/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Determines if the players' data should be split by individual teams (Y) or summed up (N)
 */
export enum teamGrouping {
    Y = 'Y',
    N = 'N',
}
