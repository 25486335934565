export const teams = [
  {
    TEAM_ID: 1610612737,
    TEAM_ABBR: "ATL",
    TEAM_FULL_NAME: "Atlanta Hawks",
    TEAM_COLOR: "#E03A3E",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612751,
    TEAM_ABBR: "BKN",
    TEAM_FULL_NAME: "Brooklyn Nets",
    TEAM_COLOR: "#000000",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612738,
    TEAM_ABBR: "BOS",
    TEAM_FULL_NAME: "Boston Celtics",
    TEAM_COLOR: "#007A33",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612766,
    TEAM_ABBR: "CHA",
    TEAM_FULL_NAME: "Charlotte Hornets",
    TEAM_COLOR: "#1D1160",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },

  {
    TEAM_ID: 1610612741,
    TEAM_ABBR: "CHI",
    TEAM_FULL_NAME: "Chicago Bulls",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612739,
    TEAM_ABBR: "CLE",
    TEAM_FULL_NAME: "Cleveland Cavaliers",
    TEAM_COLOR: "#860038",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612742,
    TEAM_ABBR: "DAL",
    TEAM_FULL_NAME: "Dallas Mavericks",
    TEAM_COLOR: "#00538C",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612743,
    TEAM_ABBR: "DEN",
    TEAM_FULL_NAME: "Denver Nuggets",
    TEAM_COLOR: "#0E2240",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612765,
    TEAM_ABBR: "DET",
    TEAM_FULL_NAME: "Detroit Pistons",
    TEAM_COLOR: "#C8102E",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612744,
    TEAM_ABBR: "GSW",
    TEAM_FULL_NAME: "Golden State Warriors",
    TEAM_COLOR: "#1D428A",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612745,
    TEAM_ABBR: "HOU",
    TEAM_FULL_NAME: "Houston Rockets",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612754,
    TEAM_ABBR: "IND",
    TEAM_FULL_NAME: "Indiana Pacers",
    TEAM_COLOR: "#002D62",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612746,
    TEAM_ABBR: "LAC",
    TEAM_FULL_NAME: "LA Clippers",
    TEAM_COLOR: "#C8102E",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612747,
    TEAM_ABBR: "LAL",
    TEAM_FULL_NAME: "Los Angeles Lakers",
    TEAM_COLOR: "#552583",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612763,
    TEAM_ABBR: "MEM",
    TEAM_FULL_NAME: "Memphis Grizzlies",
    TEAM_COLOR: "#5D76A9",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612748,
    TEAM_ABBR: "MIA",
    TEAM_FULL_NAME: "Miami Heat",
    TEAM_COLOR: "#98002E",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612749,
    TEAM_ABBR: "MIL",
    TEAM_FULL_NAME: "Milwaukee Bucks",
    TEAM_COLOR: "#00471B",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612750,
    TEAM_ABBR: "MIN",
    TEAM_FULL_NAME: "Minnesota Timberwolves",
    TEAM_COLOR: "#0C2340",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612740,
    TEAM_ABBR: "NOP",
    TEAM_FULL_NAME: "New Orleans Pelicans",
    TEAM_COLOR: "#0C2340",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },

  {
    TEAM_ID: 1610612752,
    TEAM_ABBR: "NYK",
    TEAM_FULL_NAME: "New York Knicks",
    TEAM_COLOR: "#006BB6",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612760,
    TEAM_ABBR: "OKC",
    TEAM_FULL_NAME: "Oklahoma City Thunder",
    TEAM_COLOR: "#007AC1",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612753,
    TEAM_ABBR: "ORL",
    TEAM_FULL_NAME: "Orlando Magic",
    TEAM_COLOR: "#0077C0",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612755,
    TEAM_ABBR: "PHI",
    TEAM_FULL_NAME: "Philadelphia 76ers",
    TEAM_COLOR: "#006BB6",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612756,
    TEAM_ABBR: "PHX",
    TEAM_FULL_NAME: "Phoenix Suns",
    TEAM_COLOR: "#1D1160",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612757,
    TEAM_ABBR: "POR",
    TEAM_FULL_NAME: "Portland Trail Blazers",
    TEAM_COLOR: "#E03A3E",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612758,
    TEAM_ABBR: "SAC",
    TEAM_FULL_NAME: "Sacramento Kings",
    TEAM_COLOR: "#5A2D81",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612759,
    TEAM_ABBR: "SAS",
    TEAM_FULL_NAME: "San Antonio Spurs",
    TEAM_COLOR: "#C4CED4",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612761,
    TEAM_ABBR: "TOR",
    TEAM_FULL_NAME: "Toronto Raptors",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612762,
    TEAM_ABBR: "UTA",
    TEAM_FULL_NAME: "Utah Jazz",
    TEAM_COLOR: "#002B5C",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },

  {
    TEAM_ID: 1610612764,
    TEAM_ABBR: "WAS",
    TEAM_FULL_NAME: "Washington Wizards",
    TEAM_COLOR: "#002B5C",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
];

export const gLeagueTeams = [
  { TEAM_ID: 1612709934, TEAM_ABBR: "VAL", TEAM_FULL_NAME: "Valley Suns" },
  { TEAM_ID: 1612709933, TEAM_ABBR: "RCR", TEAM_FULL_NAME: "Rip City Remix" },
  {
    TEAM_ID: 1612709928,
    TEAM_ABBR: "SDC",
    TEAM_FULL_NAME: "San Diego Clippers",
  },
  { TEAM_ID: 1612709910, TEAM_ABBR: "IMA", TEAM_FULL_NAME: "Indiana Mad Ants" },
  { TEAM_ID: 1612709925, TEAM_ABBR: "OSC", TEAM_FULL_NAME: "Osceola Magic" },
  {
    TEAM_ID: 1612709926,
    TEAM_ABBR: "WES",
    TEAM_FULL_NAME: "Westchester Knicks",
  },
  {
    TEAM_ID: 1612709932,
    TEAM_ABBR: "MCC",
    TEAM_FULL_NAME: "Motor City Cruise",
  },
  {
    TEAM_ID: 1612709903,
    TEAM_ABBR: "SLC",
    TEAM_FULL_NAME: "Salt Lake City Stars",
  },
  { TEAM_ID: 1612709914, TEAM_ABBR: "STO", TEAM_FULL_NAME: "Stockton Kings" },
  { TEAM_ID: 1612709916, TEAM_ABBR: "CLC", TEAM_FULL_NAME: "Cleveland Charge" },
  {
    TEAM_ID: 1612709917,
    TEAM_ABBR: "MXC",
    TEAM_FULL_NAME: "Mexico City Capitanes",
  },
  { TEAM_ID: 1612709931, TEAM_ABBR: "WIS", TEAM_FULL_NAME: "Wisconsin Herd" },
  { TEAM_ID: 1612709927, TEAM_ABBR: "RAP", TEAM_FULL_NAME: "Raptors 905" },
  {
    TEAM_ID: 1612709920,
    TEAM_ABBR: "CCG",
    TEAM_FULL_NAME: "Capital City Go-Go",
  },
  {
    TEAM_ID: 1612709902,
    TEAM_ABBR: "SCW",
    TEAM_FULL_NAME: "Santa Cruz Warriors",
  },
  { TEAM_ID: 1612709923, TEAM_ABBR: "WCB", TEAM_FULL_NAME: "Windy City Bulls" },
  { TEAM_ID: 1612709922, TEAM_ABBR: "AUS", TEAM_FULL_NAME: "Austin Spurs" },
  {
    TEAM_ID: 1612709929,
    TEAM_ABBR: "CPS",
    TEAM_FULL_NAME: "College Park Skyhawks",
  },
  {
    TEAM_ID: 1612709917,
    TEAM_ABBR: "GRG",
    TEAM_FULL_NAME: "Grand Rapids Gold",
  },
  {
    TEAM_ID: 1612709913,
    TEAM_ABBR: "BIR",
    TEAM_FULL_NAME: "Birmingham Squadron",
  },
  {
    TEAM_ID: 1612709889,
    TEAM_ABBR: "OKL",
    TEAM_FULL_NAME: "Oklahoma City Blue",
  },
  { TEAM_ID: 1612709905, TEAM_ABBR: "SBL", TEAM_FULL_NAME: "South Bay Lakers" },
  {
    TEAM_ID: 1612709911,
    TEAM_ABBR: "SXF",
    TEAM_FULL_NAME: "Sioux Falls Skyforce",
  },
  { TEAM_ID: 1612709918, TEAM_ABBR: "IWA", TEAM_FULL_NAME: "Iowa Wolves" },
  { TEAM_ID: 1612709926, TEAM_ABBR: "MHU", TEAM_FULL_NAME: "Memphis Hustle" },
  { TEAM_ID: 1612709912, TEAM_ABBR: "MNE", TEAM_FULL_NAME: "Maine Celtics" },
  { TEAM_ID: 1612709919, TEAM_ABBR: "GBO", TEAM_FULL_NAME: "Greensboro Swarm" },
];

export const suns = {
  TEAM_ID: 1610612756,
  TEAM_ABBR: "PHX",
  TEAM_FULL_NAME: "Phoenix Suns",
};
