import { StatName } from "@/shared/const";
import {
  formatFixed,
  formatPlusMinus,
  formatTimes100,
  formatValue,
  percent,
} from "@/shared/utils/helper-functions";

interface StatMapping {
  key: string;
  format: (value: number | string | null | undefined) => string;
}

export const seasonMeasureTypeConfigs = (perMode: string) => {
  const perModeSuffixes = {
    PerGame: "_pg",
    Totals: "",
    Per100Possessions: "_per_100_poss",
    PerPossession: "_per_poss",
    Per48Minutes: "_per_48_min",
    Per36Minutes: "_per_36_min",
    PerMinute: "_per_min",
    Per100Plays: "_per_100_plays",
    PerPlay: "_per_play",
  };

  const perModeKey = perModeSuffixes[perMode as keyof typeof perModeSuffixes];
  return {
    Base: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PTS,
        StatName.FGM,
        StatName.FGA,
        StatName.FGPct,
        StatName.ThreePM,
        StatName.ThreePA,
        StatName.ThreePPct,
        StatName.FTM,
        StatName.FTA,
        StatName.FTPct,
        StatName.ORB,
        StatName.DRB,
        StatName.REB,
        StatName.AST,
        StatName.TOV,
        StatName.STL,
        StatName.BLK,
        StatName.PF,
        StatName.PlusMinus,
      ],
      statsMapping: [
        { key: `season`, format: formatValue },
        { key: `teamName`, format: formatValue },
        { key: `gp`, format: formatValue },
        { key: `min${perModeKey}`, format: formatFixed },
        { key: `pts${perModeKey}`, format: formatFixed },
        { key: `fgm${perModeKey}`, format: formatFixed },
        { key: `fga${perModeKey}`, format: formatFixed },
        { key: `fg_pct`, format: percent },
        { key: `fg3m${perModeKey}`, format: formatFixed },
        { key: `fg3a${perModeKey}`, format: formatFixed },
        { key: `fg3_pct`, format: percent },
        { key: `ftm${perModeKey}`, format: formatFixed },
        { key: `fta${perModeKey}`, format: formatFixed },
        { key: `ft_pct`, format: percent },
        { key: `oreb${perModeKey}`, format: formatFixed },
        { key: `dreb${perModeKey}`, format: formatFixed },
        { key: `reb${perModeKey}`, format: formatFixed },
        { key: `ast${perModeKey}`, format: formatFixed },
        { key: `tov${perModeKey}`, format: formatFixed },
        { key: `stl${perModeKey}`, format: formatFixed },
        { key: `blk${perModeKey}`, format: formatFixed },
        { key: `pf${perModeKey}`, format: formatFixed },
        { key: `plus_minus${perModeKey}`, format: formatPlusMinus },
      ] as StatMapping[],
    },
    Advanced: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.ORtg,
        StatName.DRtg,
        StatName.NRtg,
        StatName.ASTPct,
        StatName.AstTo,
        StatName.AstRatio,
        StatName.ORBPct,
        StatName.DRBPct,
        StatName.RebPct,
        StatName.TORatio,
        StatName.EFGPct,
        StatName.TsPct,
        StatName.UsgPct,
        StatName.Pace,
        StatName.Pie,
        StatName.Poss,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "offRating", format: formatFixed },
        { key: "defRating", format: formatFixed },
        { key: "netRating", format: formatFixed },
        { key: "astPct", format: percent },
        { key: "astTo", format: formatFixed },
        { key: "astRatio", format: formatFixed },
        { key: "orebPct", format: percent },
        { key: "drebPct", format: percent },
        { key: "rebPct", format: percent },
        { key: "tmTovPct", format: formatFixed },
        { key: "efgPct", format: percent },
        { key: "tsPct", format: percent },
        { key: "usgPct", format: percent },
        { key: "pace", format: formatFixed },
        {
          key: "pie",
          format: formatTimes100,
        },
        { key: "poss", format: formatValue },
      ] as StatMapping[],
    },
    Misc: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PtsOffTov,
        StatName.Pts2ndChance,
        StatName.PtsFb,
        StatName.PITP,
        StatName.OppPtsOffTov,
        StatName.OppPts2ndChance,
        StatName.OppPtsFb,
        StatName.OppPITP,
        StatName.BLK,
        StatName.BLKA,
        StatName.PF,
        StatName.PFD,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "ptsOffTov", format: formatFixed },
        { key: "pts2ndChance", format: formatFixed },
        { key: "ptsFb", format: formatFixed },
        { key: "ptsPaint", format: formatFixed },
        { key: "oppPtsOffTov", format: formatFixed },
        { key: "oppPts2ndChance", format: formatFixed },
        { key: "oppPtsFb", format: formatFixed },
        { key: "oppPtsPaint", format: formatFixed },
        { key: "blk", format: formatFixed },
        { key: "blka", format: formatFixed },
        { key: "pf", format: formatFixed },
        { key: "pfd", format: formatFixed },
      ] as StatMapping[],
    },
    "Four Factors": {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.ORBPct,
        StatName.TOVPct,
        StatName.EFGPct,
        StatName.FTARate,
        StatName.OppEfgPct,
        StatName.OppFTARate,
        StatName.OppTOVPct,
        StatName.OppOrbPct,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "orebPct", format: percent },
        { key: "tmTovPct", format: formatFixed },
        { key: "efgPct", format: percent },
        { key: "ftaRate", format: percent },
        { key: "oppEfgPct", format: percent },
        { key: "oppFtaRate", format: percent },
        { key: "oppTovPct", format: percent },
        { key: "oppOrebPct", format: percent },
      ] as StatMapping[],
    },
    Scoring: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PctFga2pt,
        StatName.PctFga3pt,
        StatName.PctPts2pt,
        StatName.PctPts2ptMr,
        StatName.PctPts3pt,
        StatName.PctPtsFb,
        StatName.PctPtsFt,
        StatName.PctPtsOffTov,
        StatName.PctPtsPaint,
        StatName.PctAst2pm,
        StatName.PctUast2pm,
        StatName.PctAst3pm,
        StatName.PctUast3pm,
        StatName.PctAstFgm,
        StatName.PctUastFgm,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "pctFga2pt", format: percent },
        { key: "pctFga3pt", format: percent },
        { key: "pctPts2pt", format: percent },
        { key: "pctPts2ptMr", format: percent },
        { key: "pctPts3pt", format: percent },
        { key: "pctPtsFb", format: percent },
        { key: "pctPtsFt", format: percent },
        { key: "pctPtsOffTov", format: percent },
        { key: "pctPtsPaint", format: percent },
        { key: "pctAst2pm", format: percent },
        { key: "pctUast2pm", format: percent },
        { key: "pctAst3pm", format: percent },
        { key: "pctUast3pm", format: percent },
        { key: "pctAstFgm", format: percent },
        { key: "pctUastFgm", format: percent },
      ] as StatMapping[],
    },
    Opponent: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.OppFGM,
        StatName.OppFGA,
        StatName.OppFGPct,
        StatName.Opp3PM,
        StatName.Opp3PA,
        StatName.Opp3PPct,
        StatName.OppFTM,
        StatName.OppFTA,
        StatName.OppFTPct,
        StatName.OppOREB,
        StatName.OppDREB,
        StatName.OppREB,
        StatName.OppAST,
        StatName.OppTOV,
        StatName.OppSTL,
        StatName.OppBLK,
        StatName.OppBLKA,
        StatName.OppPF,
        StatName.OppPfd,
        StatName.OppPts,
        StatName.OppEfgPct,
        StatName.OppPlusMinus,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "oppFgm", format: formatFixed },
        { key: "oppFga", format: formatFixed },
        { key: "oppFgPct", format: percent },
        { key: "oppFg3m", format: formatFixed },
        { key: "oppFg3a", format: formatFixed },
        { key: "oppFg3Pct", format: percent },
        { key: "oppFtm", format: formatFixed },
        { key: "oppFta", format: formatFixed },
        { key: "oppFtPct", format: percent },
        { key: "oppOreb", format: formatFixed },
        { key: "oppDreb", format: formatFixed },
        { key: "oppReb", format: formatFixed },
        { key: "oppAst", format: formatFixed },
        { key: "oppTov", format: formatFixed },
        { key: "oppStl", format: formatFixed },
        { key: "oppBlk", format: formatFixed },
        { key: "oppBlka", format: formatFixed },
        { key: "oppPf", format: formatFixed },
        { key: "oppPfd", format: formatFixed },
        { key: "oppPts", format: formatFixed },
        { key: "oppEfgPct", format: percent },
        { key: "oppPlusMinus", format: formatPlusMinus },
      ] as StatMapping[],
    },
    Usage: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.UsgPct,
        StatName.PctFgm,
        StatName.PctFga,
        StatName.PctFg3m,
        StatName.PctFg3a,
        StatName.PctFtm,
        StatName.PctFta,
        StatName.PctOreb,
        StatName.PctDreb,
        StatName.PctReb,
        StatName.PctAst,
        StatName.PctTov,
        StatName.PctStl,
        StatName.PctBlk,
        StatName.PctBlka,
        StatName.PctPf,
        StatName.PctPfd,
        StatName.PctPts,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "usgPct", format: percent },
        { key: "pctFgm", format: percent },
        { key: "pctFga", format: percent },
        { key: "pctFg3m", format: percent },
        { key: "pctFg3a", format: percent },
        { key: "pctFtm", format: percent },
        { key: "pctFta", format: percent },
        { key: "pctOreb", format: percent },
        { key: "pctDreb", format: percent },
        { key: "pctReb", format: percent },
        { key: "pctAst", format: percent },
        { key: "pctTov", format: percent },
        { key: "pctStl", format: percent },
        { key: "pctBlk", format: percent },
        { key: "pctBlka", format: percent },
        { key: "pctPf", format: percent },
        { key: "pctPfd", format: percent },
        { key: "pctPts", format: percent },
      ] as StatMapping[],
    },
    Defense: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.DRB,
        StatName.DRBPct,
        StatName.STL,
        StatName.PctStl,
        StatName.BLK,
        StatName.PctBlk,
        StatName.OppPtsOffTov,
        StatName.OppPts2ndChance,
        StatName.OppPtsFb,
        StatName.OppPITP,
        StatName.DefRimFgm,
        StatName.DefRimFga,
        StatName.DefRimFgPct,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamName", format: formatValue },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "dreb", format: formatFixed },
        { key: "drebPct", format: percent },
        { key: "stl", format: formatFixed },
        { key: "pctStl", format: percent },
        { key: "blk", format: formatFixed },
        { key: "pctBlk", format: percent },
        { key: "oppPtsOffTov", format: formatFixed },
        { key: "oppPts2ndChance", format: formatFixed },
        { key: "oppPtsFb", format: formatFixed },
        { key: "oppPtsPaint", format: formatFixed },
        { key: "defRimFgm", format: formatFixed },
        { key: "defRimFga", format: formatFixed },
        { key: "defRimFgPct", format: percent },
      ] as StatMapping[],
    },
  };
};

export const playerGameMeasureTypeConfigs = {
  Base: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.PTS,
      StatName.FGM,
      StatName.FGA,
      StatName.FGPct,
      StatName.ThreePM,
      StatName.ThreePA,
      StatName.ThreePPct,
      StatName.FTM,
      StatName.FTA,
      StatName.FTPct,
      StatName.ORB,
      StatName.DRB,
      StatName.REB,
      StatName.AST,
      StatName.TOV,
      StatName.STL,
      StatName.BLK,
      StatName.PF,
      StatName.PlusMinus,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "pts", format: formatValue },
      { key: "fgm", format: formatValue },
      { key: "fga", format: formatValue },
      { key: "fg_pct", format: percent },
      { key: "fg3m", format: formatValue },
      { key: "fg3a", format: formatValue },
      { key: "fg3_pct", format: percent },
      { key: "ftm", format: formatValue },
      { key: "fta", format: formatValue },
      { key: "ft_pct", format: percent },
      { key: "oreb", format: formatValue },
      { key: "dreb", format: formatValue },
      { key: "reb", format: formatValue },
      { key: "ast", format: formatValue },
      { key: "tov", format: formatValue },
      { key: "stl", format: formatValue },
      { key: "blk", format: formatValue },
      { key: "pf", format: formatValue },
      { key: "plus_minus", format: formatPlusMinus },
    ] as StatMapping[],
  },
  Advanced: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.ORtg,
      StatName.DRtg,
      StatName.NRtg,
      StatName.ASTPct,
      StatName.AstTo,
      StatName.AstRatio,
      StatName.ORBPct,
      StatName.DRBPct,
      StatName.RebPct,
      StatName.TORatio,
      StatName.EFGPct,
      StatName.TsPct,
      StatName.UsgPct,
      StatName.Pace,
      StatName.Pie,
      StatName.Poss,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "off_rating", format: formatValue },
      { key: "def_rating", format: formatValue },
      { key: "net_rating", format: formatValue },
      { key: "ast_pct", format: percent },
      { key: "ast_to", format: formatValue },
      { key: "ast_ratio", format: formatValue },
      { key: "oreb_pct", format: percent },
      { key: "dreb_pct", format: percent },
      { key: "reb_pct", format: percent },
      { key: "tov_pct", format: formatValue },
      { key: "efg_pct", format: percent },
      { key: "ts_pct", format: percent },
      { key: "usg_pct", format: percent },
      { key: "pace", format: formatValue },
      {
        key: "pie",
        format: formatTimes100,
      },
      { key: "poss", format: formatValue },
    ] as StatMapping[],
  },
  Misc: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.PtsOffTov,
      StatName.Pts2ndChance,
      StatName.PtsFb,
      StatName.PITP,
      StatName.OppPtsOffTov,
      StatName.OppPts2ndChance,
      StatName.OppPtsFb,
      StatName.OppPITP,
      StatName.BlkFg3a,
      StatName.PtsCreated,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "pts_off_tov", format: formatValue },
      { key: "pts_2nd_chance", format: formatValue },
      { key: "pts_fb", format: formatValue },
      { key: "pts_paint", format: formatValue },
      { key: "opp_pts_off_tov", format: formatValue },
      { key: "opp_pts_2nd_chance", format: formatValue },
      { key: "opp_pts_fb", format: formatValue },
      { key: "opp_pts_paint", format: formatValue },
      { key: "blk_fg3a", format: formatValue },
      { key: "pts_created", format: formatValue },
    ] as StatMapping[],
  },
  "Four Factors": {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.ORBPct,
      StatName.TOVPct,
      StatName.EFGPct,
      StatName.FTARate,
      StatName.OppEfgPct,
      StatName.OppFTARate,
      StatName.OppTOVPct,
      StatName.OppOrbPct,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "oreb_pct", format: percent },
      { key: "tov_pct", format: formatValue },
      { key: "efg_pct", format: percent },
      { key: "fta_rate", format: percent },
      { key: "opp_efg_pct", format: percent },
      { key: "opp_fta_rate", format: percent },
      { key: "opp_tov_pct", format: formatValue },
      { key: "opp_oreb_pct", format: percent },
    ] as StatMapping[],
  },
  Scoring: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.PctFga2pt,
      StatName.PctFga3pt,
      StatName.PctPts2pt,
      StatName.PctPts2ptMr,
      StatName.PctPts3pt,
      StatName.PctPtsFb,
      StatName.PctPtsFt,
      StatName.PctPtsOffTov,
      StatName.PctPtsPaint,
      StatName.PctAst2pm,
      StatName.PctUast2pm,
      StatName.PctAst3pm,
      StatName.PctUast3pm,
      StatName.PctAstFgm,
      StatName.PctUastFgm,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "pct_fga_2pt", format: percent },
      { key: "pct_fga_3pt", format: percent },
      { key: "pct_pts_2pt", format: percent },
      { key: "pct_pts_2pt_mr", format: percent },
      { key: "pct_pts_3pt", format: percent },
      { key: "pct_pts_fb", format: percent },
      { key: "pct_pts_ft", format: percent },
      { key: "pct_pts_off_tov", format: percent },
      { key: "pct_pts_paint", format: percent },
      { key: "pct_ast_2pm", format: percent },
      { key: "pct_uast_2pm", format: percent },
      { key: "pct_ast_3pm", format: percent },
      { key: "pct_uast_3pm", format: percent },
      { key: "pct_ast_fgm", format: percent },
      { key: "pct_uast_fgm", format: percent },
    ] as StatMapping[],
  },
  Opponent: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.OppFGM,
      StatName.OppFGA,
      StatName.OppFGPct,
      StatName.Opp3PM,
      StatName.Opp3PA,
      StatName.Opp3PPct,
      StatName.OppFTM,
      StatName.OppFTA,
      StatName.OppFTPct,
      StatName.OppOREB,
      StatName.OppDREB,
      StatName.OppREB,
      StatName.OppAST,
      StatName.OppTOV,
      StatName.OppSTL,
      StatName.OppBLK,
      StatName.OppBLKA,
      StatName.OppPF,
      StatName.OppPfd,
      StatName.OppPts,
      StatName.OppPlusMinus,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "opp_fgm", format: formatValue },
      { key: "opp_fga", format: formatValue },
      { key: "opp_fg_pct", format: percent },
      { key: "opp_fg3m", format: formatValue },
      { key: "opp_fg3a", format: formatValue },
      { key: "opp_fg3_pct", format: percent },
      { key: "opp_ftm", format: formatValue },
      { key: "opp_fta", format: formatValue },
      { key: "opp_ft_pct", format: percent },
      { key: "opp_oreb", format: formatValue },
      { key: "opp_dreb", format: formatValue },
      { key: "opp_reb", format: formatValue },
      { key: "opp_ast", format: formatValue },
      { key: "opp_tov", format: formatValue },
      { key: "opp_stl", format: formatValue },
      { key: "opp_blk", format: formatValue },
      { key: "opp_blka", format: formatValue },
      { key: "opp_pf", format: formatValue },
      { key: "opp_pfd", format: formatValue },
      { key: "opp_pts", format: formatValue },
      { key: "opp_plus_minus", format: formatPlusMinus },
    ] as StatMapping[],
  },
  Usage: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.Min,
      StatName.PctFgm,
      StatName.PctFga,
      StatName.PctFg3m,
      StatName.PctFg3a,
      StatName.PctFtm,
      StatName.PctFta,
      StatName.PctOreb,
      StatName.PctDreb,
      StatName.PctReb,
      StatName.PctAst,
      StatName.PctTov,
      StatName.PctStl,
      StatName.PctBlk,
      StatName.PctBlka,
      StatName.PctPf,
      StatName.PctPfd,
      StatName.PctPts,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "min", format: formatFixed },
      { key: "pct_fgm", format: percent },
      { key: "pct_fga", format: percent },
      { key: "pct_fg3m", format: percent },
      { key: "pct_fg3a", format: percent },
      { key: "pct_ftm", format: percent },
      { key: "pct_fta", format: percent },
      { key: "pct_oreb", format: percent },
      { key: "pct_dreb", format: percent },
      { key: "pct_reb", format: percent },
      { key: "pct_ast", format: percent },
      { key: "pct_tov", format: percent },
      { key: "pct_stl", format: percent },
      { key: "pct_blk", format: percent },
      { key: "pct_blka", format: percent },
      { key: "pct_pf", format: percent },
      { key: "pct_pfd", format: percent },
      { key: "pct_pts", format: percent },
    ] as StatMapping[],
  },
  Defensive: {
    headers: [
      "",
      StatName.WinLoss,
      StatName.DefAst,
      StatName.DefBlk,
      StatName.DefFg3a,
      StatName.DefFg3m,
      StatName.DefFg3Pct,
      StatName.DefFga,
      StatName.DefFgm,
      StatName.DefFgPct,
      StatName.DefFoul,
      StatName.DefFta,
      StatName.DefFtm,
      StatName.DefFtPct,
      StatName.DefPts,
      StatName.DefShootingFoul,
      StatName.DefStl,
      StatName.DefTov,
    ],
    statsMapping: [
      { key: "matchUp", format: formatValue },
      { key: "outcome", format: formatValue },
      { key: "def_ast", format: formatValue },
      { key: "def_blk", format: formatValue },
      { key: "def_fg3a", format: formatValue },
      { key: "def_fg3m", format: formatValue },
      { key: "def_fg3_pct", format: percent },
      { key: "def_fga", format: formatValue },
      { key: "def_fgm", format: formatValue },
      { key: "def_fg_pct", format: percent },
      { key: "def_foul", format: formatValue },
      { key: "def_fta", format: formatValue },
      { key: "def_ftm", format: formatValue },
      { key: "def_ft_pct", format: percent },
      { key: "def_pts", format: formatValue },
      { key: "def_shooting_foul", format: formatValue },
      { key: "def_stl", format: formatValue },
      { key: "def_tov", format: formatValue },
    ] as StatMapping[],
  },
};
