import { Flex, SkeletonText, Text } from "@suns/design-system";
import { PaginationControl } from "../PaginationControl/PaginationControl";
import ReportListItem from "@/pages/reports/reports-listings/components/ReportListItem";
import { ReportsResponse } from "@suns/api/generated-client/apollo";

interface ReportsListProps {
  loading: boolean;
  response: ReportsResponse;
  setPage: (page: number) => void;
  hideListItemHeader?: boolean;
}

export function ReportsList({
  loading,
  response,
  setPage,
  hideListItemHeader,
}: ReportsListProps) {
  return (
    <>
      {loading ? (
        <ReportsListingsLoading />
      ) : response && response.reports.length > 0 ? (
        <>
          <div className="divide-y-2">
            {response.reports.map((report) => (
              <ReportListItem
                key={report.id}
                className="py-4 first:pt-0 last:pb-0"
                hideListItemHeader={hideListItemHeader}
                report={report}
              />
            ))}
          </div>
          <PaginationControl {...response} onPageClicked={setPage} />
        </>
      ) : (
        <Text className="py-10 text-center" size="lg" muted>
          No reports found.
        </Text>
      )}
    </>
  );
}

function ReportsListingsLoading() {
  return (
    <Flex direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
