import { useMemo } from "react";
import dayjs from "dayjs";
import {
  Button,
  Flex,
  Skeleton,
  SkeletonHeading,
  SkeletonThumbnail,
  Text,
} from "@suns/design-system";
import { TeamLogo } from "@/components";
import { Game } from "../../loaders/schedule-loader";
import { getLeagueIdFromNbaLeagueId } from "@/shared/utils";

export function GameHero({
  homeTeamNbaId,
  homeTeamAbbr,
  homeTeamScore,
  awayTeamNbaId,
  awayTeamAbbr,
  awayTeamScore,
  gameDateTimeUtc,
  gameNumber,
  nbaLeagueId,
}: Game) {
  const date = useMemo(
    () => dayjs(gameDateTimeUtc).tz(dayjs.tz.guess() ?? "America/Phoenix"),
    [gameDateTimeUtc]
  );

  function GameHeroTeam({
    nbaTeamId,
    abbr,
    score,
    isHome,
    direction = "right",
  }: {
    nbaTeamId: Game["homeTeamNbaId"];
    abbr: Game["homeTeamAbbr"];
    score?: Game["homeTeamScore"];
    isHome: boolean;
    direction?: "right" | "left";
  }) {
    return (
      <Flex direction={direction} gap="sm" align="center" wrap>
        <Flex direction="down">
          <Text size={["md", "sm:2xl"]} heading>
            {abbr}
          </Text>
          <Text size={["sm", "md:md"]}>{isHome ? "Home" : "Away"}</Text>
        </Flex>
        <TeamLogo
          nbaTeamId={nbaTeamId}
          leagueId={getLeagueIdFromNbaLeagueId(nbaLeagueId)}
          size={["sm", "md:md"]}
        />
        {score ? (
          <Text size={["md", "sm:2xl"]} heading className="md:mx-md">
            {score}
          </Text>
        ) : null}
      </Flex>
    );
  }

  const showScore = awayTeamScore && homeTeamScore;

  return (
    <Flex direction="down" align="center" gap="sm">
      <Flex
        direction="right"
        gap={showScore ? "md" : "lg"}
        align="center"
        justify="center"
      >
        <GameHeroTeam
          nbaTeamId={awayTeamNbaId}
          abbr={awayTeamAbbr}
          score={showScore ? awayTeamScore : undefined}
          isHome={false}
        />
        <Text size={["sm", "md:xl"]}>@</Text>
        <GameHeroTeam
          direction="left"
          nbaTeamId={homeTeamNbaId}
          abbr={homeTeamAbbr}
          score={showScore ? homeTeamScore : undefined}
          isHome={true}
        />
      </Flex>
      <Flex align="center" gap="md">
        {gameNumber && <Text heading>Game {gameNumber}</Text>}
        <Text>{date.format("MMMM D, YYYY")}</Text>
        <Button variant="link" size="flush">
          Game DNA
        </Button>
      </Flex>
    </Flex>
  );
}

export function GameHeroSkeleton() {
  function Team({ direction = "right" }: { direction?: "right" | "left" }) {
    return (
      <Flex direction={direction} gap="md" align="center" wrap>
        <Flex direction="down" gap="sm">
          <Skeleton className="h-[30px] w-[40px]" />
          <SkeletonHeading />
        </Flex>
        <SkeletonThumbnail />
        <Skeleton className="h-[30px] w-[40px]" />
      </Flex>
    );
  }

  return (
    <Flex direction="right" gap="md" align="center" justify="center">
      <Team />
      <SkeletonHeading />
      <Team direction="left" />
    </Flex>
  );
}
