import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { LeagueId, ApolloPhase, NbaLeagueId } from "@suns/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import dayjs from "dayjs";

export { labsList } from "./labs";
export { glossaryList, StatName } from "./glossary";

export const Suns = {
  id: 1610612756,
  abbr: "PHX",
  fullName: "Phoenix Suns",
  leagueId: NbaLeagueId.NBA,
};

export const Mercury = {
  id: 1611661317,
  abbr: "PHO",
  fullName: "Phoenix Mercury",
  leagueId: NbaLeagueId.WNBA,
};

export const ValleySuns = {
  id: 1612709934,
  abbr: "VAL",
  fullName: "ValleySuns",
  leagueId: NbaLeagueId.GLEAGUE,
};

export const defaultFilterValues = {
  level: PlayerLevel.PRO,
  teamIds: [],
  playerId: undefined,
  authorUsername: [],
  status: ReportQueryParams.status.PUBLISHED,
  page: 0,
};

export const AvailableLeagues = [
  LeagueId.NBA,
  LeagueId.NCAA,
  LeagueId.GLEAGUE,
  LeagueId.SPAIN,
  LeagueId.ITALY,
  LeagueId.AUSTRALIA,
  LeagueId.FRANCE_PRO_A,
  LeagueId.FRANCE_PRO_B,
  LeagueId.GREECE,
  LeagueId.TURKEY,
  LeagueId.RUSSIA,
  LeagueId.EURO_LEAGUE,
  LeagueId.EURO_CUP,
  LeagueId.CHAMPIONS_LEAGUE,
];

export const CURRENT_PHASE = ApolloPhase.PHASE_24_25_5;

export const CURRENT_SEASON = 2024;
export const CURRENT_SEASON_LABEL = "2024-25";
export const CURRENT_DATE = dayjs();
