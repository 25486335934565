import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";
import { TeamRow } from "@suns/api/generated-client/apollo";

export function useTeams(leagueId?: number) {
  return useAsync(async () => {
    if (!leagueId) {
      return { teams: {}, conferences: [] };
    }

    const data = await apolloApi.getTeams({
      domesticLeagueId: leagueId,
    });

    const teams = data.teams?.reduce<Record<string, TeamRow[]>>((acc, team) => {
      const conference = team.conference || "Other";
      if (!acc[conference]) {
        acc[conference] = [];
      }
      acc[conference].push(team);
      return acc;
    }, {});

    const conferences = Object.keys(teams).sort();

    conferences.forEach((conference) => {
      teams[conference].sort((a, b) =>
        (a.fullName || a.name).localeCompare(b.fullName || b.name)
      );
    });

    return { teams, conferences };
  }, leagueId);
}

export function useTeam(teamId: number) {
  const { loading, response, error } = useAsync(async () => {
    if (!teamId) return null;
    const data = await apolloApi.getTeam(teamId);
    return data.team;
  }, teamId);

  return { team: response, loading, error };
}
