import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "@/pages/reports/reports-const";
import {
  PlayerMetadataRow,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import {
  Button,
  Command,
  CommandGroup,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  Filter,
  Flex,
  Input,
  Popover,
  Select,
  SelectOption,
  Separator,
  Text,
} from "@suns/design-system";
import { CircleXIcon, LoaderCircleIcon, PlusIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useLeagues } from "@/pages/intel/hooks/useLeagues";
import { PopoverTrigger } from "@suns/design-system/src/components/Popover/Popover";
import { PopoverContent } from "@radix-ui/react-popover";
import {
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@suns/design-system/src/components/Command/Command";
import { PlayerFilter, TeamFilter } from "@/components";
import { ApolloGrade } from "@suns/api";
import { apolloGradeMax } from "@/shared/utils";
import { apolloGradeMin } from "@/shared/utils/apollo-grades";
import { ScrollArea } from "@suns/design-system/src/components/ScrollArea/ScrollArea";
import { CURRENT_SEASON } from "@/shared/const";
import { CurrencyInput } from "./components";
import { SetURLSearchParams } from "react-router-dom";

export enum OperatorType {
  INCLUDES = "in",
  EXCLUDES = "ex",
  GREATER_THAN = "gt",
  LESS_THAN = "lt",
}

export interface PlayerFilterConfig {
  label: string;
  key: string;
  availableOperators: OperatorType[];
  selectedOperator?: OperatorType;
  selectedValue?: string;
  renderInputField: (
    value?: string,
    onChange?: (value: string) => void,
    operator?: OperatorType
  ) => React.ReactNode;
}

function labelForOperatorType(type: OperatorType) {
  switch (type) {
    case OperatorType.INCLUDES:
      return "Includes";
    case OperatorType.EXCLUDES:
      return "Excludes";
    case OperatorType.GREATER_THAN:
      return "Greater than or equal to";
    case OperatorType.LESS_THAN:
      return "Less than or equal to";
  }
}

const generalFilters: PlayerFilterConfig[] = [
  {
    label: "Player",
    key: "player",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <PlayerFilter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(",").trim());
        }}
      />
    ),
  },
  {
    label: "Team",
    key: "team",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <TeamFilter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(",").trim());
        }}
      />
    ),
  },

  {
    label: "League",
    key: "league",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <LeagueSelectField value={value} onChange={onChange} />
    ),
  },
  {
    label: "Target",
    key: "target",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(PlayerMetadataRow.target).map((option) => ({
          key: option,
          searchKey: option,
          name:
            option === PlayerMetadataRow.target.FREE_AGENT
              ? "Free Agent Target"
              : "Trade Target",
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
];

const evaluationFilters: PlayerFilterConfig[] = [
  {
    label: "Apollo Grade",
    key: "grade",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: ApolloGradeSelectField,
  },
  {
    label: "Remaining Capacity",
    key: "remainingCapacity",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: ApolloGradeSelectField,
  },
  {
    label: "Position",
    key: "position",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.position).map((option) => ({
          key: option,
          name: ReportPositionLabels[
            option as keyof typeof ReportPositionLabels
          ],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Role",
    key: "role",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.role).map((option) => ({
          key: option,
          searchKey: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
          name: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Analytic Grade",
    key: "analyticGrade",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: ApolloGradeSelectField,
  },
  {
    label: "Scout Grade",
    key: "scoutGrade",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: ApolloGradeSelectField,
  },
  {
    label: "Scout Remaining Capacity",
    key: "scoutRemainingCapacity",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: ApolloGradeSelectField,
  },
  {
    label: "Scout Position",
    key: "scoutPosition",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.position).map((option) => ({
          key: option,
          searchKey:
            ReportPositionLabels[option as keyof typeof ReportPositionLabels],
          name: ReportPositionLabels[
            option as keyof typeof ReportPositionLabels
          ],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Scout Role",
    key: "scoutRole",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.role).map((option) => ({
          key: option,
          name: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "AIM",
    key: "aim",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "dAIM",
    key: "dAim",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "oAIM",
    key: "oAim",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "PPV",
    key: "ppv",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  },
  {
    label: "oPPV",
    key: "oPpv",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  },
  {
    label: "dPPV",
    key: "dPpv",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  },
  {
    label: "PRV",
    key: "prv",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  },
];

const playerInfoFilters: PlayerFilterConfig[] = [
  {
    label: "Age",
    key: "age",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Height",
    key: "height",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        value={value}
        onChange={(event) => {
          const digits = event.target.value.replace(/\D/g, "");
          if (digits && digits.length > 1) {
            const feet = digits.charAt(0);
            if (digits.length === 1) {
              onChange?.(`${feet}`);
            } else {
              const inches = Math.min(11, parseInt(digits.slice(1), 10));
              onChange?.(`${feet}'${inches}`);
            }
          } else {
            onChange?.(`${digits}`);
          }
        }}
      />
    ),
  },
  {
    label: "Weight",
    key: "weight",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Wingspan",
    key: "wing",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Reach",
    key: "reach",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Years of Service",
    key: "yearsOfService",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        className="text-[16px]"
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
];

// const scoreFilters: PlayerFilterConfig[] = Object.keys(
//   PlayerMetadataScoreRow.key
// ).map((scoreKey) => ({
//   label:
//     ReportScoreDescriptions[scoreKey as ReportScore.key].fullLabel ||
//     ReportScoreDescriptions[scoreKey as ReportScore.key].label,
//   key: scoreKey,
//   availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
//   renderInputField: (value, onChange) => (
//     <Select value={value} onValueChange={onChange}>
//       {[...Array(scoreKey.startsWith("DEFEND") ? 3 : 6).keys()].map((value) => (
//         <SelectOption key={value} value={`${value}`}>
//           {value}
//         </SelectOption>
//       ))}
//     </Select>
//   ),
// }));

const salaryFilters: PlayerFilterConfig[] = [
  ...[
    CURRENT_SEASON,
    CURRENT_SEASON + 1,
    CURRENT_SEASON + 2,
    CURRENT_SEASON + 3,
    CURRENT_SEASON + 4,
  ].map((year, i) => ({
    label: `${year} Cap Hit`,
    key: `capHit${i > 0 ? `${i}Year` : ""}`,
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  })),
  {
    label: "AAV",
    key: "aav",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: CurrencyInput,
  },
  {
    label: "UFA",
    key: "unrestrictedFreeAgent",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: YearSelectField,
  },
  {
    label: "RFA",
    key: "restrictedFreeAgent",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: YearSelectField,
  },
  {
    label: "Team Option",
    key: "teamOption",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: YearSelectField,
  },
  {
    label: "Player Option",
    key: "playerOption",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: YearSelectField,
  },
];

const availableFilters = [
  { groupLabel: "General", filters: generalFilters },
  { groupLabel: "Evaluation", filters: evaluationFilters },
  { groupLabel: "Player Info", filters: playerInfoFilters },
  // { groupLabel: "Scores", filters: scoreFilters },
  { groupLabel: "Salary", filters: salaryFilters },
];

function filtersToSearchParams(filters: PlayerFilterConfig[]) {
  return filters.reduce<Record<string, string>>((acc, filter) => {
    if (filter.selectedOperator && filter.selectedValue) {
      acc[`${filter.key}.${filter.selectedOperator}`] = filter.selectedValue;
    }

    return acc;
  }, {});
}

function searchParamsToFilters(
  searchParams: URLSearchParams
): PlayerFilterConfig[] {
  const filters: PlayerFilterConfig[] = [];

  searchParams.forEach((value, key) => {
    const [filterKey, operator] = key.split(".");
    const filter = availableFilters
      .map((filterGroup) => filterGroup.filters)
      .flat()
      .find((filter) => filter.key === filterKey);

    if (filter && operator) {
      filters.push({
        ...filter,
        selectedOperator: operator as OperatorType,
        selectedValue: value,
      });
    }
  });

  return filters;
}

export function PlayerFilters({
  onSubmit,
  searchParams,
  setSearchParams,
}: {
  onSubmit: () => void;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}) {
  const [filters, setFilters] = useState<PlayerFilterConfig[]>([]);
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    const newFilters = searchParamsToFilters(searchParams);
    setFilters(newFilters);
  }, [searchParams]);

  function handleAddFilter(filter: PlayerFilterConfig) {
    setFilters((prevFilters) => [
      ...prevFilters,
      { ...filter, selectedOperator: filter.availableOperators[0] },
    ]);
  }

  const handleRemoveFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleUpdateFilter = (index: number, values: object) => {
    const filter = filters[index];
    const newFilters = [...filters];
    newFilters[index] = {
      ...filter,
      ...values,
    };

    setFilters(newFilters);
  };

  function handleSubmit() {
    const newSearchParams = filtersToSearchParams(filters);

    // get search params that aren't part of the filters and make sure they are included
    // when setting search params so we don't overwrite params from another page
    const nonFilterSearchParams = Object.keys(
      Object.fromEntries(searchParams)
    ).reduce<Record<string, string[]>>((acc, key) => {
      const [filterKey] = key.split(".");
      const filter = availableFilters
        .map((filterGroup) => filterGroup.filters)
        .flat()
        .find((filter) => filter.key === filterKey);
      if (!filter) {
        acc[key] = searchParams.getAll(key);
      }
      return acc;
    }, {});

    setSearchParams({
      ...nonFilterSearchParams,
      ...newSearchParams,
      page: "1",
    });

    onSubmit();
  }

  return (
    <Flex direction="down" gap="sm" align="start">
      <Flex
        direction="right"
        justify="between"
        align="center"
        className="w-full"
      >
        <Text heading size="xl">
          Filters
        </Text>
        {filters.length > 0 && (
          <Button variant="link" onClick={() => setFilters([])}>
            <Text size="sm">Clear Filters</Text>
          </Button>
        )}
      </Flex>

      <Flex direction="down" gap="xs">
        {filters.map((filter, i) => (
          <Flex
            direction="right"
            gap="sm"
            align="center"
            wrap={false}
            key={`${filter.key}-${i}`}
            className="pt-4"
          >
            <Text size="sm">{filter.label}</Text>
            <Select
              value={filter.selectedOperator}
              onValueChange={(newFilterType) =>
                handleUpdateFilter(i, { selectedOperator: newFilterType })
              }
            >
              {filter.availableOperators?.map((filterType) => (
                <SelectOption key={filterType} value={filterType}>
                  {labelForOperatorType(filterType)}
                </SelectOption>
              ))}
            </Select>

            <Flex>
              {filter.renderInputField(
                filter.selectedValue,
                (value) => {
                  handleUpdateFilter(i, { selectedValue: value });
                },
                filter.selectedOperator
              )}
            </Flex>

            <Button
              variant="ghost"
              size="flush"
              onClick={() => handleRemoveFilter(i)}
            >
              <CircleXIcon size={18} className="text-muted-500" />
            </Button>
          </Flex>
        ))}
      </Flex>

      <Flex className="hidden md:flex">
        <Popover open={filterPopoverOpen} onOpenChange={setFilterPopoverOpen}>
          <Flex direction="right" gap="sm" className="pt-4">
            <PopoverTrigger asChild>
              <Button variant="muted">
                <PlusIcon size={18} className="mr-2" /> Add filter
              </Button>
            </PopoverTrigger>
            <Button onClick={handleSubmit}>Update</Button>
          </Flex>
          <PopoverContent className="p-0">
            <Command>
              <CommandInput placeholder="Filters" />
              <CommandList>
                <CommandEmpty>"No results found."</CommandEmpty>
              </CommandList>
              <CommandList>
                {availableFilters.map((filterGroup) => (
                  <CommandGroup
                    key={filterGroup.groupLabel}
                    heading={filterGroup.groupLabel}
                  >
                    {filterGroup.filters.map((filter) => (
                      <CommandItem
                        key={filter.label}
                        className="px-5"
                        onSelect={() => {
                          setFilterPopoverOpen(false);
                          handleAddFilter(filter);
                        }}
                      >
                        {filter.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ))}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </Flex>

      <Flex className="md:hidden">
        <Flex direction="right" gap="sm" className="pt-4">
          <Button variant="muted" onClick={() => setFilterDrawerOpen(true)}>
            <PlusIcon size={18} className="mr-2" /> Add filter
          </Button>
          <Button onClick={handleSubmit}>Update</Button>
        </Flex>

        <Drawer open={filterDrawerOpen} onOpenChange={setFilterDrawerOpen}>
          <DrawerContent className="h-[60%]">
            <DrawerHeader>
              <DrawerTitle>Add Filter</DrawerTitle>
              <DrawerDescription />
            </DrawerHeader>
            <ScrollArea>
              <Flex direction="down" gap="md" className="px-8">
                {availableFilters.map((filterGroup) => (
                  <Flex direction="down" gap="md" key={filterGroup.groupLabel}>
                    <Text size="md" heading>
                      {filterGroup.groupLabel}
                    </Text>
                    <Separator />
                    <Flex direction="down" gap="md">
                      {filterGroup.filters.map((filter) => (
                        <>
                          <Text
                            size="lg"
                            className="pl-2"
                            onClick={() => {
                              handleAddFilter(filter);
                              setFilterDrawerOpen(false);
                            }}
                          >
                            {filter.label}
                          </Text>
                          <Separator />
                        </>
                      ))}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
}

function LeagueSelectField({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const { leagues, loading } = useLeagues();

  const options = useMemo(() => {
    return (
      leagues?.map((league) => ({
        key: league.id.toString(),
        searchKey: league.name,
        name: league.name,
      })) || []
    );
  }, [leagues]);

  if (loading) {
    return <LoaderCircleIcon size={18} className="animate-spin" />;
  }

  return (
    <Filter
      className="bg-white"
      value={value?.split(",").filter((listItem) => listItem.length > 0) ?? []}
      onChange={(selectedKeys: string[]) => {
        onChange?.(selectedKeys.join(",").trim());
      }}
      options={options}
    />
  );
}

function ApolloGradeSelectField(
  value?: string,
  onChange?: (value: string) => void,
  operator?: OperatorType
) {
  const grades = Object.keys(ApolloGrade) as ApolloGrade[];
  return (
    <Select value={value} onValueChange={onChange}>
      {grades.map((value) => (
        <SelectOption
          key={value}
          value={`${operator === OperatorType.LESS_THAN ? apolloGradeMax(value) : apolloGradeMin(value)}`}
        >
          {ApolloGradeValueLabels[value as keyof typeof ApolloGradeValueLabels]}
        </SelectOption>
      ))}
    </Select>
  );
}

function YearSelectField(value?: string, onChange?: (value: string) => void) {
  return (
    <Select value={value} onValueChange={onChange}>
      {[...Array(5).keys()].map((value) => (
        <SelectOption key={value} value={`${CURRENT_SEASON + value}`}>
          {CURRENT_SEASON + value}
        </SelectOption>
      ))}
    </Select>
  );
}
