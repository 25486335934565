import { getSchedule } from "@/shared/api";
import { NbaLeagueId } from "@suns/api";
import dayjs from "dayjs";

type Schedule = Awaited<ReturnType<typeof getSchedule>>;

export interface Game {
  nbaGameId: string;
  nbaLeagueId: NbaLeagueId;
  homeTeamNbaId: number;
  homeTeamAbbr: string;
  homeTeamScore: number;
  awayTeamNbaId: number;
  awayTeamAbbr: string;
  awayTeamScore: number;
  gameWinnerTeamNbaId: number;
  gameDateTimeUtc: string;
  gameNumber?: number;
}

export async function scheduleLoader({
  nbaLeagueId,
  nbaTeamId,
}: {
  nbaLeagueId: NbaLeagueId;
  nbaTeamId: string;
}) {
  const schedule = await getSchedule({
    season: "2024-25",
    teamId: Number(nbaTeamId),
    leagueId: nbaLeagueId,
  });
  return formatGames(schedule, nbaLeagueId);
}

function formatGames(schedule: Schedule, nbaLeagueId: NbaLeagueId) {
  const yesterday = dayjs().subtract(1, "day").endOf("day");

  if (!schedule.gameDates) {
    return { nextGame: undefined, upcomingGames: [], pastGames: [] };
  }

  const { nextGame, upcomingGames, pastGames } = schedule.gameDates.reduce<{
    nextGame?: Game;
    upcomingGames: Game[];
    pastGames: Game[];
  }>(
    ({ nextGame, upcomingGames, pastGames }, gameDate) => {
      const gameData = gameDate?.games?.[0];

      if (!gameData?.homeTeam || !gameData?.awayTeam) {
        return { nextGame, upcomingGames, pastGames };
      }

      const game = {
        nbaLeagueId: nbaLeagueId,
        nbaGameId: gameData.gameId!,
        homeTeamNbaId: gameData.homeTeam.teamId!,
        homeTeamAbbr: gameData.homeTeam.teamTricode!,
        homeTeamScore: gameData.homeTeam.score!,
        awayTeamNbaId: gameData.awayTeam.teamId!,
        awayTeamAbbr: gameData.awayTeam.teamTricode!,
        awayTeamScore: gameData.awayTeam.score!,
        gameWinnerTeamNbaId:
          gameData.homeTeam.score! > gameData.awayTeam.score!
            ? gameData.homeTeam.teamId!
            : gameData.awayTeam.teamId!,
        gameDateTimeUtc: gameData.gameDateTimeUTC!,
        gameNumber:
          gameData.seasonType == "Playoffs" ? gameData.gameSequence : undefined,
      } satisfies Game;

      const gameTime = dayjs(game.gameDateTimeUtc);

      if (!nextGame && gameTime.isAfter(yesterday)) {
        nextGame = game;
        return { nextGame, upcomingGames, pastGames };
      }

      if (gameTime.isBefore(yesterday)) {
        pastGames.push(game);
        return { nextGame, upcomingGames, pastGames };
      }

      upcomingGames.push(game);

      return { nextGame, upcomingGames, pastGames };
    },
    {
      upcomingGames: [],
      pastGames: [],
    }
  );

  return { nextGame, upcomingGames, pastGames };
}
