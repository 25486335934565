import { defer } from "react-router-dom";
import { FiltersService, PlayerMetadataRow } from "../../swagger";
import { MultiValue } from "react-select";
import { MultiValuePlayersType } from "../../pages/box-scores/box-scores-types";
import dayjs from "dayjs";
import { nbaSeasonFromDate } from "./nba-season";

type GenericMultiValueType<T> = MultiValue<T> | null;

export const getFilterData = async () => {
  try {
    const teamsList: {
      label: string;
      options: { label: string; value: string; id: number }[];
    }[] = [];
    const seasonsList: { label: string; value: string }[] = [];
    const teamsResponse = await FiltersService.getTeams();
    teamsResponse.data.forEach((group) => {
      const teams: { label: string; value: string; id: number }[] = [];
      group.options.forEach(
        (team: {
          TEAM_FULL_NAME: string;
          TEAM_ABBR: string;
          TEAM_ID: number;
        }) => {
          teams.push({
            value: team.TEAM_ABBR,
            label: team.TEAM_FULL_NAME,
            id: team.TEAM_ID,
          });
        }
      );
      teamsList.push({ label: group.label, options: teams });
    });

    const seasonsResponse = await FiltersService.getSeasons();
    seasonsResponse.data.forEach((season) => {
      seasonsList.push({ value: season.SEASON, label: season.SEASON });
    });

    return defer({ teamsList, seasonsList });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error:", error);
  }
};

export const shortenName = (fullName: string) => {
  const words = fullName.split(" ");
  if (words.length >= 2) {
    const firstName = words[0];
    const lastName = words[words.length - 1].includes("Jr.")
      ? words.splice(-2).join(" ")
      : words[words.length - 1];
    return `${firstName[0]}.${lastName}`;
  }
  return fullName;
};

export function dedupe<T>(a: T[]) {
  return [...new Set(a)];
}

export function dedupeBy<T>(arr: T[], fn: (item: T) => unknown) {
  return arr.filter((item, index, self) => {
    return index === self.findIndex((t) => fn(t) === fn(item));
  });
}

export function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export function extractValues<
  T extends { value: string | number; label: string; isDisabled?: boolean },
>(items: GenericMultiValueType<T>): string[] {
  if (!items) {
    return [];
  }
  return items.map((data) => data.value.toString());
}

export const convertPlayers = (ids: string[], arr: MultiValuePlayersType) => {
  const playerIds = new Set(ids?.map((id) => parseInt(id)));
  const filteredPlayers = arr?.filter((player) => playerIds.has(player.value));
  return (
    filteredPlayers?.map((player) => ({
      value: player.value,
      label: player.label,
      isDisabled: player.isDisabled,
    })) || []
  );
};

export const removeEmptyValues = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, v]) => v !== null && v !== "" && v !== undefined
    )
  );
};

export function formatWeight(weight: number): string {
  return `${weight} lbs`;
}

export function formatInchesToFeet(inches: number): string {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}`;
}

export function formatAge(birthDate: string): string {
  return dayjs().diff(dayjs(birthDate), "year", true).toFixed(1);
}

export function percent(
  input: number | null | undefined,
  skipRounding: boolean = false
) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }

  if (input > 1 || skipRounding) {
    return `${input.toFixed(1)}%`;
  }

  const percent = Math.round(input * 1000) / 10;
  return `${percent.toFixed(1)}%`;
}

export function padDecimals(
  input: number | null | undefined,
  decimals: number = 1
) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }
  return input.toFixed(decimals);
}

export function numericalApolloGrade(input: number | null | undefined) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }
  const rounded = parseFloat(input.toFixed(2));

  if (Math.floor(rounded) > Math.floor(input)) {
    return `${Math.floor(input)}.99`;
  }

  return input.toFixed(2);
}

export function plusMinus(
  input: number | null | undefined,
  decimals: number = 0
) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }

  if (input > 0) return `+${input.toFixed(decimals)}`;
  return input.toFixed(decimals);
}

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export function formatMoney(input: number | null | undefined) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }

  return moneyFormatter.format(input);
}

export function formatValue(
  value: string | number | null | undefined,
  fallback = "--"
) {
  if (value === "0" || value === 0) {
    return "0";
  } else if (!value) {
    return fallback;
  }
  return value;
}

export function formatFixed(
  value: number | null | undefined,
  decimals = 1,
  fallback = "--"
) {
  if (value === null || value === undefined) return fallback;
  return value.toFixed(decimals);
}

export function formatPlusMinus(value: number | null | undefined) {
  if (value === null || value === undefined) return "--";
  return value > 0 ? `+${value}` : value.toString();
}

export function formatTimes100(value: number | null | undefined) {
  if (value === null || value === undefined) return "--";
  return formatFixed(value * 100);
}

type StatConfig = {
  headers: string[];
  statsMapping: Array<{
    key: string;
    format: (value: number | null) => string | number;
  }>;
};

export async function parseNbaStats(
  playerStats: Record<string, number | string | null>,
  measureType: string,
  configType: "season" | "game",
  seasonConfig: Record<string, StatConfig>,
  gameConfig: Record<string, StatConfig>,
  additionalColumns?: {
    season?: string | null;
    teamName?: string | null;
  }
) {
  const configs = {
    season: seasonConfig,
    game: gameConfig,
  };

  const config = configs[configType][measureType] as StatConfig;

  if (!config)
    return {
      stats: [],
      statsHeaders: [],
      error: "Error loading the player stats.",
    };

  const statsHeaders = config.headers.map((header) => header.toUpperCase());
  const stats = config.statsMapping.map(({ key, format }) => {
    if (key === "season" && additionalColumns?.season !== undefined) {
      return additionalColumns.season;
    }
    if (key === "teamName" && additionalColumns?.teamName !== undefined) {
      return additionalColumns.teamName;
    }

    const value = playerStats[key as keyof typeof playerStats] as
      | number
      | string
      | null
      | undefined;

    if (typeof value === "number") {
      return format(value);
    } else if (typeof value === "string") {
      return value;
    } else {
      return format(null);
    }
  });

  return { stats, statsHeaders };
}
export const formatChartMetricValue = (value: number | null): string => {
  if (value === null) return "0.00";

  return (Math.floor(value * 100) / 100).toFixed(2);
};

export const playerMetadataToYearlyChartData = (data: PlayerMetadataRow[]) => {
  return data?.reduce<Record<string, PlayerMetadataRow>>((acc, metadata) => {
    const snapshot =
      metadata.snapshotDate == null ? dayjs() : dayjs(metadata.snapshotDate);
    const snapshotYear = nbaSeasonFromDate(snapshot.format("YYYY-MM-DD"));
    if (!acc[snapshotYear]) {
      acc[snapshotYear] = metadata;
      return acc;
    }

    const currentMetadata = acc[snapshotYear];
    if (dayjs(snapshot).isAfter(currentMetadata.snapshotDate)) {
      acc[snapshotYear] = metadata;
    }
    return acc;
  }, {});
};

export function roundToSignificantDigit(num: number) {
  if (num === 0) return 0;
  const absNum = Math.abs(num);
  const magnitude = Math.pow(10, Math.floor(Math.log10(absNum)));
  return Math.sign(num) * Math.round(absNum / magnitude) * magnitude;
}

export function generateChartTicks(min: number, max: number, minTickCount = 6) {
  // Handle edge cases
  if (min === max) return [min];
  if (isNaN(min) || isNaN(max)) return [0];

  // Ensure min is always less than max
  if (min > max) [min, max] = [max, min];

  const range = max - min;

  // Calculate the ideal step size based on range and desired tick count
  const rawStep = range / (minTickCount - 1);

  // Find a "nice" step size (1, 2, 5, 10, 20, 50, 100, etc.)
  const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
  let step;

  if (rawStep / magnitude < 1.5) {
    step = magnitude;
  } else if (rawStep / magnitude < 3) {
    step = 2 * magnitude;
  } else if (rawStep / magnitude < 7.5) {
    step = 5 * magnitude;
  } else {
    step = 10 * magnitude;
  }

  // Calculate starting point (round down to nearest step)
  const start = Math.floor(min / step) * step;

  // Generate ticks
  const ticks = [];
  for (let i = start; i <= max + step / 10; i += step) {
    ticks.push(parseFloat(i.toFixed(10))); // Avoid floating point precision issues
  }

  // Ensure zero is included if it falls within the range
  if (min <= 0 && max >= 0 && !ticks.includes(0)) {
    ticks.push(0);
    ticks.sort((a, b) => a - b);
  }

  return ticks;
}
