import { Badge, Button, cn, Flex, Text, TextEditor } from "@suns/design-system";
import { IntelRow } from "@suns/api/generated-client/apollo";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { PlayersIntelTagsLabels } from "@/pages/players/players-const";

export function IntelListItem({
  intel,
  className,
  hideButtons,
  onTagClick,
}: {
  intel: IntelRow;
  className?: string;
  hideButtons?: boolean;
  onTagClick?: (tag: string) => void;
}) {
  const listItemHeader = [...(intel.players || []), ...(intel.teams || [])].map(
    (item, index, array) => (
      <Text key={item.id} size="xl" heading>
        {"firstName" in item
          ? `${item.firstName} ${item.lastName}`
          : item.fullName}
        {index < array.length - 1 && ","}
      </Text>
    )
  );

  return (
    <Flex direction="down" gap="md" className={className}>
      <Flex direction="down" gap="md">
        <Link
          className={cn(
            "flex flex-wrap gap-2",
            hideButtons && "text-gray-600 hover:text-black"
          )}
          to={`/reports/intel/view/${intel.id}`}
        >
          {listItemHeader}
        </Link>
        {intel.tags && intel.tags.length > 0 && (
          <Flex gap="sm" wrap>
            {intel.tags?.map((key) => {
              return (
                <Badge
                  key={`tag-${key}`}
                  variant="secondary"
                  className={cn("cursor-pointer hover:bg-secondary-950")}
                  onClick={() => onTagClick?.(key) || undefined}
                >
                  {PlayersIntelTagsLabels[key]}
                </Badge>
              );
            })}
          </Flex>
        )}

        <TextEditor defaultValue={intel.notes ?? undefined} readonly />
        <Text heading>— {intel.source}</Text>
      </Flex>

      {!hideButtons && (
        <Flex direction="down" gap="sm">
          <Flex gap="sm">
            <Link to={`/reports/intel/view/${intel.id}`}>
              <Button size="sm" variant="outline">
                View
              </Button>
            </Link>
            <Link to={`/reports/intel/edit/${intel?.id}`}>
              <Button size="sm" variant="outline">
                Edit
              </Button>
            </Link>
          </Flex>
        </Flex>
      )}
      <Flex direction="right" gap="xs">
        <Text size="sm" muted heading>
          {dayjs(intel.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text size="sm" muted>
          by
        </Text>
        <Text size="sm" muted heading>
          {intel.authorName}
        </Text>
      </Flex>
    </Flex>
  );
}
