import {
  cn,
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@suns/design-system";
import { CartesianGrid, Dot, Line, LineChart, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import { useState } from "react";
import {
  formatChartMetricValue,
  playerMetadataToYearlyChartData,
} from "@/shared/utils/helper-functions";
import { MetricButton } from "./MetricButton";
import { theme } from "@/shared/theme";
import { apolloGradeColor } from "@/shared/utils/colors";
import { CURRENT_DATE } from "@/shared/const";
import { PlayerMetadataRow } from "@suns/api/generated-client/apollo";

const chartConfig = {
  hybrid: {
    label: "Hybrid",
    color: theme.colors.purple.DEFAULT,
  },
  analytics: {
    label: "Analytics",
    color: theme.colors.magenta.DEFAULT,
  },
  scout: {
    label: "Scout",
    color: theme.colors.teal.DEFAULT,
  },
} satisfies ChartConfig;

export function PlayerApolloGradeValueChart({
  playerMetadata,
}: {
  playerMetadata: PlayerMetadataRow[];
}) {
  const [activeChart, setActiveChart] = useState<
    keyof typeof chartConfig | null
  >(null);

  const chartDataMonthly = playerMetadata
    ?.filter((metadata) => {
      if (metadata.snapshotDate === null) return true;
      const snapshotDate = new Date(metadata.snapshotDate);
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 8);
      return snapshotDate >= startDate;
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM DD YYYY")
        : dayjs().format("MMM DD YYYY"),
      hybrid: metadata.hybridGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.hybridGradeCurrent?.numericalValue)
        : null,
      analytics: metadata.analyticGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.analyticGradeCurrent?.numericalValue)
        : null,
      scout: metadata.scoutGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.scoutGradeCurrent?.numericalValue)
        : null,
    }))
    .sort(
      (a, b) =>
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
    )
    .filter((item, index, array) => {
      if (index === 0) return true;
      return item.snapshotDate !== array[index - 1].snapshotDate;
    });

  const chartDataAnnual = Object.values(
    playerMetadataToYearlyChartData(playerMetadata)
  )
    .sort((a, b) => {
      if (a.snapshotDate === null) return 1;
      if (b.snapshotDate === null) return -1;
      return (
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
      );
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM YYYY")
        : dayjs().format("MMM YYYY"),
      hybrid: metadata.hybridGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.hybridGradeCurrent?.numericalValue)
        : null,
      analytics: metadata.analyticGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.analyticGradeCurrent?.numericalValue)
        : null,
      scout: metadata.scoutGradeCurrent?.numericalValue
        ? formatChartMetricValue(metadata.scoutGradeCurrent?.numericalValue)
        : null,
    }));

  const onMetricClick = (metric: keyof typeof chartConfig) => {
    if (activeChart === metric) {
      setActiveChart(null);
    } else {
      setActiveChart(metric);
    }
  };

  type MetricKey = keyof typeof chartConfig;

  const getMetricValues = (
    data: typeof chartDataAnnual,
    metric: MetricKey
  ) => ({
    currentValue: data[data.length - 1]?.[metric]?.toString() ?? null,
    previousValue:
      data.find((d) => d[metric] !== null)?.[metric]?.toString() ?? null,
  });

  return (
    <Flex direction="down" gap="md" className="w-full">
      <Tabs defaultValue="annual">
        <Flex direction="right" justify="between">
          <Text heading muted size="lg">
            Apollo Grade Value
          </Text>
          <TabsList>
            <TabsTrigger value="annual">Annual</TabsTrigger>
            <TabsTrigger value="monthly">Monthly</TabsTrigger>
          </TabsList>
        </Flex>
        <TabsContent value="annual">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" wrap>
              {(
                Object.entries(chartConfig) as [
                  MetricKey,
                  (typeof chartConfig)[MetricKey],
                ][]
              ).map(([key, config]) => {
                const values = getMetricValues(chartDataAnnual, key);
                return (
                  <MetricButton
                    key={key}
                    label={config.label}
                    color={config.color}
                    currentValue={values.currentValue}
                    previousValue={values.previousValue}
                    active={activeChart === key}
                    onClick={() => onMetricClick(key)}
                  />
                );
              })}
            </Flex>
            <GradeValueChart
              timeframe="annual"
              chartData={chartDataAnnual.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate,
              }))}
              chartConfig={chartConfig}
              activeChart={activeChart}
            />
          </Flex>
        </TabsContent>
        <TabsContent value="monthly">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" wrap>
              {(
                Object.entries(chartConfig) as [
                  MetricKey,
                  (typeof chartConfig)[MetricKey],
                ][]
              ).map(([key, config]) => {
                const values = getMetricValues(chartDataMonthly, key);
                return (
                  <MetricButton
                    key={key}
                    label={config.label}
                    color={config.color}
                    currentValue={values.currentValue}
                    previousValue={values.previousValue}
                    active={activeChart === key}
                    onClick={() => onMetricClick(key)}
                  />
                );
              })}
            </Flex>
            <GradeValueChart
              timeframe="monthly"
              chartData={chartDataMonthly.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate,
              }))}
              chartConfig={chartConfig}
              activeChart={activeChart}
            />
          </Flex>
        </TabsContent>
      </Tabs>
    </Flex>
  );
}

interface CustomTickProps {
  height: number;
  x: number;
  y: number;
  payload: { value: number };
  visibleTicksCount: number;
}

const gradeLabels: Record<number, string> = {
  1: "ML",
  2: "RS",
  3: "RT",
  4: "TR",
  5: "ST",
  6: "AS",
  7: "AS+",
  8: "FR",
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomTick(props: any) {
  const { x, y, payload, visibleTicksCount, height } = props as CustomTickProps;
  const tickHeight = height / visibleTicksCount;
  const label = gradeLabels[payload.value];

  return (
    <g transform={`translate(${x},${y - tickHeight / 2})`}>
      <text x={0} y={0} textAnchor="end" fill="#666">
        {label}
      </text>
    </g>
  );
}

function GradeValueChart({
  chartData,
  chartConfig,
  timeframe,
  activeChart,
}: {
  chartData: {
    snapshotDate: string;
    hybrid: string | null;
    analytics: string | null;
    scout: string | null;
  }[];
  chartConfig: ChartConfig;
  timeframe: "monthly" | "annual";
  activeChart: keyof typeof chartConfig | null;
}) {
  return (
    <ChartContainer config={chartConfig} className="h-[400px] w-full md:h-full">
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          top: 12,
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="snapshotDate"
          tickLine={false}
          axisLine={false}
          tickMargin={12}
          tickFormatter={(value) => {
            const isAnnual = timeframe === "annual";
            const dateFormat = isAnnual ? "MMM YYYY" : "MMM DD YYYY";
            const isPresent = value === CURRENT_DATE.format("MMM DD YYYY");
            return isPresent
              ? "Today"
              : dayjs(value, dateFormat).format(isAnnual ? "'YY" : "MMM YY");
          }}
        />
        <YAxis
          width={23}
          dataKey="hybrid"
          type="number"
          domain={[1, 9]}
          ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          interval={0}
          tickLine={false}
          tick={<CustomTick />}
        />

        <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
        <ChartLegend content={<ChartLegendContent />} />
        {Object.entries(chartConfig).map(([key, config], index) => (
          <Line
            key={key + index}
            dataKey={key}
            type="monotone"
            stroke={config.color}
            className={cn(activeChart && activeChart !== key && "opacity-40")}
            strokeWidth={2}
            animationDuration={900}
            dot={({ payload, ...props }) => {
              return (
                <Dot
                  className={cn(
                    activeChart && activeChart !== key && "opacity-40"
                  )}
                  key={`${key}-${props.cx}-${props.cy}`}
                  r={5}
                  cx={props.cx}
                  cy={props.cy}
                  fill={
                    payload[key]
                      ? apolloGradeColor(payload[key], "hex")
                      : "transparent"
                  }
                  stroke={config.color}
                />
              );
            }}
          />
        ))}
      </LineChart>
    </ChartContainer>
  );
}
