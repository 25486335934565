import { formatMoney } from "@/shared/utils/helper-functions";
import { Input } from "@suns/design-system";

export function CurrencyInput(
  value?: string,
  onChange?: (value: string) => void
) {
  return (
    <Input
      className="text-[16px]"
      onChange={(event) => {
        const digits = event.target.value.replace(/\D/g, "");
        if (digits) {
          onChange?.(digits);
        } else {
          onChange?.("");
        }
      }}
      value={value ? formatMoney(Number(value)) : ""}
    />
  );
}
