import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { useState } from "react";
import { PlayerColumn } from "@/pages/players/players-listings";

import { Text } from "@suns/design-system/src/components/Text/Text";
import { Badge } from "@suns/design-system/src/components/Badge/Badge";
import { cn } from "@suns/design-system/src/utils/cn";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "@/pages/reports/reports-const";
import { apolloGradeColor } from "@/shared/utils/colors";
import dayjs from "dayjs";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { playersLoader } from "@/pages/players/loaders/player-loader";
import { useAsync } from "@/shared/hooks";
import { useNavigate } from "react-router-dom";
import { SortingState } from "@tanstack/react-table";
import { Flex } from "@suns/design-system/src/components/Flex/Flex";

const columns: PlayerColumn[] = [
  {
    header: "Player",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.lastName + ", " + row.firstName,
    enableHiding: false,
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "AAV",
    accessorKey: "aav",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.aav ? `$${row.metadata[0].aav.toLocaleString()}` : "--",
    sortingFn: "alphanumeric",
  },
  {
    header: "UFA",
    accessorKey: "unrestrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.unrestrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
  },
  {
    header: "RFA",
    accessorKey: "restrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.restrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
  },

  {
    header: "Position",
    accessorKey: "position",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Role",
    accessorKey: "role",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Apollo Grade",
    accessorKey: "grade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.hybridGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Years Of Service",
    accessorKey: "yearsOfService",
    accessorFn: (row: PlayerRow) =>
      row.yearsOfService !== null
        ? row.yearsOfService === 0
          ? "Rookie"
          : `${row.yearsOfService} years`
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (!row.height) return "--";
      const inches = Number(row.height);
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
];

export function TeamOverview({ teamId }: { teamId: string }) {
  const navigate = useNavigate();

  const [sortingColumns, setSortingColumns] = useState<SortingState>([
    {
      id: "name",
      desc: false,
    },
  ]);
  const [paginationValues, setPaginationValues] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const pagination = {
    pageIndex: paginationValues.pageIndex,
    pageSize: paginationValues.pageSize,
  };

  const { response, loading: playersLoading } = useAsync(playersLoader, {
    currentTeams: [teamId ?? ""],
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sortingColumns[0].id,
    sortDir: sortingColumns[0].desc ? "DESC" : "ASC",
  });

  const handleSelectPlayer = (id: number | null) => {
    if (!id) return;
    navigate(`/players/${id}`);
  };

  return (
    <Flex direction="down" gap="md">
      <Text variant="muted" heading>
        Roster
      </Text>
      <DataTable
        loading={playersLoading}
        columns={columns}
        data={response?.players || []}
        count={response?.count || 0}
        pagination={pagination}
        setPagination={(setPagination) => {
          const newPagination =
            typeof setPagination === "function"
              ? setPagination(pagination)
              : setPagination;

          setPaginationValues(newPagination);
        }}
        sorting={sortingColumns}
        setSorting={(setSorting) => {
          const newSort =
            typeof setSorting === "function"
              ? setSorting(sortingColumns)
              : setSorting;

          const sort = newSort[0] ?? null;

          setSortingColumns([
            {
              id: sort.id,
              desc: sort.desc,
            },
          ]);
        }}
        onRowClicked={(row) => handleSelectPlayer(row.original.id)}
      />
    </Flex>
  );
}
