import ErrorFallback from "@/components/error-fallback/error-fallback";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import { AgentView } from "@/pages/agents";
import { AgentListing } from "@/pages/agents";
import { PlayersListings, PlayersView } from "@/pages/players";
import { Suspense } from "react";

export const PlayersRoutes = {
  path: "players",
  children: [
    {
      path: "",
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <PlayersListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: ":playerId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <PlayersView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "agency/:agencyId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <AgentListing />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "agents",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <AgentListing />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "agents/:agentId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <AgentView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
};
