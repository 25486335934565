import { useState } from "react";
import { useAccount } from "@azure/msal-react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { apolloApi } from "@/shared/api";
import IntelForm, { IntelFormSchema } from "./components/IntelForm";
import { Button, Card, Flex, Skeleton, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import {
  IntelQueryParams,
  IntelUpsertParams,
} from "@suns/api/generated-client/apollo";
import { useAsync, useNavigateLogin, usePlayer, useTeam } from "@/shared/hooks";
import {
  intelFormDataToUpsertParams,
  intelResponseToFormData,
} from "./intel-utils";
import { toast, ToastType } from "@/shared/utils/toaster";
import { SunsApiError } from "@suns/api";
import { PlayerLevel } from "./tags";
import { intelLoader } from "../loaders/intel-loaders";
function defaultIntel({
  teamId,
  playerId,
  level,
  authorUsername,
  authorName,
}: Pick<
  IntelFormSchema,
  "teamId" | "playerId" | "level" | "authorUsername" | "authorName"
>) {
  return {
    teamId,
    playerId,
    level,
    status: IntelUpsertParams.status.UNPUBLISHED,
    authorUsername,
    authorName,
  };
}

export function IntelCreatePlayerTeam() {
  const navigateLogin = useNavigateLogin();
  const navigate = useNavigate();
  const account = useAccount();
  const { playerId, teamId } = useParams();
  const [saving, setSaving] = useState(false);
  const [savedIntelId, setSavedIntelId] = useState<number>();

  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));
  const {
    team,
    loading: teamLoading,
    error: teamError,
  } = useTeam(Number(teamId));

  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelLoader, {
    teamIds: teamId ? [Number(teamId)] : undefined,
    playerIds: playerId ? [Number(playerId)] : undefined,
    authorUsername: account?.username ? [account.username] : undefined,
    status: IntelQueryParams.status.UNPUBLISHED,
  });

  const intelData = intel?.intel[0];

  const handleSave = async (intelFormData: IntelFormSchema) => {
    try {
      setSaving(true);
      const savedIntel = await apolloApi.saveIntel({
        ...intelFormDataToUpsertParams(intelFormData),
        id: intelData?.id ?? savedIntelId,
      });

      setSavedIntelId(savedIntel.intel.id);

      if (intelFormData.status !== IntelUpsertParams.status.PUBLISHED) {
        toast(ToastType.INFO, "Intel Saved");
        return;
      }

      const player = savedIntel.intel.players?.find(
        (player) => player.id === Number(playerId)
      );

      let searchParams;
      try {
        const { team } = await apolloApi.getTeam(
          player?.currentTeams?.[0].id || Number(teamId)
        );

        searchParams = createSearchParams({
          teamId: `${team.id}`,
          leagueId: `${team.domesticLeagueId}`,
          conference: team.conference || "Other",
        }).toString();
      } catch (e) {
        void e;
      }

      toast(ToastType.SUCCESS, "Intel Published");

      navigate({
        pathname: "/reports/intel/create",
        search: searchParams,
      });
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        navigateLogin();
      }
      toast(ToastType.ERROR, "Unable to save intel. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (intelError) {
    throw new SunsApiError("There was an error requesting intel.", {
      cause: intelError,
    });
  }

  if (teamError) {
    throw new SunsApiError("There was an error requesting the team.", {
      cause: teamError,
    });
  }

  if (playerError) {
    throw new SunsApiError("There was an error requesting the player.", {
      cause: playerError,
    });
  }

  const loading = intelLoading || teamLoading || playerLoading;

  return (
    <>
      <Flex className="mb-2" align="center">
        <Button
          size="xs"
          variant="ghost"
          className="p-0 text-sm text-gray-500"
          onClick={() => navigate("/reports/intel/create")}
        >
          Intel
        </Button>
        <ChevronRight className="text-gray-500 " size={20} />
        <Button size="xs" variant="ghost" className="p-0 text-sm text-gray-500">
          New Intel
        </Button>
      </Flex>
      <Text heading={true} size="4xl" className="mb-4">
        New Intel
      </Text>
      <Card>
        {loading ? (
          <Flex gap="md" className="mt-1">
            <Skeleton className="h-10 w-40" />
            <Skeleton className="h-10 w-40" />
          </Flex>
        ) : (
          <>
            <IntelForm
              intel={
                intelData
                  ? intelResponseToFormData(intelData)
                  : defaultIntel(
                      player
                        ? {
                            playerId: [player.id],
                            level: player.level,
                            authorUsername: account!.username,
                            authorName: account!.name!,
                          }
                        : {
                            teamId: team ? [team.id] : [],
                            level:
                              team?.level.toLocaleUpperCase() ===
                              PlayerLevel.PRO
                                ? PlayerLevel.PRO
                                : PlayerLevel.AMATEUR,
                            authorUsername: account!.username,
                            authorName: account!.name!,
                          }
                    )
              }
              onSave={handleSave}
              onPublish={handleSave}
              saving={saving}
            />
          </>
        )}
      </Card>
    </>
  );
}
