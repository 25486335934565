import { apolloApi } from "@/shared/api";
import { getLeagueIdFromNbaLeagueId } from "@/shared/utils/league-id";
import { NbaLeagueId } from "@suns/api";

export async function teamsLoader({
  nbaLeagueId,
}: {
  nbaLeagueId: NbaLeagueId;
}) {
  const { teams } = await apolloApi.getTeams({
    domesticLeagueId: getLeagueIdFromNbaLeagueId(nbaLeagueId),
  });
  return teams;
}
