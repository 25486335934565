/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Season Type
 */
export enum seasonType {
    REGULAR_SEASON = 'Regular Season',
    PRE_SEASON = 'Pre Season',
    PRESEASON = 'Preseason',
    PLAYOFFS = 'Playoffs',
    ALL_STAR = 'All-Star',
    PLAY_IN = 'PlayIn',
    IST_CHAMPIONSHIP = 'IST Championship',
}
