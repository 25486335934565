import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { NAVIGATION } from "@/shared/utils/navigation";
import { useAccount } from "@/shared/hooks";
import {
  PlayerTeamAgentSearch,
  SearchType,
} from "../PlayerTeamSearch/PlayerTeamAgentSearch";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const account = useAccount();
  const componentRef = useSelector(
    (state: { printRef: { componentRef: React.ReactInstance | null } }) =>
      state.printRef.componentRef
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  if (["/", "/glossary"].includes(location.pathname)) {
    return <div className="header-empty"></div>;
  }

  const activeNavigation = NAVIGATION.find(
    (navItem) =>
      navItem.path != "/" && location.pathname.startsWith(navItem.path)
  );

  if (activeNavigation?.header) {
    return (
      <header
        className="fixed left-0 top-0 z-10 mt-16 w-full bg-white shadow-md lg:ml-52 lg:mt-0
          print:hidden"
      >
        {activeNavigation.header}
      </header>
    );
  }

  const showPrinter = activeNavigation && activeNavigation.showPrinter;

  const handleSelectPlayer = (id: number | null, type: SearchType) => {
    if (type === SearchType.PLAYER) {
      navigate(`/players/${id}`);
    } else if (type === SearchType.AGENT) {
      navigate(`/players/agents/${id}`);
    }
  };

  return (
    <header className="header-main print:hidden">
      <nav>
        <ul>
          {activeNavigation
            ? activeNavigation.children?.map((childNav) => {
                const hasPermission =
                  (!childNav.requiredRoles ||
                    childNav.requiredRoles.some(
                      (role) => account[role as keyof typeof account] === true
                    )) &&
                  !childNav.disabledRoles?.some(
                    (role) => account[role as keyof typeof account] === true
                  );

                if (!hasPermission) {
                  return null;
                }
                return (
                  <li key={childNav.path}>
                    <NavLink
                      to={childNav.path}
                      className={({ isActive, isPending }) =>
                        isActive ? "active" : isPending ? "pending" : ""
                      }
                      end
                    >
                      {childNav.title}
                    </NavLink>
                  </li>
                );
              })
            : null}
          {location.pathname.startsWith("/players") && (
            <div className="w-[260px] md:w-[400px]">
              <PlayerTeamAgentSearch
                type={[SearchType.PLAYER, SearchType.AGENT]}
                onChange={(id, type) => {
                  handleSelectPlayer(id, type as SearchType);
                }}
              />
            </div>
          )}
        </ul>
      </nav>
      {showPrinter ? (
        <div className="print">
          <a
            className={`${!componentRef ? "disabled" : ""}`}
            onClick={componentRef ? handlePrint : () => {}}
          >
            <i className="cs-icon icon-print"></i>
            <span>Print</span>
          </a>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
