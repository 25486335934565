import { useState } from "react";
import { Flex, Text } from "@suns/design-system";
import { TeamRow } from "@suns/api/generated-client/apollo";
import { CURRENT_SEASON, CURRENT_SEASON_LABEL } from "@/shared/const";
import { StatsTable } from "@/components";
import { TeamStatParams, useTeamStats } from "../hooks/useTeamStats";

export interface Stats {
  statsHeaders: string[];
  stats: (string | number)[];
  error?: string;
  loading?: boolean;
  response?: Array<{
    statsHeaders: string[];
    stats: (string | number)[];
  }>;
}

interface StatsTableConfig {
  title: string;
  stats: Stats[];
  loading: boolean;
  error: Error | null;
  measureTypeKey: string;
  perModeKey?: string;
  additionalProps?: {
    playerLastNGames?: string;
    setPlayerLastNGames?: (value: string) => void;
    seasonType?: string;
    setSeasonType?: (value: string) => void;
  };
}

export function TeamStats({ team }: { team: TeamRow }) {
  const createSeasons = (startYear: number, numSeasons: number) => {
    return Array.from({ length: numSeasons }, (_, i) => {
      const year = startYear - i;
      const shortNext = (year + 1).toString().slice(2);
      return `${year}-${shortNext}`;
    });
  };

  const seasons = createSeasons(CURRENT_SEASON, 5).join(",");

  const [statParams, setStatParams] = useState({
    measureTypeNBARegularSeason: "Base",
    perModeNBARegularSeason: "PerGame",
    measureTypeNBAPlayoffs: "Base",
    perModeNBAPlayoffs: "PerGame",
    measureTypeNbaGames: "Base",
    playerLastNGamesNba: "5",
    measureTypeNbaTeamRoster: "Base",
    perModeNbaTeamRoster: "PerGame",

    measureTypeGLeagueRegularSeason: "Base",
    perModeGLeagueRegularSeason: "PerGame",
    measureTypeGLeaguePlayoffs: "Base",
    perModeGLeaguePlayoffs: "PerGame",
    measureTypeGLeagueShowcase: "Base",
    perModeGLeagueShowcase: "PerGame",
    nbaGamesSeasonType: "Regular Season",
    measureTypeGLeagueGames: "Base",
    gLeaguePlayerLastNGames: "5",
    gLeagueGamesSeasonType: "Regular Season",
    measureTypeGLeagueTeamRoster: "Base",
    perModeGLeagueTeamRoster: "PerGame",
  });

  const nbaId = team?.nbaId;

  const {
    nbaRegularSeasonStats,
    nbaPlayoffStats,
    nbaRosterSeasonStats,
    nbaGameStats,
    gLeagueStatsShowcase,
    gLeagueRegularSeasonStats,
    gLeagueStatsPlayoffs,
    gLeagueRosterSeasonStats,
    gLeagueGameStats,
  } = useTeamStats({
    statParams: statParams as TeamStatParams,
    seasons,
    nbaTeamId: nbaId,
    apolloLeagueId: team?.domesticLeagueId,
    currentSeason: CURRENT_SEASON_LABEL,
  });

  const handleStatsChange = (key: string, value: string) => {
    if (key === "nbaGamesSeasonType" && value === "IST") {
      setStatParams((prev) => ({
        ...prev,
        playerLastNGamesNba: "0",
        nbaGamesSeasonType: "IST",
      }));
    } else {
      setStatParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const statsConfigs: StatsTableConfig[] = [
    {
      title: "Game Stats",
      stats: nbaGameStats.response as Stats[],
      loading: nbaGameStats.loading,
      error: nbaGameStats.error,
      measureTypeKey: "NbaGames",
      additionalProps: {
        playerLastNGames: statParams.playerLastNGamesNba,
        setPlayerLastNGames: (value) =>
          handleStatsChange("playerLastNGamesNba", value),
        seasonType: statParams.nbaGamesSeasonType,
        setSeasonType: (value) =>
          handleStatsChange("nbaGamesSeasonType", value),
      },
    },
    {
      title: "Regular Season Stats",
      stats: nbaRegularSeasonStats.response as Stats[],
      loading: nbaRegularSeasonStats.loading,
      error: nbaRegularSeasonStats.error,
      measureTypeKey: "NBARegularSeason",
      perModeKey: "NBARegularSeason",
    },

    {
      title: "Playoffs Stats",
      stats: nbaPlayoffStats.response as Stats[],
      loading: nbaPlayoffStats.loading,
      error: nbaPlayoffStats.error,
      measureTypeKey: "NBAPlayoffs",
      perModeKey: "NBAPlayoffs",
    },

    {
      title: "Team Stats",
      stats: nbaRosterSeasonStats.response as Stats[],
      loading: nbaRosterSeasonStats.loading,
      error: nbaRosterSeasonStats.error,
      measureTypeKey: "NbaTeamRoster",
      perModeKey: "NbaTeamRoster",
    },

    {
      title: "Game Stats",
      stats: gLeagueGameStats.response as Stats[],
      loading: gLeagueGameStats.loading,
      error: gLeagueGameStats.error,
      measureTypeKey: "GLeagueGames",
      additionalProps: {
        playerLastNGames: statParams.gLeaguePlayerLastNGames,
        setPlayerLastNGames: (value) =>
          handleStatsChange("gLeaguePlayerLastNGames", value),
        seasonType: statParams.gLeagueGamesSeasonType,
        setSeasonType: (value) =>
          handleStatsChange("gLeagueGamesSeasonType", value),
      },
    },

    {
      title: "Showcase Stats",
      stats: gLeagueStatsShowcase.response as Stats[],
      loading: gLeagueStatsShowcase.loading,
      error: gLeagueStatsShowcase.error,
      measureTypeKey: "GLeagueShowcase",
      perModeKey: "GLeagueShowcase",
    },

    {
      title: "Regular Season Stats",
      stats: gLeagueRegularSeasonStats.response as Stats[],
      loading: gLeagueRegularSeasonStats.loading,
      error: gLeagueRegularSeasonStats.error,
      measureTypeKey: "GLeagueRegularSeason",
      perModeKey: "GLeagueRegularSeason",
    },

    {
      title: "Playoffs Stats",
      stats: gLeagueStatsPlayoffs.response as Stats[],
      loading: gLeagueStatsPlayoffs.loading,
      error: gLeagueStatsPlayoffs.error,
      measureTypeKey: "GLeaguePlayoffs",
      perModeKey: "GLeaguePlayoffs",
    },

    {
      title: "Team Stats",
      stats: gLeagueRosterSeasonStats.response as Stats[],
      loading: gLeagueRosterSeasonStats.loading,
      error: gLeagueRosterSeasonStats.error,
      measureTypeKey: "GLeagueTeamRoster",
      perModeKey: "GLeagueTeamRoster",
    },
  ];

  return (
    <Flex direction="down" gap="md">
      {nbaId ? (
        <>
          {statsConfigs.map((stats, index) => (
            <StatsTable
              key={stats.title + index}
              title={stats.title}
              loading={stats.loading}
              error={stats.error ? true : null}
              stats={stats.stats}
              measureType={
                statParams[
                  `measureType${stats.measureTypeKey}` as keyof typeof statParams
                ]
              }
              setMeasureType={(value) =>
                handleStatsChange(`measureType${stats.measureTypeKey}`, value)
              }
              perMode={
                stats.perModeKey
                  ? statParams[
                      `perMode${stats.perModeKey}` as keyof typeof statParams
                    ]
                  : undefined
              }
              setPerMode={
                stats.perModeKey
                  ? (value) =>
                      handleStatsChange(`perMode${stats.perModeKey}`, value)
                  : undefined
              }
              {...stats.additionalProps}
            />
          ))}
        </>
      ) : (
        <>
          <Text size="lg" heading muted className="py-4 text-center">
            No stats available
          </Text>
        </>
      )}
    </Flex>
  );
}
