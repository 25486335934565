import {
  formatChartMetricValue,
  formatMoney,
} from "@/shared/utils/helper-functions";
import { Button, cn, Flex, Text } from "@suns/design-system";

export interface MetricButtonProps {
  label: string;
  color: string;
  currentValue: string | null;
  previousValue: string | null;
  active?: boolean;
  onClick?: () => void;
  differenceType?: "percentage" | null;
  disabled?: boolean;
}

export function MetricButton({
  label,
  color,
  currentValue,
  previousValue,
  active,
  onClick,
  differenceType,
  disabled,
}: MetricButtonProps) {
  let diff;
  if (differenceType === "percentage") {
    if (
      previousValue === null ||
      previousValue === "0" ||
      Number(previousValue) === 0
    ) {
      diff = 0;
    } else {
      diff =
        ((Number(currentValue) - Number(previousValue)) /
          Math.abs(Number(previousValue))) *
        100;
    }
  } else {
    diff = Number(currentValue) - Number(previousValue);
  }

  const isPositive = diff >= 0;

  return (
    <Button
      variant="ghost"
      className={cn(
        "h-10 rounded-md py-8",
        disabled && "pointer-events-none hover:bg-transparent"
      )}
      onClick={onClick}
      style={{
        backgroundColor: active && !disabled ? "#F0F0F0" : undefined,
      }}
    >
      <Flex direction="down" align="start" justify="start">
        <Text className="text-md max-sm:text-sm" heading style={{ color }}>
          {label}
        </Text>
        <Flex direction="right" align="end">
          <Text className="max-sm:text-md text-lg" heading>
            {currentValue
              ? differenceType === "percentage"
                ? formatMoney(Number(currentValue))
                : currentValue
              : "--"}
          </Text>
          <Text
            heading
            className={`max-sm:text-md ml-1 text-lg ${isPositive ? "text-green" : "text-red"}`}
          >
            {isPositive && diff !== 0 ? "+" : ""}
            {diff
              ? differenceType === "percentage"
                ? `${diff.toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}%`
                : formatChartMetricValue(diff)
              : null}
          </Text>
        </Flex>
      </Flex>
    </Button>
  );
}
