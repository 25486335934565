import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useParams<
  T extends string,
  D extends Partial<Record<T, string>>,
  P = D & Record<Exclude<T, keyof D>, string | undefined>,
>(
  keys: T[],
  defaultParams?: D
): {
  params: P;
  setParams: (newParams: Partial<Record<T, string>>) => void;
  resetParams: () => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(
    () =>
      keys.reduce<P>((acc, key) => {
        const value = searchParams.get(key) || defaultParams?.[key];
        if (value) {
          acc[key as unknown as keyof P] = value as P[keyof P];
        }
        return acc;
      }, {} as P),
    [defaultParams, keys, searchParams]
  );

  function setParams(newParams: Partial<Record<T, string>>) {
    const formattedParams = (Object.keys(newParams) as T[]).reduce(
      (acc, key) => {
        const value = newParams[key];
        if (value == defaultParams?.[key] || !value) {
          acc.delete(key);
        } else {
          acc.set(key, value);
        }
        return acc;
      },
      new URLSearchParams()
    );
    setSearchParams(formattedParams);
  }

  function resetParams() {
    setSearchParams(new URLSearchParams());
  }

  return { params, setParams, resetParams };
}
