/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractSalaryRow = {
    id: number;
    contractId: number;
    year: number;
    option: ContractSalaryRow.option;
    optionDecision: ContractSalaryRow.optionDecision;
    protectionCoverage: ContractSalaryRow.protectionCoverage;
    applicableMinSalary: boolean;
    capTaxVariance: boolean;
    capRaisePercent: number;
    currentBaseComp: number;
    deferredBaseComp: number;
    totalBaseComp: number;
    signingBonus: number;
    intlPlayerPayment: number;
    twoWaySalary: number;
    likelyBonus: number;
    unlikelyBonus: number;
    tradeBonus: number;
    offseasonBonus: number;
    totalSalary: number;
    capSalaryAdjustment: number;
    taxSalaryAdjustment: number;
    apronSalaryAdjustment: number;
    totalSalaryAdjustment: number;
    capSalary: number;
    taxSalary: number;
    apronSalary: number;
    apronAssumptionSalary: number;
    minTeamSalary: number;
    skillProtection: number;
    comments: string;
};
export namespace ContractSalaryRow {
    export enum option {
        NONE = 'NONE',
        PLYR = 'PLYR',
        TEAM = 'TEAM',
        ETO = 'ETO',
        PLYTF = 'PLYTF',
        TMPLY = 'TMPLY',
    }
    export enum optionDecision {
        DECL = 'DECL',
        ETOCA = 'ETOCA',
        ETODE = 'ETODE',
        ETOEX = 'ETOEX',
        EXER = 'EXER',
        NODEC = 'NODEC',
        POD = 'POD',
        POE = 'POE',
        TOCA = 'TOCA',
        TOD = 'TOD',
        TOE = 'TOE',
        WETO = 'WETO',
        WTOPT = 'WTOPT',
        WVPF = 'WVPF',
        WVTF = 'WVTF',
    }
    export enum protectionCoverage {
        FULL = 'FULL',
        NOCND = 'NOCND',
        NONE = 'NONE',
        PART = 'PART',
        PTCND = 'PTCND',
    }
}

