import { LeagueId, NbaLeagueId, MeasureType } from "@suns/api";
import { PlayerSeasonStatsParams } from "@suns/api/src/suns-api";
import { teamGrouping } from "@suns/api/generated-client/querytool";
import { useAsync } from "@/shared/hooks/useAsync";
import {
  playerGameStatsLoader,
  playerSeasonStatsLoader,
} from "../loaders/player-loader";
import { seasonType } from "@suns/api/generated-client/stats";
import { CURRENT_SEASON_LABEL } from "@/shared/const";

export interface StatParams {
  measureTypeNBARegularSeason: MeasureType;
  perModeNBARegularSeason: PlayerSeasonStatsParams["perMode"];

  measureTypeNBAPlayoffs: MeasureType;
  perModeNBAPlayoffs: PlayerSeasonStatsParams["perMode"];

  measureTypeGLeagueRegularSeason: MeasureType;
  perModeGLeagueRegularSeason: PlayerSeasonStatsParams["perMode"];

  measureTypeGLeagueShowcase: MeasureType;
  perModeGLeagueShowcase: PlayerSeasonStatsParams["perMode"];

  measureTypeGLeaguePlayoffs: MeasureType;
  perModeGLeaguePlayoffs: PlayerSeasonStatsParams["perMode"];

  measureTypeNbaGames: string;
  nbaGamesSeasonType: seasonType;
  playerLastNGamesNba: string;

  gLeagueGamesSeasonType: seasonType;
  measureTypeGLeagueGames: string;
  gLeaguePlayerLastNGames: string;
}

export const usePlayerStats = ({
  statParams,
  seasons,
  playerId,
  currentSeason,
  apolloLeagueIds,
}: {
  statParams: StatParams;
  seasons: string;
  playerId: number | undefined;
  apolloLeagueIds: LeagueId[];
  currentSeason: typeof CURRENT_SEASON_LABEL;
}) => {
  const isGLeaguePlayer = apolloLeagueIds.includes(LeagueId.GLEAGUE);

  // * NBA Season Stats
  const nbaRegularSeasonStats = useAsync(
    playerSeasonStatsLoader,
    {
      scope: "season",
      leagueId: NbaLeagueId.NBA,
      perMode:
        statParams.perModeNBARegularSeason as PlayerSeasonStatsParams["perMode"],
      seasonYear: seasons,
      seasonType: seasonType.REGULAR_SEASON,
      grouping: "LeagueYear",
      playerId: playerId,
      measureType: statParams.measureTypeNBARegularSeason as MeasureType,
      teamGrouping: teamGrouping.Y,
    },
    !playerId
  );

  const nbaPlayoffStats = useAsync(
    playerSeasonStatsLoader,
    {
      scope: "season",
      leagueId: NbaLeagueId.NBA,
      perMode:
        statParams.perModeNBAPlayoffs as PlayerSeasonStatsParams["perMode"],
      seasonYear: seasons,
      seasonType: seasonType.PLAYOFFS,
      grouping: "LeagueYear",
      playerId: playerId,
      measureType: statParams.measureTypeNBAPlayoffs as MeasureType,
      teamGrouping: teamGrouping.Y,
    },
    !playerId
  );

  // * G-League Season Stats
  const gLeagueRegularSeasonStats = useAsync(
    playerSeasonStatsLoader,
    {
      scope: "season",
      leagueId: NbaLeagueId.GLEAGUE,
      perMode:
        statParams.perModeGLeagueRegularSeason as PlayerSeasonStatsParams["perMode"],
      seasonYear: seasons,
      seasonType: [seasonType.REGULAR_SEASON, "Showcase"],
      grouping: "LeagueYear",
      playerId: playerId,
      measureType: statParams.measureTypeGLeagueRegularSeason as MeasureType,
      teamGrouping: teamGrouping.Y,
    },
    !isGLeaguePlayer
  );

  const gLeagueStatsPlayoffs = useAsync(
    playerSeasonStatsLoader,
    {
      scope: "season",
      leagueId: NbaLeagueId.GLEAGUE,
      perMode:
        statParams.perModeGLeaguePlayoffs as PlayerSeasonStatsParams["perMode"],
      seasonYear: seasons,
      seasonType: seasonType.PLAYOFFS,
      grouping: "LeagueYear",
      playerId: playerId,
      measureType: statParams.measureTypeGLeaguePlayoffs as MeasureType,
      teamGrouping: teamGrouping.Y,
    },
    !isGLeaguePlayer
  );

  // * Game Stats
  const nbaGameStats = useAsync(
    playerGameStatsLoader,

    {
      leagueId: NbaLeagueId.NBA,
      seasonYear: currentSeason,
      seasonType: statParams.nbaGamesSeasonType as seasonType,
      playerId: playerId,
      measureType:
        statParams.measureTypeNbaGames === "Defense"
          ? "Defensive"
          : (statParams.measureTypeNbaGames as MeasureType),
      teamGrouping: teamGrouping.Y,
      grouping: "None",
      playerLastNGames: Number(statParams.playerLastNGamesNba),
    },
    !playerId
  );

  const gLeagueGameStats = useAsync(
    playerGameStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      seasonYear: currentSeason,
      seasonType: statParams.gLeagueGamesSeasonType as seasonType,
      playerId: playerId,
      measureType: statParams.measureTypeGLeagueGames as MeasureType,
      teamGrouping: teamGrouping.Y,
      grouping: "None",
      playerLastNGames: Number(statParams.gLeaguePlayerLastNGames),
    },
    !isGLeaguePlayer
  );

  return {
    nbaRegularSeasonStats,
    nbaPlayoffStats,
    gLeagueRegularSeasonStats,
    gLeagueStatsPlayoffs,
    nbaGameStats,
    gLeagueGameStats,
  };
};
