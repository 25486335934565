import { editReportLoader } from "./loaders/edit-report-loader";
import { GameHero } from "@/components/GameHero/GameHero";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import { apolloApi } from "@/shared/api";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { useState } from "react";
import { SunsApiError } from "@suns/api";
import { useParams } from "react-router-dom";
import { useAccount, useAsync, useNavigateLogin } from "@/shared/hooks";
import { Page, PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import {
  Card,
  Container,
  Flex,
  SkeletonHeading,
  SkeletonText,
} from "@suns/design-system";
import { toast, ToastType } from "@/shared/utils/toaster";
import { getLeagueIdFromNbaGameId } from "@/shared/utils";

export function ReportEdit() {
  const navigateLogin = useNavigateLogin();
  const { reportId } = useParams();
  const account = useAccount();
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const { response, loading, error } = useAsync(editReportLoader, {
    reportId: reportId || "",
  });

  async function handleSubmit(
    report: ReportFormSchema,
    publish: boolean = false
  ) {
    if (publish) {
      setIsPublishing(true);
    } else {
      setIsSaving(true);
    }

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const res = await apolloApi.saveReport(upsertParams);

      if (publish) {
        toast(ToastType.SUCCESS, "Report Published");
      } else {
        toast(ToastType.INFO, "Report Saved");
      }

      return res.report.id;
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        navigateLogin();
      }
      toast(ToastType.ERROR, "Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  }

  if (error) {
    throw new SunsApiError("Error loading the report.", { cause: error });
  }

  if (loading) {
    return <ReportEditLoading />;
  }

  if (!account.info) {
    throw new Error("User Authorization Error", { cause: account });
  }

  const { report, availableReports } = response;

  if (!report) {
    throw new Error("Error loading report data");
  }

  const parsedReport = reportResponseToFormData(
    report.player,
    account.info,
    report.type,
    report.games,
    report,
    availableReports
  );

  const team = report.player.currentTeams?.[0];

  return (
    <Page title="Edit Report">
      {parsedReport.games.length === 1 && (
        <Card className="mb-4 overflow-hidden">
          <GameHero
            leagueId={getLeagueIdFromNbaGameId(parsedReport.games[0].id)}
            gameStatus={parsedReport.games[0].gameStatus || 1}
            gameTimeUTC={parsedReport.games[0].gameTimeUTC}
            homeTeam={{
              id: parsedReport.games[0].homeTeam.id,
              image: parsedReport.games[0].homeTeam.image,
              tricode: parsedReport.games[0].homeTeam.code,
            }}
            awayTeam={{
              id: parsedReport.games[0].awayTeam.id,
              image: parsedReport.games[0].awayTeam.image,
              tricode: parsedReport.games[0].awayTeam.code,
            }}
          />
        </Card>
      )}
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="max-w-4xl" gap="lg">
            <PlayerHeader
              player={report.player}
              nbaTeamId={team?.nbaId ?? undefined}
              teamName={team?.name}
              teamImage={team?.image ?? undefined}
              leagueId={team?.domesticLeagueId}
            />
            <ReportForm
              report={parsedReport}
              onSubmit={handleSubmit}
              isSaving={isSaving}
              isPublishing={isPublishing}
            />
          </Flex>
        </Flex>
      </Card>
    </Page>
  );
}

function ReportEditLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex justify="center">
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="sm">
              <SkeletonHeading size="3xl" />
              <SkeletonHeading />
            </Flex>
            <PlayerHeaderSkeleton />
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
