import { ApolloGrade } from "@suns/api";

const apolloGradeValues = [
  {
    grade: ApolloGrade.FRANCHISE,
    min: 8,
    lt: 9,
    default: 8.5,
  },
  {
    grade: ApolloGrade.ALL_STAR_PLUS,
    min: 7,
    lt: 8,
    default: 7.5,
  },
  {
    grade: ApolloGrade.ALL_STAR,
    min: 6,
    lt: 7,
    default: 6.5,
  },
  {
    grade: ApolloGrade.STARTER,
    min: 5,
    lt: 6,
    default: 5.5,
  },
  {
    grade: ApolloGrade.TOP_ROTATION,
    min: 4,
    lt: 5,
    default: 4.5,
  },
  {
    grade: ApolloGrade.ROTATION,
    min: 3,
    lt: 4,
    default: 3.5,
  },
  {
    grade: ApolloGrade.ROSTER,
    min: 2,
    lt: 3,
    default: 2.5,
  },
  {
    grade: ApolloGrade.MINOR_LEAGUE,
    min: 1,
    lt: 2,
    default: 1.5,
  },
];

export function apolloGradeToNumericalValue(value: ApolloGrade) {
  return apolloGradeValues.find(({ grade }) => grade === value)!.default;
}

export function apolloGradeFromNumericalValue(value: number) {
  const gradeObj = apolloGradeValues.find(
    ({ min, lt }) => value >= min && value < lt
  );

  if (!gradeObj) {
    throw new Error(
      `${value} is an invalid numerical value for Apollo Grade. Number must be between 1 and 9.`
    );
  }

  return gradeObj.grade;
}

export function apolloGradeMax(value: ApolloGrade) {
  return (
    apolloGradeValues.find(({ grade }) => grade === value)!.lt - 0.000000001
  );
}

export function apolloGradeMin(value: ApolloGrade) {
  return apolloGradeValues.find(({ grade }) => grade === value)!.min;
}
