import { TeamLogo } from "@/components";
import {
  ContractRow,
  ContractSalaryRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import {
  Badge,
  Button,
  Flex,
  Grid,
  Select,
  SelectOption,
  Separator,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";
import {
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PenToolIcon,
  SquareArrowOutUpRightIcon,
} from "lucide-react";
import { useState } from "react";
import { PlayerDetails } from "./PlayerDetails";
import { playerContractLoader } from "../loaders/player-contract-loader";
import { useAsync } from "@/shared/hooks";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { SunsApiError } from "@suns/api";
import {
  ContractBonusEarnedLabels,
  ContractBonusTypeLabels,
  ContractFlagLabels,
  ContractMaximumTypeLabels,
  ContractMinimumTypeLabels,
  ContractPaymentScheduleLabels,
  ContractPlayerConsentLabels,
  ContractSalaryOptionLabels,
  ContractSignedMethodLabels,
  ContractStatusLabels,
  ContractTradeRestrictionLabels,
  ContractTypeLabels,
} from "../players-const";
import { CURRENT_SEASON, CURRENT_SEASON_LABEL } from "@/shared/const";
import { dedupe, formatMoney } from "@/shared/utils/helper-functions";

const ContractTableCell = ({ children }: { children?: React.ReactNode }) => (
  <TableCell className="border-x text-left">{children}</TableCell>
);

export default function PlayerContracts({ player }: { player: PlayerRow }) {
  const { loading, response, error } = useAsync(playerContractLoader, {
    playerId: player.id,
  });

  if (loading) {
    return <ContractSkeleton />;
  }

  if (error) {
    throw new SunsApiError("Error loading the contracts.", { cause: error });
  }

  if (response.contracts.length === 0) {
    return (
      <Text className="py-10 text-center" size="lg" muted>
        No contracts found.
      </Text>
    );
  }

  return (
    <Flex direction="down" gap="md">
      {response?.contracts.map((contract, i) => (
        <Flex direction="down" key={contract.id}>
          <PlayerContract initialOpenState={i === 0} contract={contract} />
          {i < response.contracts.length - 1 && <Separator className="mt-4" />}
        </Flex>
      ))}
    </Flex>
  );
}

function PlayerContract({
  initialOpenState,
  contract,
}: {
  initialOpenState: boolean;
  contract: ContractRow;
}) {
  const [open, setIsOpen] = useState(initialOpenState);

  const salaries = contract.contractSalaries.sort(
    (salary1, salary2) => salary1.year - salary2.year
  );

  const currentSeasonCapHit = salaries.find(
    (salary) => salary.year === CURRENT_SEASON
  )?.capSalary;

  const hasOptions = contract.contractSalaries.some(
    (contractSalary) =>
      contractSalary.option ||
      contractSalary.applicableMinSalary ||
      contractSalary.capTaxVariance
  );

  function addContractRow(
    rows: React.ReactNode[],
    field: keyof ContractSalaryRow,
    label: string,
    type: "currency" | "percent",
    className?: string
  ) {
    const shouldDisplayRow = salaries.some(
      (contractSalary) => contractSalary[field]
    );

    if (shouldDisplayRow) {
      const total = salaries.reduce((acc, contractSalary) => {
        return acc + (Number(contractSalary[field]) ?? 0);
      }, 0);

      const totalDisplayValue = type === "currency" ? formatMoney(total) : null;

      rows.push(
        <TableRow key={field} className={className}>
          <ContractTableCell>{label}</ContractTableCell>
          {salaries.map((contractSalary) => {
            const displayValue = contractSalary[field]
              ? type === "currency"
                ? formatMoney(Number(contractSalary[field]))
                : `${contractSalary[field]}%`
              : type === "currency"
                ? "$0"
                : "0%";

            return (
              <ContractTableCell key={contractSalary.id}>
                {displayValue}
              </ContractTableCell>
            );
          })}
          <ContractTableCell>{totalDisplayValue}</ContractTableCell>
        </TableRow>
      );
    }
  }

  function addBlankRow(rows: React.ReactNode[]) {
    // add a null to the row array for blank rows
    // don't add two nulls in a row
    if (rows.length === 0) {
      rows.push(null);
    } else if (rows[rows.length - 1] !== null) {
      rows.push(null);
    }
  }

  const contractRows: React.ReactNode[] = [];

  addContractRow(contractRows, "capRaisePercent", "% Cap/Raise", "percent");
  addContractRow(contractRows, "currentBaseComp", "Current Base", "currency");
  addContractRow(contractRows, "deferredBaseComp", "Deferred Base", "currency");
  addContractRow(contractRows, "totalBaseComp", "Total Base", "currency");
  addBlankRow(contractRows);
  addContractRow(
    contractRows,
    "intlPlayerPayment",
    "Intl. Player Payment",
    "currency"
  );
  addContractRow(contractRows, "twoWaySalary", "Two-way Salary", "currency");
  addBlankRow(contractRows);
  addContractRow(contractRows, "likelyBonus", "Likely Bonus", "currency");
  addContractRow(contractRows, "unlikelyBonus", "Unlikely Bonus", "currency");
  addContractRow(contractRows, "signingBonus", "Signing Bonus", "currency");
  addContractRow(contractRows, "tradeBonus", "Earned Trade Bonus", "currency");
  addContractRow(contractRows, "offseasonBonus", "Offseason Bonus", "currency");
  addBlankRow(contractRows);
  addContractRow(
    contractRows,
    "totalSalary",
    "Total Salary",
    "currency",
    "font-bold bg-purple-100"
  );
  addBlankRow(contractRows);
  addContractRow(
    contractRows,
    "capSalaryAdjustment",
    "Cap Salary +/-",
    "currency"
  );
  addContractRow(
    contractRows,
    "taxSalaryAdjustment",
    "Tax Salary +/-",
    "currency"
  );
  addContractRow(
    contractRows,
    "apronSalaryAdjustment",
    "Apron Salary +/-",
    "currency"
  );
  addContractRow(
    contractRows,
    "totalSalaryAdjustment",
    "Total Salary +/-",
    "currency"
  );
  addBlankRow(contractRows);
  addContractRow(contractRows, "capSalary", "Cap Salary", "currency");
  addContractRow(contractRows, "taxSalary", "Tax Salary", "currency");
  addContractRow(contractRows, "apronSalary", "Apron Salary", "currency");
  addContractRow(
    contractRows,
    "minTeamSalary",
    "Minimum Team Salary",
    "currency"
  );
  addBlankRow(contractRows);
  addContractRow(
    contractRows,
    "skillProtection",
    "Skill Protection",
    "currency"
  );

  return (
    <Flex direction="down" gap="sm">
      <ContractHeader
        contract={contract}
        open={open}
        onClick={() => setIsOpen(!open)}
      />

      {open && (
        <Flex direction="down" gap="lg" className="pb-4">
          <Flex direction="right" align="center" gap="lg">
            <Flex direction="down">
              <Text size="xs" muted heading className="whitespace-nowrap">
                {CURRENT_SEASON_LABEL} Cap Hit
              </Text>
              <Text size="sm" heading className="md:text-2xl">
                {formatMoney(currentSeasonCapHit)}
              </Text>
            </Flex>

            <Flex direction="down">
              <Text size="xs" muted heading>
                Free Agent
              </Text>
              <Text size="sm" heading className="md:text-2xl">
                {contract.endDate
                  ? dayjs(contract.endDate).format("YYYY")
                  : contract.startYear + contract.lengthOfYears}{" "}
                / {contract.freeAgentStatus}
              </Text>
            </Flex>

            <Flex direction="down">
              <Text size="xs" muted heading>
                Signed Using
              </Text>
              <Text size="sm" heading className="md:text-2xl">
                {ContractSignedMethodLabels[contract.signedMethod]}
              </Text>
            </Flex>
          </Flex>

          <Grid columns={["md:3"]} gap="sm">
            <PlayerDetails
              items={[
                {
                  label: "Date",
                  value: dayjs(contract.signingDate).format("MM/DD/YYYY"),
                },
                {
                  label: "Signing team",
                  value: contract.signAndTradeTeam ? (
                    <Flex direction="right" gap="sm" wrap>
                      <Link to={`/teams/${contract.signingTeam.id}`}>
                        <Button variant="link" className="h-auto p-0 font-bold">
                          {contract.signingTeam.name}
                        </Button>
                      </Link>
                      →
                      <Link to={`/teams/${contract.signAndTradeTeam.id}`}>
                        <Button variant="link" className="h-auto p-0 font-bold">
                          {contract.signAndTradeTeam.name}
                        </Button>
                      </Link>
                    </Flex>
                  ) : (
                    <Link to={`/teams/${contract.signingTeam.id}`}>
                      <Button variant="link" className="h-auto p-0 font-bold">
                        {contract.signingTeam.name}
                      </Button>
                    </Link>
                  ),
                },
                {
                  label: "Status",
                  value: ContractStatusLabels[contract.status],
                },
                {
                  label: "Contract type",
                  value: ContractTypeLabels[contract.type],
                },
                {
                  label: "Max Contract",
                  value: ContractMaximumTypeLabels[contract.maxContract],
                },
                {
                  label: "Min Contract",
                  value: ContractMinimumTypeLabels[contract.minContract],
                },
              ]}
            />

            <PlayerDetails
              items={[
                {
                  label: "Start year",
                  value: `${contract.startYear}`,
                },
                {
                  label: "Length",
                  value: `${contract.lengthOfYears} years`,
                },
                {
                  label: "Signed using",
                  value: ContractSignedMethodLabels[contract.signedMethod],
                },
                {
                  label: "Trade restriction",
                  value:
                    ContractTradeRestrictionLabels[contract.tradeRestriction],
                },
                {
                  label: "Player Constent",
                  value: ContractPlayerConsentLabels[contract.playerConsent],
                },
                {
                  label: "Agent",
                  value: contract.agent ? (
                    <Link to={`/players/agents/${contract.agent.id}`}>
                      <Button variant="link" className="h-auto p-0 font-bold">
                        {contract.agent.name}
                      </Button>
                    </Link>
                  ) : (
                    "--"
                  ),
                },
              ]}
            />

            <PlayerDetails
              items={[
                {
                  label: "Schedule",
                  value:
                    ContractPaymentScheduleLabels[contract.paymentSchedule],
                },
                {
                  label: "Intl. Player Payment",
                  value: formatMoney(contract.internationalPlayerPayment),
                },
                {
                  label: "Poison Pill",
                  value: formatMoney(contract.poisonPillAmount),
                },
              ]}
            />
          </Grid>

          <Flex direction="down" gap="sm">
            <Text heading size="md">
              Contract flags
            </Text>
            <Flex direction="right" gap="sm" wrap>
              {Object.keys(contract.contractFlags).map((flag) => {
                if (
                  contract.contractFlags[
                    flag as keyof typeof contract.contractFlags
                  ] === true
                ) {
                  return (
                    <Badge key={flag}>
                      {
                        ContractFlagLabels[
                          flag as keyof typeof ContractFlagLabels
                        ]
                      }
                    </Badge>
                  );
                } else {
                  return null;
                }
              })}
            </Flex>
          </Flex>

          {(contract.tradeBonusAmount || contract.tradeBonusPercent) && (
            <Flex direction="down" gap="sm">
              <Text heading size="md">
                Trade Bonus
              </Text>
              <Flex direction="right" gap="sm">
                {contract.tradeBonusPercent ? (
                  <>
                    <Text heading size="sm">
                      {contract.tradeBonusPercent}%
                    </Text>
                    {contract.tradeBonusAmount ? (
                      <>
                        <Text muted size="sm">
                          OR
                        </Text>
                        <Text heading size="sm">
                          ${contract.tradeBonusAmount}
                        </Text>
                      </>
                    ) : null}
                  </>
                ) : contract.tradeBonusAmount ? (
                  <Text heading size="sm">
                    ${contract.tradeBonusAmount}
                  </Text>
                ) : (
                  <Text>None</Text>
                )}
              </Flex>
            </Flex>
          )}

          <Table className="border-b">
            <TableHeader>
              <TableRow>
                <TableHead />
                {salaries.map((contractSalary) => (
                  <TableHead key={contractSalary.id}>
                    {contractSalary.year}
                  </TableHead>
                ))}
                <TableHead>Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {hasOptions && (
                <TableRow>
                  <ContractTableCell />

                  {salaries.map((contractSalary) => (
                    <ContractTableCell key={contractSalary.id}>
                      <Flex direction="down" gap="sm" align="start">
                        {contractSalary.option && (
                          <Badge variant="secondary">
                            {ContractSalaryOptionLabels[contractSalary.option]}
                          </Badge>
                        )}
                        {contractSalary.applicableMinSalary && (
                          <Badge>Min Salary</Badge>
                        )}
                        {contractSalary.capTaxVariance && (
                          <Badge>Cap/Tax Variance</Badge>
                        )}
                      </Flex>
                    </ContractTableCell>
                  ))}

                  {/* TOTAL */}
                  <ContractTableCell />
                </TableRow>
              )}

              {contractRows.map((row, i) => {
                if (row) {
                  return row;
                } else {
                  return (
                    <BlankTableRow columns={salaries.length + 2} key={i} />
                  );
                }
              })}
            </TableBody>
          </Table>

          <ContractInventiveBonuses contract={contract} />
        </Flex>
      )}
    </Flex>
  );
}

function ContractHeader({
  contract,
  open,
  onClick,
}: {
  contract: ContractRow;
  open: boolean;
  onClick: () => void;
}) {
  const totalSalary = contract.contractSalaries.reduce((acc, salary) => {
    return acc + salary.totalSalary;
  }, 0);

  return (
    <Flex
      direction="down"
      gap="md"
      className="relative cursor-pointer py-2 md:flex-row"
      onClick={onClick}
    >
      <Flex gap="sm" align="center">
        {open ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}

        <Flex direction="right" gap="sm" align="center">
          {contract.signAndTradeTeamId ? (
            <TeamLogo
              nbaTeamId={contract.signAndTradeTeam?.nbaId}
              leagueId={contract.signAndTradeTeam?.domesticLeagueId}
              size="xs"
            />
          ) : (
            <TeamLogo
              nbaTeamId={contract.signingTeam?.nbaId}
              leagueId={contract.signingTeam?.domesticLeagueId}
              size="xs"
            />
          )}
          <Text heading size="2xl">
            {contract.startYear}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="right" gap="sm" align="center">
        <Flex gap="md" align="center">
          <Flex direction="right" gap="sm" align="center">
            <PenToolIcon size={20} color="gray" />
            <Text size="sm" muted className="max-sm:w-36 md:text-2xl">
              {contract.minContract !== "NO"
                ? ContractMinimumTypeLabels[contract.minContract]
                : contract.maxContract != "NO"
                  ? ContractMaximumTypeLabels[contract.maxContract]
                  : ContractTypeLabels[contract.type]}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="right" gap="sm" align="center">
          <CalendarDaysIcon size={20} color="gray" />
          <Text size="sm" muted className="md:text-2xl">
            {contract.lengthOfYears} yrs / {formatMoney(totalSalary)}
          </Text>
        </Flex>
      </Flex>

      <Link
        className="absolute right-0"
        to={`https://pcms.nba.com/#/pcms/player/${contract.player.nbaId}/contractdetails/summary?cid=${contract.pcmsId}`}
      >
        <Button variant="link" onClick={(e) => e.stopPropagation()}>
          PCMS <SquareArrowOutUpRightIcon size={16} className="ml-2" />
        </Button>
      </Link>
    </Flex>
  );
}

function ContractInventiveBonuses({ contract }: { contract: ContractRow }) {
  const years = dedupe(
    contract.contractSalaries.map((salary) => salary.year)
  ).sort();

  const year = years.includes(CURRENT_SEASON) ? CURRENT_SEASON : years[0];

  const [selectedYear, setSelectedYear] = useState(`${year}`);

  const bonuses = contract.contractBonuses
    .filter((bonus) => bonus.year === Number(selectedYear))
    .sort(
      (bonus1, bonus2) =>
        Object.keys(ContractBonusTypeLabels).indexOf(bonus1.bonusType) -
        Object.keys(ContractBonusTypeLabels).indexOf(bonus2.bonusType)
    );

  return (
    <Flex direction="down" gap="sm">
      <Flex direction="right" gap="sm" align="center">
        <Text heading size="md">
          Incentive Bonuses
        </Text>
        <Select value={`${selectedYear}`} onValueChange={setSelectedYear}>
          {years.map((year) => (
            <SelectOption key={year} value={`${year}`}>
              {year}
            </SelectOption>
          ))}
        </Select>
      </Flex>

      <Table className="border-b">
        <TableHeader>
          <TableRow>
            <TableHead>Amount</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Criteria</TableHead>
            <TableHead>Earned</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {bonuses.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                No bonuses found.
              </TableCell>
            </TableRow>
          )}
          {bonuses.map((bonus) => (
            <TableRow key={bonus.id}>
              <ContractTableCell>{formatMoney(bonus.amount)}</ContractTableCell>
              <ContractTableCell>
                {ContractBonusTypeLabels[bonus.bonusType]}
              </ContractTableCell>
              <ContractTableCell>{bonus.criteria}</ContractTableCell>
              <ContractTableCell>
                {ContractBonusEarnedLabels[bonus.earned]}
              </ContractTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Flex>
  );
}

function BlankTableRow({ columns }: { columns: number }) {
  return (
    <TableRow className="border-x">
      {[...Array(columns).keys()].map((_, i) => (
        <ContractTableCell key={i}>&nbsp;</ContractTableCell>
      ))}
    </TableRow>
  );
}

function ContractSkeleton() {
  return (
    <Flex direction="right" gap="md" className="py-4">
      <Skeleton className="h-8 w-24" />
      <Skeleton className="h-8 w-44" />
      <Skeleton className="h-8 w-72" />
    </Flex>
  );
}
