import { TeamLogo } from "@/components";
import { TeamRow } from "@suns/api/generated-client/apollo";
import { Card, Flex, Grid, Text } from "@suns/design-system";

interface TeamHeroProps {
  team: TeamRow;
}

export function TeamHero({ team }: TeamHeroProps) {
  return (
    <Card>
      {/* Desktop only */}
      <Flex gap="md" align="center" className=" max-sm:hidden">
        <TeamLogo
          nbaTeamId={team.nbaId}
          leagueId={team.domesticLeagueId}
          src={team.image}
          className="h-40 w-40"
        />
        <Flex direction="down" gap="md">
          <Text size="3xl" heading className="whitespace-nowrap">
            {team.fullName}
          </Text>
          <Flex gap="xl">
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="sm" heading>
                Record
              </Text>
              <Text size="2xl" heading>
                0-0
              </Text>
            </Flex>
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="sm" heading>
                Net Rating
              </Text>
              <Flex direction="right" gap="xs" align="end">
                <Text size="2xl" heading>
                  0.0
                </Text>
                <Text variant="muted" size="lg" heading>
                  32nd
                </Text>
              </Flex>
            </Flex>
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="sm" heading>
                2025 Cap Total
              </Text>
              <Flex direction="right" gap="xs" align="end">
                <Text size="2xl" heading>
                  $0
                </Text>
                <Text variant="muted" size="lg" heading>
                  32nd
                </Text>
              </Flex>
            </Flex>
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="sm" heading>
                Cap Status
              </Text>
              <Text size="2xl" heading>
                None
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* Mobile only */}
      <Flex gap="sm" direction="down" className="sm:hidden">
        <Flex gap="sm" align="center">
          <TeamLogo
            nbaTeamId={team.nbaId}
            leagueId={team.domesticLeagueId}
            src={team.image}
            className="h-10 w-10"
          />
          <Text size="xl" heading className="whitespace-nowrap">
            {team.fullName}
          </Text>
        </Flex>
        <Flex direction="down" gap="sm">
          <Grid columns="2" gap="lg">
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="xs" heading>
                Record
              </Text>
              <Text size="md" heading>
                0-0
              </Text>
            </Flex>
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="xs" heading>
                Net Rating
              </Text>
              <Flex direction="right" gap="xs" align="center">
                <Text size="md" heading>
                  0.0
                </Text>
                <Text variant="muted" size="sm" heading>
                  32nd
                </Text>
              </Flex>
            </Flex>
          </Grid>
          <Grid columns="2" gap="lg">
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="xs" heading>
                2025 Cap Total
              </Text>
              <Flex direction="right" gap="xs" align="center">
                <Text size="md" heading>
                  $0
                </Text>
                <Text variant="muted" size="sm" heading>
                  32nd
                </Text>
              </Flex>
            </Flex>
            <Flex direction="down" gap="xs">
              <Text variant="muted" size="xs" heading>
                Cap Status
              </Text>
              <Text size="md" heading>
                None
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </Card>
  );
}
