import { LeagueId } from "@suns/api";
import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";

const sizes = {
  xxxs: "size-5",
  xxs: "size-6",
  xs: "size-10",
  sm: "size-12",
  md: "size-16",
  lg: "size-24",
};

const teamLogoVariants = classVariants([], {
  variants: {
    size: {
      ...sizes,
      ...responsiveVariants("sm", sizes),
      ...responsiveVariants("md", sizes),
      ...responsiveVariants("lg", sizes),
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type TeamLogoProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof teamLogoVariants> & {
    src?: string | null;
    nbaTeamId?: number | null;
    leagueId?: LeagueId | null;
  };
export function TeamLogo({
  src,
  leagueId,
  nbaTeamId,
  size,
  className,
  ...props
}: TeamLogoProps) {
  let nbaURL;

  if (leagueId == LeagueId.WNBA) {
    nbaURL = `https://cdn.wnba.com/logos/wnba/${nbaTeamId}/primary/L/logo.svg`;
  } else if (leagueId == LeagueId.GLEAGUE) {
    nbaURL = `https://ak-static.cms.nba.com/wp-content/uploads/logos/nbagleague/${nbaTeamId}/primary/L/logo.svg`;
  } else if (leagueId == LeagueId.NBA) {
    nbaURL = `https://cdn.nba.com/logos/nba/${nbaTeamId}/primary/L/logo.svg`;
  }
  const url = leagueId && nbaTeamId ? nbaURL : src;

  return url ? (
    <div className={cn(teamLogoVariants({ size }), className)} {...props}>
      <img src={url} className="h-full w-full" />
    </div>
  ) : null;
}
