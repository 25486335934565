/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScheduleFullGameDate } from './ScheduleFullGameDate';
import type { ScheduleFullWeek } from './ScheduleFullWeek';
export type LeagueSchedule = {
    /**
     * Season YYYY-YY
     */
    seasonYear?: string | null;
    /**
     * NBA: 00, WNBA: 10, G League: 20, NBA2K: 12
     */
    leagueId?: LeagueSchedule.leagueId | null;
    gameDates?: Array<ScheduleFullGameDate> | null;
    /**
     * Regular season week number and description
     */
    weeks?: Array<ScheduleFullWeek> | null;
};
export namespace LeagueSchedule {
    /**
     * NBA: 00, WNBA: 10, G League: 20, NBA2K: 12
     */
    export enum leagueId {
        _00 = '00',
        _10 = '10',
    }
}

