import { useAsync, useNavigateLogin } from "@/shared/hooks";
import { LeagueId, SunsApiError } from "@suns/api";
import {
  ApolloBadge,
  Card,
  Flex,
  SkeletonText,
  SkeletonHeading,
  Text,
  Container,
  Separator,
  Grid,
  Popover,
  PopoverContent,
  Command,
  CommandItem,
  CommandList,
  Input,
} from "@suns/design-system";
import { PopoverTrigger } from "@suns/design-system/src/components/Popover/Popover";
import {
  CommandEmpty,
  CommandGroup,
} from "@suns/design-system/src/components/Command/Command";
import { Loader2Icon, PencilIcon, SearchIcon } from "@suns/design-system/icons";
import {
  Page,
  PlayerHeaderSkeleton,
  PlayerHeadshot,
  TeamLogo,
} from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  formatAge,
  formatInchesToFeet,
  formatMoney,
  formatWeight,
} from "@/shared/utils/helper-functions";
import { PlayerInfoSection } from "./components/PlayersInfoSection";
import { ApolloGradeValueLabels } from "../reports/reports-const";
import {
  PlayerMetadataPositionLabels,
  PlayerMetadataRoleLabels,
} from "./players-const";
import { useEffect, useMemo, useState } from "react";
import { agentsLoader } from "../agents/loaders/agency-loader";
import { apolloApi } from "@/shared/api";
import { toast, ToastType } from "@/shared/utils/toaster";
import { PlayerResponse } from "@suns/api/generated-client/apollo";
import { PlayerDetails } from "./components/PlayerDetails";
import { usePrompt } from "@/components/Prompt/Prompt";
import { viewPlayerLoader } from "./loaders/player-loader";
import countries from "./country-code.json";
import { CURRENT_SEASON } from "@/shared/const";
import { colors } from "@/shared/utils/colors";
export interface PlayerAgentInfo {
  agentId: number;
}

export function PlayersView() {
  const { playerId } = useParams();
  const navigate = useNavigate();
  const navigateLogin = useNavigateLogin();
  const prompt = usePrompt();

  const { response, loading, error } = useAsync(viewPlayerLoader, {
    playerId: Number(playerId),
  });

  const { response: agents, error: agentsError } = useAsync(agentsLoader, {
    limit: 1355,
    sortColumn: "name",
    sortDir: "ASC",
  });

  const [playerResponse, setPlayerResponse] = useState<PlayerResponse | null>(
    null
  );

  const [saving, setSaving] = useState(false);

  const handleClickUpsertAgent = async (agentId: number) => {
    const agent = agents?.agents?.find((a) => a.id === agentId);
    const confirmation = await prompt(
      "Are you sure you want to update the agent?",
      `This will update the agent for this player from ${playerResponse?.player.agent?.name ?? response?.player.agent?.name} to ${agent?.name}.`
    );

    if (confirmation) {
      try {
        setSaving(true);
        const updatedAgent = await apolloApi.upsertPlayersAgent({
          id: Number(playerId),
          agentId: agentId,
        });

        setPlayerResponse(updatedAgent);

        toast(ToastType.SUCCESS, "Agent Updated");
      } catch (error) {
        if (error instanceof SunsApiError && error.authError) {
          toast(ToastType.ERROR, "Please log back in to update.");
          navigateLogin();
        }
        toast(ToastType.ERROR, "Unable to update agent. Please try again.");
      } finally {
        setSaving(false);
      }
    }
  };

  const memoizedAgents = useMemo(() => agents?.agents, [agents]);

  if (agentsError) {
    throw new SunsApiError("Error loading agents", { cause: agentsError });
  }

  return (
    <Page
      title={
        response
          ? `${response?.player.firstName} ${response?.player.lastName}`
          : "View Player"
      }
      loading={loading}
      loadingState={<PlayerViewLoading />}
      className="mt-3 lg:mt-1"
      render={() => {
        if (error) {
          throw new SunsApiError("Error loading report", { cause: error });
        }

        const { player } = response!;

        const playerMetadata = {
          Grade: player.metadata[0]?.hybridGradeCurrent
            ? ApolloGradeValueLabels[
                player.metadata[0]?.hybridGradeCurrent
                  .value as keyof typeof ApolloGradeValueLabels
              ]
            : "--",
          "Remaining Capacity": player.metadata[0]?.hybridGradeRemainingCapacity
            ? ApolloGradeValueLabels[
                player.metadata[0]?.hybridGradeRemainingCapacity
                  .value as keyof typeof ApolloGradeValueLabels
              ]
            : "--",
          Position:
            PlayerMetadataPositionLabels[
              player.metadata[0]
                ?.hybridPosition as keyof typeof PlayerMetadataPositionLabels
            ] ?? "--",
          Role:
            PlayerMetadataRoleLabels[
              player.metadata[0]
                ?.hybridRole as keyof typeof PlayerMetadataRoleLabels
            ] ?? "--",
        };

        const playerCountry = player.homeCountryCode
          ? getCountryName(player.homeCountryCode ?? "")
          : player.homeCountryCode;

        const playerFreeAgentStatus = (() => {
          switch (true) {
            case !!player.metadata[0]?.playerOption &&
              !!player.metadata[0]?.restrictedFreeAgent:
              return `PO:${player.metadata[0]?.playerOption};RFA:${player.metadata[0]?.restrictedFreeAgent}`;
            case !!player.metadata[0]?.playerOption &&
              !!player.metadata[0]?.unrestrictedFreeAgent:
              return `PO:${player.metadata[0]?.playerOption};UFA:${player.metadata[0]?.unrestrictedFreeAgent}`;
            case !!player.metadata[0]?.playerOption:
              return `PO:${player.metadata[0]?.playerOption}`;
            case !!player.metadata[0]?.teamOption &&
              !!player.metadata[0]?.restrictedFreeAgent:
              return `TO:${player.metadata[0]?.teamOption};RFA:${player.metadata[0]?.restrictedFreeAgent}`;
            case !!player.metadata[0]?.teamOption &&
              !!player.metadata[0]?.unrestrictedFreeAgent:
              return `TO:${player.metadata[0]?.teamOption};UFA:${player.metadata[0]?.unrestrictedFreeAgent}`;
            case !!player.metadata[0]?.teamOption:
              return `TO:${player.metadata[0]?.teamOption}`;
            case !!player.metadata[0]?.unrestrictedFreeAgent:
              return `UFA:${player.metadata[0]?.unrestrictedFreeAgent}`;
            case !!player.metadata[0]?.restrictedFreeAgent:
              return `RFA:${player.metadata[0]?.restrictedFreeAgent}`;
            default:
              return "--";
          }
        })();

        return (
          <Container size="xl">
            <Flex direction="down" gap="md">
              <Card className="overflow-hidden px-0 pb-4 pt-2">
                <Flex gap="md">
                  {/* Desktop only */}
                  <Flex
                    className="relative w-44 overflow-hidden max-sm:hidden"
                    align="end"
                  >
                    {player.currentTeams?.[0]?.domesticLeagueId !==
                    LeagueId.NCAA ? (
                      <div className="z-2 absolute -bottom-20 -left-20 size-56 opacity-15">
                        <TeamLogo
                          nbaTeamId={player.currentTeams?.[0]?.nbaId}
                          leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                          src={player.currentTeams?.[0]?.image}
                          className="h-full w-full"
                        />
                      </div>
                    ) : null}
                    <PlayerHeadshot
                      src={player.image}
                      nbaPlayerId={player.nbaId}
                      leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                      size="xl"
                      variant="profile"
                      className="ml-4"
                    />
                  </Flex>
                  <Flex gap="sm" direction="down" className="mt-2 flex-1">
                    <Flex
                      align="center"
                      gap="lg"
                      className="relative overflow-hidden"
                    >
                      <Flex className="h-full sm:hidden">
                        {player.currentTeams?.[0]?.domesticLeagueId !==
                        LeagueId.NCAA ? (
                          <div className="z-1 absolute -bottom-14 -left-6 size-48 opacity-15 sm:hidden">
                            <TeamLogo
                              nbaTeamId={player.currentTeams?.[0]?.nbaId}
                              leagueId={
                                player.currentTeams?.[0]?.domesticLeagueId
                              }
                              src={player.currentTeams?.[0]?.image}
                              className="h-full w-full"
                            />
                          </div>
                        ) : null}
                        <PlayerHeadshot
                          src={player.image}
                          nbaPlayerId={player.nbaId}
                          leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                          size="xl"
                          variant="profile"
                          className="self-end"
                        />
                      </Flex>
                      <Flex gap="xs" direction="down" className="flex-1">
                        <Flex
                          direction="down"
                          justify="between"
                          align="start"
                          className="md:flex-row"
                        >
                          <Flex direction="down" gap="xs">
                            <Text size="2xl" heading>
                              {player.firstName} {player.lastName}
                            </Text>
                            <Flex direction="right" gap="xs" align="center">
                              {player.nbaId ||
                              player.currentTeams?.[0]?.image ? (
                                <TeamLogo
                                  src={player.currentTeams?.[0]?.image}
                                  nbaTeamId={player.currentTeams?.[0]?.nbaId}
                                  leagueId={
                                    player.currentTeams?.[0]?.domesticLeagueId
                                  }
                                  size="xxs"
                                />
                              ) : (
                                <div className="h-3" />
                              )}
                              <Text size="xs">
                                {player.currentTeams?.[0]?.fullName ??
                                  "Free Agent"}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            direction="right"
                            gap="lg"
                            align="center"
                            className="mt-1 md:mr-8"
                          >
                            <RankBadge
                              label="Rank"
                              value={player.metadata[0]?.rank ?? undefined}
                              total={player.metadata[0]?.rankTotal ?? undefined}
                            />
                            <RankBadge
                              label={
                                player.metadata[0]?.hybridPosition
                                  ? `${player.metadata[0]?.hybridPosition.replace("_", " ").toLowerCase()}s Rank`
                                  : "Rank"
                              }
                              value={
                                player.metadata[0]?.positionRank ?? undefined
                              }
                              total={
                                player.metadata[0]?.positionRankTotal ??
                                undefined
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex gap="lg" className="mb-2 max-sm:hidden">
                      {Object.entries(playerMetadata).map(([key, value]) => (
                        <div key={key}>
                          <Text size="xs" muted heading>
                            {key}
                          </Text>
                          <Text size="xl" heading>
                            {value}
                          </Text>
                        </div>
                      ))}
                    </Flex>
                    {/* Mobile only */}
                    <Flex
                      direction="right"
                      gap="md"
                      className="my-2 w-full pl-5 sm:hidden"
                    >
                      <Flex direction="down" className="w-full">
                        <Grid columns="2" gap="md">
                          {Object.entries(playerMetadata).map(
                            ([key, value]) => (
                              <div key={key}>
                                <Text size="xs" muted heading>
                                  {key}
                                </Text>
                                <Text size="md" heading>
                                  {value}
                                </Text>
                              </div>
                            )
                          )}
                        </Grid>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Separator className="mb-4 h-[2px] bg-gray-200" />
                <Grid gap={"md"} columns={["md:3"]} className="px-4">
                  <PlayerDetails
                    title="Player Info"
                    items={[
                      {
                        label: "Birthdate",
                        value: player.birthDate
                          ? `${dayjs(player.birthDate).format("MM/DD/YYYY")} (${formatAge(player.birthDate)})`
                          : "--",
                      },
                      {
                        label: "Birthplace",
                        value: `${player.homeTown ? player.homeTown : "--"}${
                          player.homeState ? ", " + player.homeState : ""
                        }`,
                      },
                      {
                        label: "Pre-Draft Team",
                        value: player.collegeTeam?.fullName ?? "--",
                      },
                      {
                        label: "High School",
                        value: player.highschool || "--",
                      },
                      {
                        label: "Nationality",
                        value: playerCountry ?? "--",
                      },
                      {
                        label: "Years of Service",
                        value:
                          player.yearsOfService !== null
                            ? player.yearsOfService === 0
                              ? "Rookie"
                              : `${player.yearsOfService} years`
                            : "--",
                      },
                    ]}
                  />
                  <PlayerDetails
                    title="Status"
                    items={[
                      {
                        label: "Level",
                        value: player.level ?? "--",
                      },
                      {
                        label: "Contract Status",
                        value: player.metadata[0]?.capHit
                          ? "Under Contract"
                          : "Free Agent",
                      },
                      {
                        label: `${CURRENT_SEASON} Cap Hit`,
                        value: formatMoney(player.metadata[0]?.capHit),
                      },
                      {
                        label: "Free Agency",
                        value: playerFreeAgentStatus,
                      },
                      {
                        label: "Agent",
                        value: (
                          <AgentSelector
                            value={
                              playerResponse?.player.agent?.name ??
                              player.agent?.name ??
                              "--"
                            }
                            options={memoizedAgents}
                            onClick={() =>
                              navigate(
                                `/players/agents/${playerResponse?.player.agent?.id ?? player.agent?.id}`
                              )
                            }
                            onUpdate={handleClickUpsertAgent}
                            saving={saving}
                          />
                        ),
                      },
                    ]}
                  />

                  <PlayerDetails
                    title="Measurements"
                    items={[
                      {
                        label: "Height",
                        value: player.height
                          ? formatInchesToFeet(player.height)
                          : "--",
                      },
                      {
                        label: "Weight",
                        value: player.weight
                          ? formatWeight(player.weight)
                          : "--",
                      },
                      {
                        label: "Wing",
                        value: player.wing
                          ? formatInchesToFeet(player.wing)
                          : "--",
                      },
                      {
                        label: "Reach",
                        value: player.reach
                          ? formatInchesToFeet(player.reach)
                          : "--",
                      },
                    ]}
                  />
                </Grid>
              </Card>
              <Card>
                <PlayerInfoSection player={player} />
              </Card>
            </Flex>
          </Container>
        );
      }}
    />
  );
}

interface AgentSelectorProps {
  value?: string;
  options?: { id: number; name: string }[];
  onClick?: () => void;
  onUpdate?: (value: number) => void;
  saving?: boolean;
}

function AgentSelector({
  value,
  options,
  onUpdate,
  onClick,
  saving,
}: AgentSelectorProps) {
  const [editValue, setEditValue] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = useMemo(() => {
    return options?.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  useEffect(() => {
    if (!editValue) {
      setSearchValue("");
    }
  }, [editValue]);

  return (
    <Flex gap="sm" align="center">
      <Text
        size="sm"
        heading
        className={value !== "--" ? "cursor-pointer text-primary" : ""}
        onClick={onClick}
      >
        {value}
      </Text>
      <Popover open={editValue} onOpenChange={setEditValue}>
        <PopoverTrigger asChild>
          <span className="cursor-pointer" onClick={() => setEditValue(true)}>
            {saving ? (
              <Loader2Icon className="size-4 animate-spin text-primary" />
            ) : (
              <PencilIcon className="size-4 pb-0.5 text-gray-500" />
            )}
          </span>
        </PopoverTrigger>
        <PopoverContent className="relative -left-16 w-[250px] p-0">
          <Command>
            <Flex className="border-b px-3" align="center" gap="sm">
              <SearchIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <Input
                className="h-11 w-full border-none p-0 text-[16px] font-normal
                  placeholder:text-muted-foreground"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Flex>
            <CommandGroup>
              <CommandList>
                <CommandEmpty>No agents found</CommandEmpty>
                {filteredOptions?.map((option) => (
                  <CommandItem
                    className="font-normal"
                    key={option.id}
                    onSelect={() => {
                      onUpdate?.(option.id);
                      setEditValue(false);
                    }}
                  >
                    {option.name}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

function RankBadge({
  value,
  total,
  label,
}: {
  value?: number;
  total?: number;
  label: string;
}) {
  return (
    <Flex direction="down" gap="sm">
      <Flex direction="right" gap="xs" align="center">
        <ApolloBadge size={20} color={colors.purple} />
        <Text size="sm" heading muted className="capitalize max-sm:hidden">
          {label}
        </Text>
        <Text size="sm" heading muted className="capitalize md:hidden">
          {label === "point guards Rank" ? "PG Rank" : label}
        </Text>
      </Flex>
      <Flex direction="right" gap="none" align="start">
        <Text size="2xl" heading className="-mt-1">
          {value ?? "--"}
        </Text>
        <Text size="sm" heading muted>
          /{total ?? "--"}
        </Text>
      </Flex>
    </Flex>
  );
}

function PlayerViewLoading() {
  return (
    <Container size="xl">
      <Flex direction="down" gap="md">
        <Card>
          <PlayerHeaderSkeleton />
        </Card>
        <Card>
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="md">
              <SkeletonHeading size="3xl" className="w-36" />
              <SkeletonHeading size="3xl" className="w-28" />
            </Flex>
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
}

function getCountryName(code: string) {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const countryCodesMap = Object.fromEntries(
    countries.map((c) => [c["alpha-3"], c["alpha-2"]])
  );
  const twoLetterCode = countryCodesMap[code] || code;
  try {
    return regionNames.of(twoLetterCode);
  } catch {
    return null;
  }
}
