import {
  Button,
  Flex,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Grid,
  Input,
} from "@suns/design-system";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { LoaderCircle } from "lucide-react";
import { toast } from "@/shared/utils/toaster";
import { ToastType } from "@/shared/utils/toaster";
import { AgentResponse, AgentRow } from "@suns/api/generated-client/apollo";
import { AgentInfo, formatPhoneNumber } from "../agent-view";

interface AgentFormProps {
  agent: AgentRow | AgentResponse;
  saving: boolean;
  onUpsert: (values: AgentInfo) => void;
}

export default function AgentInfoForm({
  agent,
  onUpsert,
  saving,
}: AgentFormProps) {
  const formSchema = yup.object({
    email: yup.string().email().nullable(),
    mobilePhone: yup
      .string()
      .test({
        test: (value) => !value || value.length === 11,
      })
      .nullable(),
    workPhone: yup
      .string()
      .test({
        test: (value) => !value || value.length === 11,
      })
      .nullable(),
  });

  type FormSchema = yup.InferType<typeof formSchema>;

  const form = useForm<FormSchema>({
    defaultValues: agent as AgentRow,
    mode: "onChange",
  });

  const handleSave = async () => {
    const { email, mobilePhone, workPhone } = form.getValues();
    try {
      await formSchema.validate(
        {
          email,
          mobilePhone: unformatPhoneNumber(mobilePhone ?? undefined),
          workPhone: unformatPhoneNumber(workPhone ?? undefined),
        },
        {
          abortEarly: false,
        }
      );
      onUpsert({
        ...agent,
        email: email ?? undefined,
        mobilePhone: mobilePhone ? unformatPhoneNumber(mobilePhone) : undefined,
        workPhone: workPhone ? unformatPhoneNumber(workPhone) : undefined,
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            form.setError(e.path as keyof FormSchema, {
              type: "custom",
              message: e.message,
            });
          }
        });
        toast(ToastType.ERROR, "Unable to update agent. Please try again.");
      }
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col items-end gap-4">
        <Grid columns="3" gap="sm" className="max-md:grid-cols-1">
          <FormField
            name="mobilePhone"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-xs">Mobile Phone</FormLabel>
                <FormControl>
                  <Input
                    className="text-[16px]"
                    {...field}
                    value={formatPhoneNumber(field.value)}
                    onChange={(e) => {
                      const phoneNumber = unformatPhoneNumber(e.target.value);
                      if (phoneNumber) {
                        field.onChange(phoneNumber);
                      } else {
                        field.onChange("");
                      }
                    }}
                    type="tel"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            name="workPhone"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-xs">Work Phone</FormLabel>

                <FormControl>
                  <Input
                    className="text-[16px]"
                    {...field}
                    value={formatPhoneNumber(field.value)}
                    onChange={(e) => {
                      const phoneNumber = unformatPhoneNumber(e.target.value);
                      if (phoneNumber) {
                        field.onChange(phoneNumber);
                      } else {
                        field.onChange("");
                      }
                    }}
                    type="tel"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-xs">Email</FormLabel>

                <FormControl>
                  <Input
                    className="text-[16px]"
                    {...field}
                    value={field.value}
                    type="email"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Grid>
        <div>
          <Flex gap="sm">
            <Button type="button" onClick={handleSave}>
              {saving ? (
                <Flex align="center" gap="sm">
                  Updating...
                  <LoaderCircle size={18} className="animate-spin" />
                </Flex>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="muted"
              type="button"
              onClick={() => form.reset(agent as AgentRow)}
            >
              Cancel
            </Button>
          </Flex>
        </div>
      </form>
    </Form>
  );
}

function unformatPhoneNumber(phoneNumber: string | undefined) {
  if (!phoneNumber) return undefined;

  let cleaned = phoneNumber.replace(/[()\s-]/g, "");

  if (!cleaned.startsWith("1") && cleaned.length === 10) {
    cleaned = "1" + cleaned;
  }

  if (cleaned.startsWith("1") && cleaned.length < 11 && cleaned.length > 1) {
    cleaned = "1" + cleaned.slice(1);
  }

  return cleaned;
}
