import React from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Flex,
  Skeleton,
} from "@suns/design-system";
import { DataTableColumnHeader } from "./DataTableHeader";
import { DataTablePagination } from "./DataTablePagination";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sorting?: SortingState;
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  pagination?: PaginationState;
  setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  count?: number;
  onRowClicked: (row: Row<TData>) => void;
  columnVisibility?: VisibilityState;
  setColumnVisibility?: React.Dispatch<React.SetStateAction<VisibilityState>>;
  loading?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  sorting,
  setSorting,
  pagination,
  setPagination,
  count,
  onRowClicked,
  columnVisibility,
  setColumnVisibility,
  loading = false,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    rowCount: count,
    state: {
      sorting,
      pagination,
      columnVisibility,
    },
  });

  return (
    <Flex direction="down" gap="md">
      <Flex className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      <DataTableColumnHeader header={header} />
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              table.getHeaderGroups().map((headerGroup) =>
                [1, 2, 3, 4, 5].map((i) => (
                  <TableRow key={i}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell key={header.id}>
                          <Skeleton className="h-6 w-full" />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              )
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onRowClicked(row)}
                  className="cursor-pointer hover:bg-gray-200"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-nowrap">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Flex>
      {loading ? (
        <Skeleton className="h-8 w-64 self-end pt-4" />
      ) : (
        <DataTablePagination table={table} />
      )}
    </Flex>
  );
}
