import { useEffect } from "react";
import { useAccount, useNavigateLogin } from "@/shared/hooks";

interface ProtectedProps {
  children: React.ReactNode;
}
export function Protected({ children }: ProtectedProps) {
  const { isAuthenticated, hasDefaultAccess, userId, primaryRole } =
    useAccount();
  const navigateLogin = useNavigateLogin();

  useEffect(() => {
    if (!isAuthenticated || !hasDefaultAccess) {
      navigateLogin();
      return;
    }

    if (userId && typeof gtag == "function") {
      gtag("set", "user_id", userId);
      gtag("set", "user_properties", {
        apollo_id: userId,
        apollo_role: primaryRole,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated && hasDefaultAccess ? children : null;
}

export default Protected;
