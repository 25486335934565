export enum StatName {
  TwoP = "2P",
  TwoPPct = "2P%",
  TwoPA = "2PA",
  ThreeP = "3P",
  ThreePPct = "3P%",
  ThreePA = "3PA",
  TEAM = "TEAM",
  TORatio = "TO Ratio",
  AST = "AST",
  ASTPct = "Ast%",
  AstTo = "Ast/TO",
  AstRatio = "Ast Ratio",
  BLK = "BLK",
  BLKPct = "Blk%",
  BLKA = "BlkA",
  BLOB = "Blob",
  DRBPct = "DRB%",
  RebPct = "REB%",
  DRtg = "DRtg",
  EFGPct = "eFG%",
  FG = "FG",
  FGPct = "FG%",
  FGA = "FGA",
  FT = "FT",
  FTPct = "FT%",
  FTA = "FTA",
  L = "L",
  MP = "MP",
  ORtg = "ORtg",
  Opp = "Opp",
  ORB = "ORB",
  DRB = "DRB",
  ORBPct = "ORB%",
  Pace = "Pace",
  PerGame = "Per Game",
  PF = "PF",
  Poss = "Poss",
  PTS = "PTS",
  TsPct = "TS%",
  Share = "Share",
  SLOB = "SLOB",
  STL = "STL",
  STLPct = "STL%",
  TOV = "TOV",
  TOVPct = "TOV%",
  TRBPct = "TRB%",
  UsgPct = "Usg%",
  WinLoss = "W/L",
  W = "W",
  PlusMinus = "+/-",
  CrashPct = "Crash%",
  DRBPctDiffON = "DRB% Diff ON",
  DriveEfficiency = "DRIVE EFFICIENCY",
  FGM = "FGM",
  FTM = "FTM",
  GP = "GP",
  GS = "GS",
  IsoEfficiency = "ISO EFFICIENCY",
  Min = "Min",
  NRtg = "NRtg",
  OffballScreenEfficiency = "OFFBALL SCREEN EFFICIENCY",
  OppEfgPct = "Opp. eFG%",
  OppFTARate = "Opp. FTA Rate",
  OppPlayerAdjustedShotQuality = "Opp. PLAYER ADJUSTED SHOT QUALITY",
  OppRimFGPct = "Opp. Rim FG%",
  OppRimRate = "Opp. Rim Rate",
  OppShotQuality = "Opp. SHOT QUALITY",
  ORBPctDiffON = "ORB% Diff ON",
  PACE = "PACE",
  PNREfficiency = "P&R EFFICIENCY",
  PITPAllowed = "PITP ALLOWED",
  PostUpEfficiency = "POST UP EFFICIENCY",
  PPP = "PPP",
  PFD = "PFD",
  PtsOffTov = "PTS OFF TO",
  Pts2ndChance = "2ND PTS",
  PtsFb = "PTS FBPS",
  REB = "REB",
  TransitionPTS = "TRANSITION PTS",
  TransitionPTSAllowed = "TRANSITION PTS ALLOWED",
  TurnoversForceRate = "TURNOVERS FORCED RATE",
  XPPP = "XPPP",
  FTARate = "FTA Rate",
  ThreePM = "3PM",
  PITP = "PITP",
  AvgNoPlayersCrashing = "AVG NO. PLAYERS CRASHING",
  PNRVolume = "P&R VOLUME",
  IsoVolume = "ISO VOLUME",
  DHOVolume = "DHO VOLUME",
  PostUpVolume = "POST UP VOLUME",
  OffballScreenVolume = "OFFBALL SCREEN VOLUME",
  DriveVolume = "DRIVE VOLUME",
  Deflections = "DEFLECTIONS",
  FoulsCommitted = "FOULS COMMITTED",
  ChargesDrawn = "CHARGES DRAWN",
  OppTransitionPts = "Opp. Transition Pts",
  OppTOVPct = "Opp. TOV%",
  OppPITP = "Opp. PITP",
  QSP = "qSP",
  QSM = "qSM",
  QSQ = "qSQ",
  OppPtsOffTov = "Opp. PTS OFF TO",
  OppPts2ndChance = "Opp. 2ND PTS",
  OppPtsFb = "Opp. PTS FBPS",
  OppPitpAllowed = "Opp. PITP ALLOWED",
  OppOrbPct = "Opp. ORB%",
  ThrPtsPct = "3PT Pts%",
  FBptsPct = "FBPS Pts%",
  FTptsPct = "FT Pts%",
  PtsOffTovPct = "Pts Off Tov%",
  PtsPaintPct = "Pts Paint%",
  Ast2PTPct = "Ast 2PT%",
  Ast2PTPctM = "Ast 2PT% Made",
  Ast3PTPct = "Ast 3PT%",
  Ast3PTPctM = "Ast 3PT% Made",
  AstFgmPct = "Ast FGM%",
  AstFgmPctM = "Ast FGM% Made",
  DefRimFgm = "Def. Rim FGM",
  DefRimFga = "Def. Rim FGA",
  DefRimRate = "Def. Rim Rate",
  DefRimFgPct = "Def. Rim FG%",
  PctFgm = "%FGM",
  PctFga = "%FGA",
  PctFg3m = "%3PM",
  PctFg3a = "%3PA",
  PctFtm = "%FTM",
  PctFta = "%FTA",
  PctPts2pt = "%Pts 2p",
  PctPts2ptMr = "%Pts 2p Mid",
  PctPts3pt = "%Pts 3p",
  PctPtsFb = "%Pts FBPS",
  PctPtsFt = "%Pts FT",
  PctPtsOffTov = "%Pts Off Tov",
  PctPtsPaint = "%Pts Paint",
  PctAst2pm = "%AST 2pm",
  PctAst3pm = "%AST 3pm",
  PctAstFgm = "%AST FGM",
  PctUast2pm = "%UAST 2pm",
  PctUast3pm = "%UAST 3pm",
  PctUastFgm = "%UAST FGM",
  PctOreb = "%OREB",
  PctDreb = "%DREB",
  PctReb = "%REB",
  PctAst = "%AST",
  PctTov = "%TOV",
  PctStl = "%STL",
  PctBlk = "%BLK",
  PctBlka = "%BLKA",
  PctPf = "%PF",
  PctPfd = "%PFD",
  PctPts = "%PTS",
  PctFga2pt = "%FGA 2pt",
  PctFga3pt = "%FGA 3pt",
  OppFGM = "Opp. FGM",
  OppFGA = "Opp. FGA",
  OppFGPct = "Opp. FG%",
  Opp3PM = "Opp. 3PM",
  Opp3PA = "Opp. 3PA",
  Opp3PPct = "Opp. 3P%",
  OppFTM = "Opp. FTM",
  OppFTA = "Opp. FTA",
  OppFTPct = "Opp. FT%",
  OppOREB = "Opp. ORB",
  OppDREB = "Opp. DRB",
  OppREB = "Opp. REB",
  OppAST = "Opp. AST",
  OppTOV = "Opp. TOV",
  OppSTL = "Opp. STL",
  OppBLK = "Opp. BLK",
  OppBLKA = "Opp. BLKA",
  OppPF = "Opp. PF",
  OppPfd = "Opp. Pfd",
  OppPts = "Opp. Pts",
  OppPlusMinus = "Opp. +/-",
  Pie = "Pie",
  Base = "Base",
  Advanced = "Advanced",
  BoxScore = "Box Score",
  Defense = "Defense",
  FourFactors = "Four Factors",
  Misc = "Misc",
  Opponent = "Opponent",
  Scoring = "Scoring",
  Usage = "Usage",
  Per100Possessions = "Per 100 Possessions",
  Per100Plays = "Per 100 Plays",
  PerPossession = "Per Possession",
  Per48 = "Per 48",
  Per40 = "Per 40",
  Per36 = "Per 36",
  PerMinute = "Per Minute",
  PerPlay = "Per Play",
  Totals = "Totals",
  DefAst = "DEF. AST",
  DefBlk = "DEF. BLK",
  DefFg3a = "DEF. FG3PA",
  DefFg3m = "DEF. FG3PM",
  DefFg3Pct = "DEF. FG3%",
  DefFga = "DEF. FGA",
  DefFgm = "DEF. FGM",
  DefFgPct = "DEF. FG%",
  DefFoul = "DEF. FOUL",
  DefFta = "DEF. FTA",
  DefFtm = "DEF. FTM",
  DefFtPct = "DEF. FT%",
  DefPts = "DEF. PTS",
  DefShootingFoul = "DEF. SHOOTING FOUL",
  DefStl = "DEF. STL",
  DefTov = "DEF. TOV",
  BlkFg3a = "BLK FG3A",
  PtsCreated = "PTS Created",
  Aim = "AIM",
  OAim = "oAIM",
  DAim = "dAIM",
  PPV = "PPV",
  OPPV = "oPPV",
  DPPV = "dPPV",
  PRV = "PRV",
}

export const glossaryList = [
  { id: 1, stat: StatName.TwoP, definition: "2-Point Field Goals" },
  {
    id: 2,
    stat: StatName.TwoPPct,
    definition: "2-Point Field Goal Percentage; the formula is 2P / 2PA.",
  },
  { id: 3, stat: StatName.TwoPA, definition: "2-Point Field Goal Attempts" },
  {
    id: 4,
    stat: StatName.ThreeP,
    definition: "3-Point Field Goals",
  },
  {
    id: 5,
    stat: StatName.ThreePPct,
    definition: "3-Point Field Goal Percentage; the formula is 3P / 3PA.",
  },
  {
    id: 6,
    stat: StatName.ThreePA,
    definition: "3-Point Field Goal Attempts",
  },
  { id: 7, stat: StatName.AST, definition: "Assists" },
  {
    id: 8,
    stat: StatName.ASTPct,
    definition:
      "Assist Percentage; the formula is 100 * AST / (((MP / (Tm MP / 5)) * Tm FG) - FG). Assist percentage is an estimate of the percentage of teammate field goals a player assisted while he was on the floor.",
  },
  { id: 9, stat: StatName.BLK, definition: "Blocks" },
  {
    id: 10,
    stat: StatName.BLKPct,
    definition:
      "Block Percentage; the formula is 100 * (BLK * (Tm MP / 5)) / (MP * (Opp FGA - Opp 3PA)). Block percentage estimates the percentage of the opponent's two-point field goal attempts blocked by the player while he was on the floor.",
  },
  {
    id: 11,
    stat: StatName.DRB,
    definition: "Defensive Rebounds",
  },
  {
    id: 12,
    stat: StatName.DRBPct,
    definition:
      "Defensive Rebound Percentage; the formula is 100 * (DRB * (Tm MP / 5)) / (MP * (Tm DRB + Opp ORB)). Defensive rebound percentage estimates the percentage of available defensive rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 13,
    stat: StatName.DRtg,
    definition:
      "Defensive Rating; for players and teams, it is points allowed per 100 possessions. Dean Oliver, author of Basketball on Paper, developed this rating. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  {
    id: 14,
    stat: StatName.EFGPct,
    definition:
      "Effective Field Goal Percentage; the formula is (FG + 0.5 * 3P) / FGA. This statistic adjusts for a 3-point field goal worth one more point than a 2-point field goal. For example, suppose Player A goes 4 for 10 with 2 threes, while Player B goes 5 for 10 with 0 threes. Each player would have 10 points from field goals and thus would have the same effective field goal percentage (50%).",
  },
  {
    id: 15,
    stat: StatName.FG,
    definition:
      "Field Goals (includes both 2-point field goals and 3-point field goals)",
  },
  {
    id: 16,
    stat: StatName.FGPct,
    definition: "Field Goal Percentage; the formula is FG / FGA.",
  },
  {
    id: 17,
    stat: StatName.FGA,
    definition:
      "Field Goal Attempts (includes both 2-point field goal attempts and 3-point field goal attempts)",
  },
  { id: 18, stat: StatName.FT, definition: "Free Throws" },
  {
    id: 19,
    stat: StatName.FTPct,
    definition: "Free Throw Percentage; the formula is FT / FTA.",
  },
  { id: 20, stat: StatName.FTA, definition: "Free Throw Attempts" },
  { id: 21, stat: StatName.L, definition: "Losses" },
  { id: 22, stat: StatName.MP, definition: "Minutes Played" },
  {
    id: 23,
    stat: StatName.ORtg,
    definition:
      "Offensive Rating; for players, it is points produced per 100 possessions, while for teams, it is points scored per 100 possessions. This rating was developed by Dean Oliver, author of Basketball on Paper. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  { id: 24, stat: StatName.Opp, definition: "Opponent" },
  { id: 25, stat: StatName.ORB, definition: "Offensive Rebounds" },
  {
    id: 26,
    stat: StatName.ORBPct,
    definition:
      "Offensive Rebound Percentage; the formula is 100 * (ORB * (Tm MP / 5)) / (MP * (Tm ORB + Opp DRB)). Offensive rebound percentage estimates the percentage of available offensive rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 27,
    stat: StatName.Pace,
    definition:
      "Pace Factor; the formula is 48 * ((Tm Poss + Opp Poss) / (2 * (Tm MP / 5))). The pace factor estimates a team's total number of possessions per 48 minutes. (Note: 40 minutes is used in the calculation for the WNBA.)",
  },
  {
    id: 28,
    stat: StatName.PerGame,
    definition: "A statistic (e.g., assists) divided by games.",
  },
  { id: 29, stat: StatName.PF, definition: "Personal Fouls" },
  {
    id: 30,
    stat: StatName.Poss,
    definition:
      "Possessions; the formula for teams is 0.5 * ((Tm FGA + 0.4 * Tm FTA - 1.07 * (Tm ORB / (Tm ORB + Opp DRB)) * (Tm FGA - Tm FG) + Tm TOV) + (Opp FGA + 0.4 * Opp FTA - 1.07 * (Opp ORB / (Opp ORB + Tm DRB)) * (Opp FGA - Opp FG) + Opp TOV)). This formula estimates possessions based on both the team's statistics and their opponent's statistics, then averages them to provide a more stable estimate. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  { id: 31, stat: StatName.PTS, definition: "Points" },
  { id: 32, stat: StatName.STL, definition: "Steals" },
  {
    id: 33,
    stat: StatName.STLPct,
    definition:
      "Steal Percentage; the formula is 100 * (STL * (Tm MP / 5)) / (MP * Opp Poss). Steal Percentage is an estimate of the percentage of opponent possessions that end with a steal by the player while he is on the floor.",
  },
  { id: 34, stat: StatName.TOV, definition: "Turnovers" },
  {
    id: 35,
    stat: StatName.TOVPct,
    definition:
      "Turnover Percentage; the formula is 100 * TOV / (FGA + 0.44 * FTA + TOV). The turnover percentage is an estimate of turnovers per 100 plays.",
  },
  {
    id: 36,
    stat: StatName.TRBPct,
    definition:
      "Total Rebound Percentage; the formula is 100 * (TRB * (Tm MP / 5)) / (MP * (Tm TRB + Opp TRB)). Total rebound percentage is an estimate of the percentage of available rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 37,
    stat: StatName.UsgPct,
    definition:
      "Usage Percentage; the formula is 100 * ((FGA + 0.44 * FTA + TOV) * (Tm MP / 5)) / (MP * (Tm FGA + 0.44 * Tm FTA + Tm TOV)). Usage percentage is an estimate of the percentage of team plays used by a player while he was on the floor.",
  },
  { id: 38, stat: StatName.W, definition: "Wins" },
  { id: 39, stat: StatName.PlusMinus, definition: "Plus/Minus" },
  {
    id: 40,
    stat: StatName.CrashPct,
    definition: "Percentage of Rebounds on Offensive Crashes",
  },
  {
    id: 41,
    stat: StatName.DRBPctDiffON,
    definition:
      "Difference in Team's Defensive Rebound Percentage when Player is On Court",
  },
  { id: 43, stat: StatName.DriveEfficiency, definition: "Drive Efficiency" },
  { id: 44, stat: StatName.FGM, definition: "Field Goals Made" },
  { id: 45, stat: StatName.GP, definition: "Games Played" },
  { id: 46, stat: StatName.IsoEfficiency, definition: "Isolation Efficiency" },
  { id: 47, stat: StatName.Min, definition: "Minutes Per Game" },
  { id: 48, stat: StatName.NRtg, definition: "Net Rating" },
  {
    id: 49,
    stat: StatName.OffballScreenEfficiency,
    definition: "Off-ball Screen Efficiency",
  },
  {
    id: 50,
    stat: StatName.OppEfgPct,
    definition: "Opponent's Effective Field Goal Percentage",
  },
  {
    id: 51,
    stat: StatName.OppFTARate,
    definition: "Opponent's Free Throw Attempt Rate",
  },
  {
    id: 52,
    stat: StatName.OppPlayerAdjustedShotQuality,
    definition: "Opponent's Player Adjusted Shot Quality",
  },
  {
    id: 53,
    stat: StatName.OppRimFGPct,
    definition: "Opponent's Field Goal Percentage at the Rim",
  },
  {
    id: 54,
    stat: StatName.OppRimRate,
    definition: "Opponent's Rate of Shots at the Rim",
  },
  {
    id: 55,
    stat: StatName.OppShotQuality,
    definition: "Opponent's Shot Quality",
  },
  {
    id: 56,
    stat: StatName.ORBPctDiffON,
    definition:
      "Difference in Team's Offensive Rebound Percentage when Player is On Court",
  },
  { id: 57, stat: StatName.PACE, definition: "Pace of the game" },
  {
    id: 58,
    stat: StatName.PNREfficiency,
    definition: "Pick and Roll Efficiency",
  },
  {
    id: 59,
    stat: StatName.PITPAllowed,
    definition: "Points in the Paint Allowed",
  },
  { id: 60, stat: StatName.PostUpEfficiency, definition: "Post Up Efficiency" },
  { id: 61, stat: StatName.PPP, definition: "Points Per Possession" },
  { id: 62, stat: StatName.REB, definition: "Total Rebounds" },
  {
    id: 63,
    stat: StatName.TransitionPTS,
    definition: "Points scored in transition",
  },
  {
    id: 64,
    stat: StatName.TransitionPTSAllowed,
    definition: "Transition Points Allowed",
  },
  {
    id: 65,
    stat: StatName.TurnoversForceRate,
    definition: "Rate at which turnovers are forced",
  },
  { id: 66, stat: StatName.XPPP, definition: "Expected Points Per Possession" },
  { id: 67, stat: StatName.FTARate, definition: "Free Throw Attempt Rate" },
  {
    id: 68,
    stat: StatName.ThreePM,
    definition: "Three Point Field Goals Made",
  },
  { id: 69, stat: StatName.PITP, definition: "Points in the Paint" },
  {
    id: 70,
    stat: StatName.AvgNoPlayersCrashing,
    definition: "Average number of players crashing",
  },
  { id: 71, stat: StatName.PNRVolume, definition: "Pick and Roll Volume" },
  { id: 72, stat: StatName.IsoVolume, definition: "Isolation Volume" },
  { id: 73, stat: StatName.DHOVolume, definition: "Dribble Handoff Volume" },
  { id: 74, stat: StatName.PostUpVolume, definition: "Post Up Volume" },
  {
    id: 75,
    stat: StatName.OffballScreenVolume,
    definition: "Off-ball Screen Volume",
  },
  { id: 76, stat: StatName.DriveVolume, definition: "Drive Volume" },
  { id: 77, stat: StatName.Deflections, definition: "Deflection" },
  {
    id: 78,
    stat: StatName.FoulsCommitted,
    definition: "Amount of fouls committed",
  },
  {
    id: 79,
    stat: StatName.ChargesDrawn,
    definition: "Amount of charges drawn",
  },
  {
    id: 80,
    stat: StatName.OppTransitionPts,
    definition: "Opponent's transition points",
  },
  { id: 81, stat: StatName.OppTOVPct, definition: "Opponent's turnovers rate" },
  {
    id: 82,
    stat: StatName.OppPITP,
    definition: "Opponent's points in the paint",
  },
  {
    id: 83,
    stat: StatName.BLKA,
    definition: "Blocks Attempts",
  },
  {
    id: 84,
    stat: StatName.PFD,
    definition: "Personal Fouls Drawn",
  },
  {
    id: 85,
    stat: StatName.PtsOffTov,
    definition: "Points Off Turnovers",
  },
  {
    id: 86,
    stat: StatName.Pts2ndChance,
    definition: "Points From Second Chance Opportunities",
  },
  {
    id: 87,
    stat: StatName.PtsFb,
    definition: "Points From Fast Breaks",
  },
  {
    id: 88,
    stat: StatName.ThrPtsPct,
    definition: "Percentage of points scored from three-point field goals.",
  },
  {
    id: 89,
    stat: StatName.FBptsPct,
    definition: "Percentage of points scored on fast breaks.",
  },
  {
    id: 90,
    stat: StatName.FTptsPct,
    definition: "Percentage of points scored from free throws.",
  },
  {
    id: 91,
    stat: StatName.PtsOffTovPct,
    definition: "Percentage of points scored off turnovers.",
  },
  {
    id: 92,
    stat: StatName.PtsPaintPct,
    definition: "Percentage of points scored in the paint.",
  },
  {
    id: 93,
    stat: StatName.Ast2PTPct,
    definition: "Percentage of assisted two-point field goals.",
  },
  {
    id: 95,
    stat: StatName.Ast3PTPct,
    definition: "Percentage of assisted three-point field goals.",
  },
  {
    id: 97,
    stat: StatName.AstFgmPct,
    definition: "Percentage of assisted field goals made.",
  },
  {
    id: 99,
    stat: StatName.DefRimFga,
    definition: "Number of defensive field goal attempts at the rim.",
  },
  {
    id: 100,
    stat: StatName.DefRimRate,
    definition: "Rate of defensive field goal attempts at the rim.",
  },
  {
    id: 101,
    stat: StatName.DefRimFgPct,
    definition: "Defensive field goal percentage allowed at the rim.",
  },
  {
    id: 102,
    stat: StatName.PctFgm,
    definition: "Percent of Team's Field Goals Made",
  },
  {
    id: 103,
    stat: StatName.PctFga,
    definition: "Percent of Team's Field Goals Attempted",
  },
  {
    id: 104,
    stat: StatName.PctFg3m,
    definition: "Percent of Team's Three Point Field Goals Made",
  },
  {
    id: 105,
    stat: StatName.PctFg3a,
    definition: "Percent of Team's Three Point Field Goals Attempted",
  },
  {
    id: 106,
    stat: StatName.PctFtm,
    definition: "Percent of Team's Free Throws Made",
  },
  {
    id: 107,
    stat: StatName.PctFta,
    definition: "Percent of Team's Free Throws Attempted",
  },
  {
    id: 108,
    stat: StatName.PctPts2pt,
    definition: "Percent of Team's Points Scored from Two Point Field Goals",
  },
  {
    id: 109,
    stat: StatName.PctPts2ptMr,
    definition:
      "Percent of Team's Points Scored from Two Point Mid-Range Shots",
  },
  {
    id: 110,
    stat: StatName.PctPts3pt,
    definition: "Percent of Team's Points Scored from Three Point Shots",
  },
  {
    id: 111,
    stat: StatName.PctPtsFb,
    definition: "Percent of Team's Points Scored on Fast Breaks",
  },
  {
    id: 112,
    stat: StatName.PctPtsFt,
    definition: "Percent of Team's Points Scored from Free Throws",
  },
  {
    id: 113,
    stat: StatName.PctPtsOffTov,
    definition: "Percent of Team's Points Scored off Turnovers",
  },
  {
    id: 114,
    stat: StatName.PctPtsPaint,
    definition: "Percent of Team's Points Scored in the Paint",
  },
  {
    id: 115,
    stat: StatName.PctAst2pm,
    definition: "Percent of Team's Assisted Two Point Field Goals Made",
  },
  {
    id: 116,
    stat: StatName.PctAst3pm,
    definition: "Percent of Team's Assisted Three Point Field Goals Made",
  },
  {
    id: 117,
    stat: StatName.PctAstFgm,
    definition: "Percentage of assisted field goals made.",
  },
  {
    id: 118,
    stat: StatName.PctUast2pm,
    definition: "Percent of Team's Unassisted Two Point Field Goals Made",
  },
  {
    id: 119,
    stat: StatName.PctUast3pm,
    definition: "Percent of Team's Unassisted Three Point Field Goals Made",
  },
  {
    id: 120,
    stat: StatName.PctOreb,
    definition: "Percent of Team's Offensive Rebounds.",
  },
  {
    id: 121,
    stat: StatName.PctDreb,
    definition: "Percent of Team's Defensive Rebounds",
  },
  {
    id: 122,
    stat: StatName.PctReb,
    definition: "Percent of Team's Total Rebounds",
  },
  {
    id: 123,
    stat: StatName.PctAst,
    definition: "Percent of Team's Assists",
  },
  {
    id: 124,
    stat: StatName.PctTov,
    definition: "Percent of Team's Turnovers",
  },
  {
    id: 125,
    stat: StatName.PctStl,
    definition: "Percent of Team's Steals",
  },
  {
    id: 126,
    stat: StatName.PctBlk,
    definition: "Percent of Team's Blocks",
  },
  {
    id: 127,
    stat: StatName.PctPts2ptMr,
    definition:
      "Percent of Team's Points Scored from Two Point Mid-Range Shots",
  },

  {
    id: 128,
    stat: StatName.OppFGM,
    definition: "Opponent's Field Goals Made",
  },
  {
    id: 129,
    stat: StatName.OppFGA,
    definition: "Opponent's Field Goals Attempted",
  },
  {
    id: 130,
    stat: StatName.OppFGPct,
    definition: "Opponent's Field Goal Percentage",
  },
  {
    id: 131,
    stat: StatName.Opp3PM,
    definition: "Opponent's Three Point Field Goals Made",
  },
  {
    id: 132,
    stat: StatName.Opp3PA,
    definition: "Opponent's Three Point Field Goals Attempted",
  },
  {
    id: 133,
    stat: StatName.Opp3PPct,
    definition: "Opponent's Three Point Field Goal Percentage",
  },
  {
    id: 134,
    stat: StatName.OppFTM,
    definition: "Opponent's Free Throws Made",
  },
  {
    id: 135,
    stat: StatName.OppFTA,
    definition: "Opponent's Free Throws Attempted",
  },
  {
    id: 136,
    stat: StatName.OppFTPct,
    definition: "Opponent's Free Throw Percentage",
  },
  {
    id: 137,
    stat: StatName.OppOREB,
    definition: "Opponent's Offensive Rebounds",
  },
  {
    id: 138,
    stat: StatName.OppDREB,
    definition: "Opponent's Defensive Rebounds",
  },
  {
    id: 139,
    stat: StatName.OppREB,
    definition: "Opponent's Total Rebounds",
  },
  {
    id: 140,
    stat: StatName.OppAST,
    definition: "Opponent's Assists",
  },
  {
    id: 141,
    stat: StatName.OppTOV,
    definition: "Opponent's Turnovers",
  },
  {
    id: 142,
    stat: StatName.OppSTL,
    definition: "Opponent's Steals",
  },
  {
    id: 143,
    stat: StatName.OppBLK,
    definition: "Opponent's Blocks",
  },
  {
    id: 144,
    stat: StatName.OppBLKA,
    definition: "Opponent's Block Attempts",
  },
  {
    id: 145,
    stat: StatName.OppPF,
    definition: "Opponent's Personal Fouls",
  },
  {
    id: 146,
    stat: StatName.OppPfd,
    definition: "Opponent's Personal Fouls Drawn",
  },
  {
    id: 147,
    stat: StatName.OppPts,
    definition: "Opponent's Points",
  },
  {
    id: 149,
    stat: StatName.OppPlusMinus,
    definition: "Opponent's Plus/Minus",
  },
  {
    id: 150,
    stat: StatName.AstTo,
    definition: "Assist to Turnover Ratio",
  },
  {
    id: 151,
    stat: StatName.AstRatio,
    definition: "Assist Ratio",
  },
  {
    id: 152,
    stat: StatName.TsPct,
    definition: "True Shooting Percentage",
  },
  {
    id: 153,
    stat: StatName.Pie,
    definition: "Player Impact Estimate",
  },

  {
    id: 154,
    stat: StatName.Advanced,
    definition:
      "Advanced statistics that go beyond the basic box score metrics, often including efficiency and pace-related measurements.",
  },
  {
    id: 155,
    stat: StatName.BoxScore,
    definition:
      "Traditional box score data such as points, rebounds, assists, steals, and blocks.",
  },
  {
    id: 156,
    stat: StatName.Defense,
    definition:
      "Statistics focused on defensive performance, including blocks, steals, and defensive rating.",
  },
  {
    id: 157,
    stat: StatName.FourFactors,
    definition:
      "Metrics introduced by Dean Oliver focusing on four key areas: Effective Field Goal %, Turnover %, Offensive Rebound %, and Free Throw Rate.",
  },
  {
    id: 158,
    stat: StatName.Misc,
    definition:
      "Miscellaneous statistics that don't fit into other specific categories, such as plus-minus or clutch stats.",
  },
  {
    id: 159,
    stat: StatName.Opponent,
    definition:
      "Statistics capturing opposing team performance, often used to measure defensive impact or matchup advantages.",
  },
  {
    id: 160,
    stat: StatName.Scoring,
    definition:
      "Offensive scoring metrics, including points per game, shooting percentages, and related efficiency metrics.",
  },
  {
    id: 161,
    stat: StatName.Usage,
    definition:
      "Statistics indicating the percentage of team plays a player uses while on the floor, such as usage rate.",
  },
  {
    id: 162,
    stat: StatName.Per100Possessions,
    definition:
      "Statistics normalized to a per 100 possessions basis, allowing comparison of performance independent of pace.",
  },
  {
    id: 163,
    stat: StatName.Per100Plays,
    definition:
      "Statistics normalized to a per 100 plays basis, providing insights into efficiency during active play.",
  },
  {
    id: 164,
    stat: StatName.PerPossession,
    definition:
      "Statistics calculated per possession, representing contributions or impact per team possession.",
  },
  {
    id: 165,
    stat: StatName.Per48,
    definition:
      "Statistics extrapolated to a 48-minute game, which represents a full regulation NBA game.",
  },
  {
    id: 166,
    stat: StatName.Per40,
    definition:
      "Statistics extrapolated to a 40-minute game, typically used for college basketball comparisons.",
  },
  {
    id: 167,
    stat: StatName.Per36,
    definition:
      "Statistics extrapolated to a 36-minute game, commonly used to compare players with varying minutes.",
  },
  {
    id: 168,
    stat: StatName.PerMinute,
    definition:
      "Statistics calculated on a per-minute basis, highlighting a player's productivity relative to time played.",
  },
  {
    id: 169,
    stat: StatName.PerPlay,
    definition:
      "Statistics calculated per play, showcasing efficiency and effectiveness in individual plays.",
  },
  {
    id: 170,
    stat: StatName.Totals,
    definition:
      "Cumulative or total counts of standard statistics like total points, total rebounds, and total assists.",
  },
  {
    id: 171,
    stat: StatName.Base,
    definition:
      "Base statistics, including points, rebounds, assists, steals, and blocks.",
  },

  {
    id: 172,
    stat: StatName.PctPf,
    definition: "Percent of Team's Personal Fouls",
  },

  {
    id: 173,
    stat: StatName.PctPfd,
    definition: "Percent of Team's Personal Fouls Drawn",
  },

  {
    id: 174,
    stat: StatName.PctPts,
    definition: "Percent of Team's Points",
  },

  {
    id: 175,
    stat: StatName.PctBlka,
    definition: "Percent of Team's Block Attempts",
  },

  {
    id: 176,
    stat: StatName.TORatio,
    definition: "Turnover Ratio",
  },
];
