import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { Flex, Text, Button } from "@suns/design-system";
import dayjs from "dayjs";
import { URL } from "@/shared/utils/route-constant";
import { Link } from "react-router-dom";

interface PhaseReportGameSelectionProps {
  readonly: boolean;
  reports: ReportResponseItem[];
}
export default function PhaseReportGameSelection({
  readonly,
  reports,
}: PhaseReportGameSelectionProps) {
  return (
    <Flex direction="down" gap="lg">
      {reports != null && reports.length > 0 ? (
        <Flex direction="down" gap="sm">
          <Text heading size="lg">
            Phase Report Games
          </Text>
          <Flex direction="down" gap="xs">
            {reports.map((report) => {
              if (report.type == ReportResponseItem.type.PHASE) return null;

              const firstGame =
                report.games?.length > 0 ? report.games[0] : null;

              if (!firstGame) {
                return (
                  <Flex
                    direction="right"
                    gap="xs"
                    align="center"
                    key={report.id}
                  >
                    <Text heading>
                      {dayjs(report.createdAt).format("MMMM Do")}
                    </Text>
                    Player Report
                    {!readonly && (
                      <Button variant="link" size="xs" type="button">
                        <Link
                          to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
                        >
                          (View Report)
                        </Link>
                      </Button>
                    )}
                  </Flex>
                );
              }

              const opponent =
                firstGame.homeTeam?.id === report.teamId
                  ? firstGame.awayTeam
                  : firstGame.homeTeam;

              return (
                <Flex direction="right" gap="xs" align="center" key={report.id}>
                  {report.games.length > 1 ? (
                    <Text heading>{report.games.length}-Game Report</Text>
                  ) : (
                    <>
                      <Text heading>
                        {dayjs(firstGame.gameTimeUTC).format("MMMM Do")}
                      </Text>
                      against
                      <Text heading>{opponent?.fullName}</Text>
                    </>
                  )}
                  {!readonly && (
                    <Button variant="link" size="xs" type="button">
                      <Link
                        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
                      >
                        (View Report)
                      </Link>
                    </Button>
                  )}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
}
