import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";

export function useGameSelect(params: {
  playerId: number;
  authorUsername: string;
}) {
  return useAsync(async ({ playerId, authorUsername }) => {
    const gameResponse = await apolloApi.getPlayerGames(playerId);
    const reportResponse = await apolloApi.getReports({
      playerIds: [playerId],
      authorUsername: [authorUsername],
      limit: 100,
    });

    //filter out games that this author has already written a report for
    const games = gameResponse.games.filter(
      (game) =>
        !reportResponse.reports.find(
          (report) =>
            report.playerId === playerId &&
            report.games.find((reportGame) => reportGame.id === game.id)
        )
    );

    return games;
  }, params);
}
