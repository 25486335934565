/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerMetadataApolloGradeRow } from './PlayerMetadataApolloGradeRow';
import type { PlayerMetadataScoreRow } from './PlayerMetadataScoreRow';
export type PlayerMetadataRow = {
    target: PlayerMetadataRow.target | null;
    aim: number | null;
    oAim: number | null;
    dAim: number | null;
    ppv: number | null;
    oPpv: number | null;
    dPpv: number | null;
    adjustedPpv: number | null;
    rank: number | null;
    rankTotal: number | null;
    positionRank: number | null;
    positionRankTotal: number | null;
    hybridPosition: PlayerMetadataRow.hybridPosition | null;
    scoutPosition: PlayerMetadataRow.scoutPosition | null;
    hybridRole: PlayerMetadataRow.hybridRole | null;
    scoutRole: PlayerMetadataRow.scoutRole | null;
    hybridGradeCurrent: PlayerMetadataApolloGradeRow | null;
    scoutGradeCurrent: PlayerMetadataApolloGradeRow | null;
    analyticGradeCurrent: PlayerMetadataApolloGradeRow | null;
    hybridGradeRemainingCapacity: PlayerMetadataApolloGradeRow | null;
    scoutGradeRemainingCapacity: PlayerMetadataApolloGradeRow | null;
    scoutScores: Array<PlayerMetadataScoreRow> | null;
    hybridScores: Array<PlayerMetadataScoreRow> | null;
    capHit: number | null;
    capHit1Year: number | null;
    capHit2Year: number | null;
    capHit3Year: number | null;
    capHit4Year: number | null;
    aav: number | null;
    unrestrictedFreeAgent: number | null;
    restrictedFreeAgent: number | null;
    playerOption: number | null;
    teamOption: number | null;
    prv: number | null;
    snapshotDate: string | null;
};
export namespace PlayerMetadataRow {
    export enum target {
        TRADE = 'TRADE',
        FREE_AGENT = 'FREE_AGENT',
    }
    export enum hybridPosition {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum scoutPosition {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum hybridRole {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERIOR_DEFENDER = 'INTERIOR_DEFENDER',
    }
    export enum scoutRole {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERIOR_DEFENDER = 'INTERIOR_DEFENDER',
    }
}

