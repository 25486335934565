/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReportQueryParams = {
    offset?: number;
    limit?: number;
    sortColumn?: string;
    sortDir?: string;
    ids?: Array<number>;
    playerIds?: Array<number>;
    teamIds?: Array<number>;
    level?: ReportQueryParams.level;
    gameId?: number;
    authorUsername?: Array<string>;
    status?: ReportQueryParams.status;
    type?: ReportQueryParams.type;
    include?: Array<string>;
    phaseReportId?: number;
    phase?: ReportQueryParams.phase;
};
export namespace ReportQueryParams {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
    export enum type {
        PHASE = 'PHASE',
        GENERAL = 'GENERAL',
    }
    export enum phase {
        PHASE_24_25_0 = 'PHASE_24_25_0',
        PHASE_24_25_1 = 'PHASE_24_25_1',
        PHASE_24_25_2 = 'PHASE_24_25_2',
        PHASE_24_25_3 = 'PHASE_24_25_3',
        PHASE_24_25_4 = 'PHASE_24_25_4',
        PHASE_24_25_5 = 'PHASE_24_25_5',
        PHASE_24_25_6 = 'PHASE_24_25_6',
    }
}

