import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, cn, Flex, Text } from "@suns/design-system";
import dayjs from "dayjs";

import { TeamLogo } from "@/components";
import { LeagueId } from "@suns/api";
import { Game } from "../../loaders/schedule-loader";
import { getLeagueIdFromNbaLeagueId } from "@/shared/utils";

export function GameCard({ ...props }: Game & { nbaPrimaryTeamId: number }) {
  const {
    nbaGameId,
    homeTeamNbaId,
    homeTeamAbbr,
    homeTeamScore,
    awayTeamNbaId,
    awayTeamAbbr,
    awayTeamScore,
    gameDateTimeUtc,
    gameWinnerTeamNbaId,
    gameNumber,
    nbaLeagueId,
    nbaPrimaryTeamId,
  } = props;
  const date = useMemo(
    () => dayjs(gameDateTimeUtc).tz(dayjs.tz.guess() ?? "America/Phoenix"),
    [gameDateTimeUtc]
  );

  const showScore = awayTeamScore && homeTeamScore;
  const leagueId = getLeagueIdFromNbaLeagueId(nbaLeagueId);

  return (
    <Link to={`/game/${nbaGameId}/live`}>
      <Flex>
        <Flex
          direction="down"
          className="mr-4 w-52 flex-1 border-r-2 border-gray-300 pr-4"
        >
          <GameCardTeam
            id={awayTeamNbaId}
            abbr={awayTeamAbbr}
            score={showScore ? awayTeamScore : undefined}
            isWinner={awayTeamNbaId == gameWinnerTeamNbaId}
            colorWinner={awayTeamNbaId == nbaPrimaryTeamId}
            leagueId={leagueId}
          />
          <GameCardTeam
            id={homeTeamNbaId}
            abbr={homeTeamAbbr}
            score={showScore ? homeTeamScore : undefined}
            isWinner={homeTeamNbaId == gameWinnerTeamNbaId}
            colorWinner={homeTeamNbaId == nbaPrimaryTeamId}
            leagueId={leagueId}
          />
        </Flex>

        <Flex
          direction="down"
          justify="between"
          align="start"
          className="w-1/3 py-2"
        >
          {gameNumber ? <Text heading>Game {gameNumber}</Text> : null}
          <Text>{date.format("MMMM D, YYYY")}</Text>
          <Button variant="link" size="flush">
            Game Stats
          </Button>
        </Flex>
      </Flex>
    </Link>
  );
}

function GameCardTeam({
  id,
  abbr,
  score,
  isWinner,
  colorWinner,
  leagueId,
}: {
  id: number;
  abbr: string;
  score?: number;
  isWinner: boolean;
  colorWinner: boolean;
  leagueId: LeagueId;
}) {
  return (
    <Flex
      align="center"
      gap="sm"
      justify={score ? "between" : "start"}
      direction="right"
    >
      <TeamLogo nbaTeamId={id} leagueId={leagueId} size="sm" />
      <Text size="2xl" heading>
        {abbr}
      </Text>
      {score ? (
        <Text
          size="2xl"
          className={cn(
            "ml-auto",
            colorWinner && (isWinner ? "text-green-600" : "text-red-600")
          )}
        >
          {score}
        </Text>
      ) : null}
    </Flex>
  );
}
