import { Suspense } from "react";
import { Outlet, type RouteObject } from "react-router-dom";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { getFilterData } from "@/shared/utils/helper-functions";
import BoxScores from "@/pages/box-scores/box-scores";
import LineUps from "@/pages/box-scores/lineups/lineups";
import Rebounding from "@/pages/box-scores/rebounding/rebounding";
import { protectedLoader } from "@/shared/utils";

export const BoxScoreRoutes = {
  path: "suns/box-scores",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <BoxScores />,
      loader: protectedLoader(getFilterData),
      errorElement: <ErrorFallback />,
    },
    {
      path: "lineups",
      element: <LineUps />,
      loader: protectedLoader(getFilterData),
      errorElement: <ErrorFallback />,
    },
    {
      path: "rebounding",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <Rebounding />
        </Suspense>
      ),
      loader: protectedLoader(getFilterData),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;
