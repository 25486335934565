import { apolloApi } from "@/shared/api";
import { AgentQueryParams } from "@suns/api/generated-client/apollo/models/AgentQueryParams";

export async function agenciesLoader() {
  const response = await apolloApi.getAgencies({});
  return response;
}

export async function agentsLoader({
  offset,
  limit,
  sortColumn,
  sortDir,
  agencyId,
}: AgentQueryParams) {
  const response = await apolloApi.getAgents({
    offset,
    limit,
    sortColumn,
    sortDir,
    agencyId,
  });
  return response;
}

export async function agentByIdLoader({ id }: { id: number }) {
  const response = await apolloApi.getAgentById(id);

  return response;
}
