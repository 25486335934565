/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShotsResponse = {
    location: ShotsResponse.location;
    fgm: number;
    shotsPct: number;
    fga: number;
    efgPct: number | null;
    qsp: number | null;
    qsm: number | null;
    ppp: number;
    videoUrl: string | null;
};
export namespace ShotsResponse {
    export enum location {
        ALL_SHOTS = 'ALL_SHOTS',
        RIM = 'RIM',
        NON_RIM_PAINT = 'NON_RIM_PAINT',
        MIDRANGE = 'MIDRANGE',
        ALL_3S = 'ALL_3S',
        CORNER_3 = 'CORNER_3',
        ABOVE_THE_BREAK_3 = 'ABOVE_THE_BREAK_3',
        C_AND_S_3 = 'C_AND_S_3',
        OFF_DRIBBLE_3 = 'OFF_DRIBBLE_3',
    }
}

