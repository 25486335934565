import { useEffect } from "react";
import { useNavigateLogin } from "@/shared/hooks";

export function Clear() {
  const navigateLogin = useNavigateLogin();

  useEffect(() => {
    localStorage.clear();
    navigateLogin({ backToHome: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
