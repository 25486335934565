import { useState } from "react";
import { PlayerCacheItem, usePlayerTeamAgentSearch } from "@/shared/hooks";
import { Filter, FilterOption, Skeleton } from "@suns/design-system";
import React from "react";
import { SearchType } from "../PlayerTeamSearch/PlayerTeamAgentSearch";

interface PlayerFilterProps {
  value: string[];
  onChange: (keys: string[], items: PlayerCacheItem[]) => void;
  className?: string;
}

export const PlayerFilter = React.forwardRef<
  HTMLButtonElement,
  PlayerFilterProps
>(({ value, onChange, className }, ref) => {
  const [searchValue, setSearchValue] = useState("");
  const { results, lookupMap, loading } = usePlayerTeamAgentSearch(
    searchValue,
    {
      type: [SearchType.PLAYER],
      limit: 30,
    }
  );

  if (loading) {
    return <Skeleton className="h-10 w-40" />;
  }

  const options = [
    ...value.map((id) => {
      const item = lookupMap?.get(`player-${id}`);
      if (!item) {
        throw new Error(`Player not found: ${id}`);
      }
      return {
        ...item,
        key: id,
        name: item.display,
        badgeValue: (item as PlayerCacheItem).teamCode,
      };
    }),
    ...results.reduce<(PlayerCacheItem & FilterOption)[]>((acc, result) => {
      if (value.includes(`${result.id}`)) {
        return acc;
      }
      acc.push({
        ...(result as PlayerCacheItem),
        key: `${result.id}`,
        name: result.display,
        badgeValue: (result as PlayerCacheItem).teamCode,
      });
      return acc;
    }, []),
  ];

  return (
    <Filter<PlayerCacheItem & FilterOption>
      innerRef={ref}
      shouldFilter={false}
      className={className}
      title="Add Player"
      value={value}
      onChange={onChange}
      options={options as (PlayerCacheItem & FilterOption)[]}
      onSearchChange={setSearchValue}
    />
  );
});
