import { PaginationControl } from "@/components";
import { IntelListItem } from "@/pages/intel/intel-listings/components/IntelListItem";
import { intelLoader } from "@/pages/intel/loaders/intel-loaders";
import { useAsync } from "@/shared/hooks";

import { SkeletonText } from "@suns/design-system";
import { SunsApiError } from "@suns/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";
import { Flex, Text } from "@suns/design-system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function PlayersAndTeamsIntelListings({
  tags,
  setTags,
  authorUsername,
}: {
  tags: string[];
  setTags: (tags: string[]) => void;
  authorUsername?: string[];
}) {
  const PAGE_SIZE = 10;
  const { playerId, teamId } = useParams();
  const [publishedPage, setPublishedPage] = useState(0);

  const {
    loading: publishedLoading,
    response: publishedResponse,
    error: publishedError,
    refresh: refreshPublished,
  } = useAsync(intelLoader, {
    status: IntelQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    playerIds: playerId ? [Number(playerId)] : undefined,
    teamIds: teamId ? [Number(teamId)] : undefined,
    tags: tags,
    authorUsername,
  });

  const handleSetPage = (page: number) => {
    setPublishedPage(page - 1);
  };

  const currentPublishedPage = Math.floor(
    (publishedResponse?.offset || 0) / (publishedResponse?.limit || 1)
  );

  useEffect(() => {
    if (publishedPage !== currentPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshPublished();
    }
  }, [publishedPage, currentPublishedPage, refreshPublished]);

  if (publishedError) {
    throw new SunsApiError("Error loading the published intel.", {
      cause: publishedError,
    });
  }

  const handleTagClick = (tag: string) => {
    const newTags = tags.includes(tag)
      ? tags.filter((t) => t !== tag)
      : [...tags, tag];
    setTags(newTags);
  };

  return (
    <>
      {publishedLoading ? (
        <IntelListingsLoading />
      ) : (
        <>
          {publishedResponse && publishedResponse?.intel?.length > 0 ? (
            <Flex direction="down" className="divide-y-2">
              {publishedResponse?.intel.map((intel) => (
                <IntelListItem
                  hideButtons
                  key={intel.id}
                  className="py-4"
                  intel={intel}
                  onTagClick={handleTagClick}
                />
              ))}
            </Flex>
          ) : (
            <Text className="py-10 text-center" size="lg" muted>
              No intel found.
            </Text>
          )}
          <PaginationControl
            {...publishedResponse}
            onPageClicked={handleSetPage}
          />
        </>
      )}
    </>
  );
}

function IntelListingsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
