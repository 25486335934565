import { ApolloBadgeIcon, Icon } from "@suns/design-system/icons";
import { Flex } from "../Flex/Flex";

export function ApolloBadge({
  size,
  color,
  icon,
}: {
  size: number;
  color: string;
  icon?: Icon;
}) {
  const IconElement = icon;
  return (
    <Flex className="relative" align="center" justify="center">
      <ApolloBadgeIcon size={size} color={color} />
      {IconElement && (
        <IconElement
          size={size * 0.55}
          color="white"
          strokeWidth={3}
          className="absolute left-1"
        />
      )}
    </Flex>
  );
}
