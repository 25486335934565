import type { Config } from "tailwindcss";
import { theme } from "./packages/@suns/design-system/src/theme";
import radixPlugin from "tailwindcss-radix";
import animatePlugin from "tailwindcss-animate";

export default {
  content: [
    "./src/index.html",
    "./src/**/*.{ts,tsx}",
    "./packages/**/*.{ts,tsx}",
  ],
  theme,
  plugins: [animatePlugin, radixPlugin({ variantPrefix: "radix" })],
  safelist: [
    {
      pattern: /grid-cols-(2|3|4|5)/,
      variants: ["sm", "md", "lg"],
    },
    {
      pattern: /gap-(xs|sm|md|lg|xl)/,
      variants: ["sm", "md", "lg"],
    },
    {
      pattern: /text-(6xl|5xl|4xl|3xl|2xl|xl|lg|base|sm)/,
      variants: ["sm", "md", "lg"],
    },
    {
      pattern: /w-(12|16|24)/,
      variants: ["sm", "md", "lg"],
    },
  ],
} satisfies Config;
