/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractProtectionRow = {
    id: number;
    contractId: number;
    hasClause: boolean;
    year: number;
    coverage: ContractProtectionRow.coverage;
    coverageTypes: string;
    amount: number;
    effectiveAmount: number;
};
export namespace ContractProtectionRow {
    export enum coverage {
        FULL = 'FULL',
        NOCND = 'NOCND',
        NONE = 'NONE',
        PART = 'PART',
        PTCND = 'PTCND',
    }
}

