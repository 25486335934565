import { ApolloPhase } from "@suns/api";

interface DisplayPhaseOptions {
  includeYear?: boolean;
}

export function displayPhase(
  phase: ApolloPhase,
  { includeYear = false }: DisplayPhaseOptions = {}
) {
  const [, year1, year2, phaseNumber] = phase.split("_");
  if (includeYear) {
    return `Phase ${phaseNumber}, 20${year1}-${year2}`;
  }
  return `Phase ${phaseNumber}`;
}
