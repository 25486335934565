import { apolloApi } from "@/shared/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";

export async function myReportsLoader({
  authorUsername,
  status,
  offset = 0,
  limit = 20,
  teamIds,
  playerId,
}: {
  authorUsername?: string[];
  status:
    | ReportQueryParams.status.PUBLISHED
    | ReportQueryParams.status.UNPUBLISHED;
  offset: number;
  limit: number;
  teamIds?: number[];
  position?: string[];
  role?: string[];
  playerId?: number;
}) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    status,
    authorUsername: authorUsername,
    teamIds,
    playerIds: playerId ? [playerId] : undefined,
  });

  return response;
}
