/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AssessmentApolloGrade = {
    id: number;
    assessmentId: number;
    scope: AssessmentApolloGrade.scope;
    value: AssessmentApolloGrade.value;
    numericalValue: number;
};
export namespace AssessmentApolloGrade {
    export enum scope {
        BULLSEYE = 'BULLSEYE',
        CEILING = 'CEILING',
        BASEMENT = 'BASEMENT',
        CURRENT = 'CURRENT',
        REMAINING_CAPACITY = 'REMAINING_CAPACITY',
    }
    export enum value {
        FRANCHISE = 'FRANCHISE',
        ALL_STAR_PLUS = 'ALL_STAR_PLUS',
        ALL_STAR = 'ALL_STAR',
        STARTER = 'STARTER',
        TOP_ROTATION = 'TOP_ROTATION',
        ROTATION = 'ROTATION',
        ROSTER = 'ROSTER',
        MINOR_LEAGUE = 'MINOR_LEAGUE',
    }
}

