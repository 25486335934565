import { LeagueId, NbaLeagueId, MeasureType } from "@suns/api";
import { TeamSeasonStatsParams } from "@suns/api/src/suns-api";
import { useAsync } from "@/shared/hooks/useAsync";
import { seasonType } from "@suns/api/generated-client/stats";
import {
  teamRosterStatsLoader,
  teamSeasonStatsLoader,
  teamsGameStatsLoader,
} from "../loader/team-loader";
import { CURRENT_SEASON_LABEL } from "@/shared/const";
import { teamGrouping } from "@suns/api/generated-client/querytool";

export interface TeamStatParams {
  measureTypeNBARegularSeason: MeasureType;
  perModeNBARegularSeason: TeamSeasonStatsParams["perMode"];

  measureTypeNBAPlayoffs: MeasureType;
  perModeNBAPlayoffs: TeamSeasonStatsParams["perMode"];

  measureTypeNbaTeamRoster: MeasureType;
  perModeNbaTeamRoster: TeamSeasonStatsParams["perMode"];

  measureTypeGLeagueRegularSeason: MeasureType;
  perModeGLeagueRegularSeason: TeamSeasonStatsParams["perMode"];

  measureTypeGLeagueShowcase: MeasureType;
  perModeGLeagueShowcase: TeamSeasonStatsParams["perMode"];

  measureTypeGLeaguePlayoffs: MeasureType;
  perModeGLeaguePlayoffs: TeamSeasonStatsParams["perMode"];

  measureTypeGLeagueTeamRoster: MeasureType;
  perModeGLeagueTeamRoster: TeamSeasonStatsParams["perMode"];

  measureTypeNbaGames: string;
  nbaGamesSeasonType: seasonType;
  playerLastNGamesNba: string;

  gLeagueGamesSeasonType: seasonType;
  measureTypeGLeagueGames: string;
  gLeaguePlayerLastNGames: string;
}

export const useTeamStats = ({
  statParams,
  seasons,
  nbaTeamId,
  apolloLeagueId,
  currentSeason,
}: {
  statParams: TeamStatParams;
  seasons: string;
  nbaTeamId: number | null;
  apolloLeagueId: LeagueId;
  currentSeason: typeof CURRENT_SEASON_LABEL;
}) => {
  const isNbaTeam = apolloLeagueId === LeagueId.NBA;
  const isGLeagueTeam = apolloLeagueId === LeagueId.GLEAGUE;

  // * NBA Season Stats
  const nbaRegularSeasonStats = useAsync(
    teamSeasonStatsLoader,
    {
      leagueId: NbaLeagueId.NBA,
      perMode:
        statParams.perModeNBARegularSeason as TeamSeasonStatsParams["perMode"],
      season: seasons,
      seasonType: seasonType.REGULAR_SEASON,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeNBARegularSeason as MeasureType,
    },
    !isNbaTeam
  );

  const nbaPlayoffStats = useAsync(
    teamSeasonStatsLoader,
    {
      leagueId: NbaLeagueId.NBA,
      perMode:
        statParams.perModeNBAPlayoffs as TeamSeasonStatsParams["perMode"],
      season: seasons,
      seasonType: seasonType.PLAYOFFS,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeNBAPlayoffs as MeasureType,
    },
    !isNbaTeam
  );

  const nbaRosterSeasonStats = useAsync(
    teamRosterStatsLoader,
    {
      leagueId: NbaLeagueId.NBA,
      season: currentSeason,
      seasonType: seasonType.REGULAR_SEASON,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeNbaTeamRoster as MeasureType,
      grouping: "None",
      teamGrouping: teamGrouping.N,
      perMode:
        statParams.perModeNbaTeamRoster as TeamSeasonStatsParams["perMode"],
      activeRoster: 1,
    },
    !isNbaTeam
  );

  // * G-League Season Stats
  const gLeagueRegularSeasonStats = useAsync(
    teamSeasonStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      perMode:
        statParams.perModeGLeagueRegularSeason as TeamSeasonStatsParams["perMode"],
      season: seasons,
      seasonType: seasonType.REGULAR_SEASON,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeGLeagueRegularSeason as MeasureType,
    },
    !isGLeagueTeam
  );

  const gLeagueStatsShowcase = useAsync(
    teamSeasonStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      perMode:
        statParams.perModeGLeagueShowcase as TeamSeasonStatsParams["perMode"],
      season: seasons,
      seasonType: "Showcase" as seasonType,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeGLeagueShowcase as MeasureType,
    },
    !isGLeagueTeam
  );

  const gLeagueStatsPlayoffs = useAsync(
    teamSeasonStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      perMode:
        statParams.perModeGLeaguePlayoffs as TeamSeasonStatsParams["perMode"],
      season: seasons,
      seasonType: seasonType.PLAYOFFS,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeGLeaguePlayoffs as MeasureType,
    },
    !isGLeagueTeam
  );

  const gLeagueRosterSeasonStats = useAsync(
    teamRosterStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      season: currentSeason,
      seasonType: seasonType.REGULAR_SEASON,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeGLeagueTeamRoster as MeasureType,
      grouping: "None",
      teamGrouping: teamGrouping.N,
      perMode:
        statParams.perModeGLeagueTeamRoster as TeamSeasonStatsParams["perMode"],
      activeRoster: 1,
    },
    !isGLeagueTeam
  );

  // * Game Stats
  const nbaGameStats = useAsync(
    teamsGameStatsLoader,

    {
      leagueId: NbaLeagueId.NBA,
      seasonYear: currentSeason,
      seasonType: statParams.nbaGamesSeasonType as seasonType,
      teamId: nbaTeamId,
      measureType:
        statParams.measureTypeNbaGames === "Defense"
          ? "Defensive"
          : (statParams.measureTypeNbaGames as MeasureType),
      teamGrouping: teamGrouping.N,
      grouping: "None",
      teamLastNGames: Number(statParams.playerLastNGamesNba),
    },
    !isNbaTeam
  );

  const gLeagueGameStats = useAsync(
    teamsGameStatsLoader,
    {
      leagueId: NbaLeagueId.GLEAGUE,
      seasonYear: currentSeason,
      seasonType: statParams.gLeagueGamesSeasonType as seasonType,
      teamId: nbaTeamId,
      measureType: statParams.measureTypeGLeagueGames as MeasureType,
      teamGrouping: teamGrouping.N,
      grouping: "None",
      teamLastNGames: Number(statParams.gLeaguePlayerLastNGames),
    },
    !isGLeagueTeam
  );

  return {
    nbaRegularSeasonStats,
    nbaPlayoffStats,
    nbaRosterSeasonStats,
    gLeagueRegularSeasonStats,
    gLeagueStatsShowcase,
    gLeagueStatsPlayoffs,
    gLeagueRosterSeasonStats,
    nbaGameStats,
    gLeagueGameStats,
  };
};
