import { useAccount } from "@/shared/hooks/useAccount";
import {
  Card,
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { myReportsLoader } from "./loaders/my-reports-loader";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { SunsApiError } from "@suns/api";
import { defaultFilterValues } from "@/shared/const";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { FilterValues } from "./all-reports-listings";
import { useSearchParams } from "react-router-dom";
import ReportFilters from "./components/ReportFilters";
import { ReportsList } from "@/components/ReportsList/ReportsList";
import { Page } from "@/components";

const PAGE_SIZE = 20;

export function ReportsListings() {
  const account = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamIds: searchParams.getAll("teamIds")?.map(Number),
    playerId: Number(searchParams.getAll("playerId")) || undefined,
    status: (searchParams.get("status") as FilterValues["status"]) || undefined,
    publishedPage: Number(searchParams.get("publishedPage")) || undefined,
    unPublishedPage: Number(searchParams.get("unPublishedPage")) || undefined,
    tab: searchParams.get("tab") || undefined,
  });

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const [publishedPage, setPublishedPage] = useState(
    filterValues?.publishedPage ? filterValues.publishedPage - 1 : 0
  );
  const [unPublishedPage, setUnpublishedPage] = useState(
    filterValues?.unPublishedPage ? filterValues.unPublishedPage - 1 : 0
  );

  const {
    loading: publishedLoading,
    response: publishedReponse,
    refresh: refreshPublished,
    error: publishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerId: filterValues.playerId,
  });

  const {
    loading: unPublishedLoading,
    response: unPublishedReponse,
    refresh: refreshUnPublished,
    error: unPublishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.UNPUBLISHED,
    offset: unPublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerId: filterValues.playerId,
  });

  if (publishedError) {
    throw new SunsApiError("Error loading the published reports.", {
      cause: publishedError,
    });
  }

  if (unPublishedError) {
    throw new SunsApiError("Error loading the unpublished reports.", {
      cause: unPublishedError,
    });
  }

  const currentPublishedPage = Math.floor(
    (publishedReponse?.offset || 0) / (publishedReponse?.limit || 1)
  );

  const currentUnPublishedPage = Math.floor(
    (unPublishedReponse?.offset || 0) / (unPublishedReponse?.limit || 1)
  );

  useEffect(() => {
    if (publishedPage !== currentPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshPublished();
    }

    if (unPublishedPage !== currentUnPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshUnPublished();
    }
  }, [
    publishedPage,
    currentPublishedPage,
    refreshPublished,
    unPublishedPage,
    currentUnPublishedPage,
    refreshUnPublished,
  ]);

  const handleChangeFilterValues = (value: FilterValues) => {
    setPublishedPage(0);
    setUnpublishedPage(0);
    const updatedFilters = { ...filterValues, ...value };
    setFilterValues(updatedFilters);

    const params = new URLSearchParams();

    const { level, ...rest } = updatedFilters;

    Object.entries(rest).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v.toString()));
      } else if (value) {
        params.append(key, value.toString());
      }
    });

    setSearchParams(params);
  };

  const handleSetPage = (page: number) => {
    if (filterValues.tab === "published") {
      setPublishedPage(page - 1);
    } else {
      setUnpublishedPage(page);
    }
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [filterValues.tab === "published" ? "publishedPage" : "unPublishedPage"]:
        page.toString(),
    });
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);

    if (urlParams.publishedPage) {
      setPublishedPage(urlParams.publishedPage - 1);
    } else {
      setPublishedPage(0);
    }

    if (urlParams.unPublishedPage) {
      setUnpublishedPage(urlParams.unPublishedPage - 1);
    } else {
      setUnpublishedPage(0);
    }

    if (urlParams.tab) {
      setFilterValues((prev) => ({ ...prev, tab: urlParams.tab }));
    } else {
      setFilterValues((prev) => ({ ...prev, tab: "published" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeTab = (tab: string) => {
    setFilterValues((prev) => ({ ...prev, tab }));

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      teamIds: filterValues.teamIds?.map((id) => id.toString()) || [],
      tab: tab,
    });
  };

  return (
    <Page title="My Reports" breadcrumbs={false}>
      <Card
        header={
          <Text size="xl" heading>
            My Reports
          </Text>
        }
      >
        <Tabs onValueChange={handleChangeTab} value={filterValues.tab}>
          <TabsList>
            <TabsTrigger value="published">
              <Text>Published</Text>
            </TabsTrigger>
            <TabsTrigger value="unpublished">
              <Text>Unpublished</Text>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="published">
            <Flex direction="down" gap="md">
              <ReportFilters
                type="myReportsIntel"
                onChange={handleChangeFilterValues}
                onClear={handleClear}
                filterValues={filterValues}
              />
              <ReportsList
                loading={publishedLoading}
                response={
                  publishedReponse || {
                    reports: [],
                    offset: 0,
                    limit: PAGE_SIZE,
                    count: 0,
                    sortColumn: "createdAt",
                    sortDir: "DESC",
                  }
                }
                setPage={handleSetPage}
              />
            </Flex>
          </TabsContent>
          <TabsContent value="unpublished">
            <Flex direction="down" gap="md">
              <ReportFilters
                type="myReportsIntel"
                onChange={handleChangeFilterValues}
                onClear={handleClear}
                filterValues={filterValues}
              />
              <ReportsList
                loading={unPublishedLoading}
                response={
                  unPublishedReponse || {
                    reports: [],
                    offset: 0,
                    limit: PAGE_SIZE,
                    count: 0,
                    sortColumn: "createdAt",
                    sortDir: "DESC",
                  }
                }
                setPage={handleSetPage}
              />
            </Flex>
          </TabsContent>
        </Tabs>
      </Card>
    </Page>
  );
}
