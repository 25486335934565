import { Page } from "@/components";
import { TeamHero } from "./components/TeamHero";
import {
  Card,
  Container,
  Filter,
  Flex,
  Skeleton,
  SkeletonHeading,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@suns/design-system";
import { useAccount, useTeam } from "@/shared/hooks";
import { useParams } from "react-router-dom";
import { SunsApiError } from "@suns/api";
import { IntelTag, TeamRow } from "@suns/api/generated-client/apollo";
import { useState } from "react";
import { PlayersIntelTagsLabels } from "../players/players-const";
import { PlayersAndTeamsIntelListings } from "../players/components/PlayersAndTeamsIntelListings";
import { TeamStats } from "./components/TeamStats";

import { TeamOverview } from "./components/TeamOverview";

export function TeamsView() {
  const { teamId } = useParams();
  const { team, loading, error } = useTeam(Number(teamId));
  const [tags, setTags] = useState<string[]>([]);
  const { hasScoutAdminAccess } = useAccount();
  const tabOptions: string[] = ["Overview", "Stats"];

  if (hasScoutAdminAccess) {
    tabOptions.push("Intel");
  }

  function handleReportsFilterChange(values: string[]) {
    setTags(values);
  }

  const intelTagOptions = Object.values(IntelTag).map((tag) => ({
    key: tag,
    name: PlayersIntelTagsLabels[tag],
    value: tag,
  }));

  return (
    <Page
      title={team?.fullName ?? "View Team"}
      loading={loading}
      loadingState={<TeamViewLoading />}
      render={() => {
        if (error) {
          throw new SunsApiError("Error loading team", { cause: error });
        }

        return (
          <Container size="xl">
            <Flex direction="down" gap="md">
              {team && <TeamHero team={team} />}
              <Card>
                <Tabs defaultValue="Overview">
                  {tabOptions.length && (
                    <TabsList className="mb-4">
                      {tabOptions.map((tab) => (
                        <TabsTrigger key={tab} value={tab}>
                          {tab}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                  )}
                  <TabsContent value="Overview">
                    <TeamOverview teamId={teamId ?? ""} />
                  </TabsContent>
                  <TabsContent value="Stats">
                    <TeamStats team={team as TeamRow} />
                  </TabsContent>
                  <TabsContent value="Intel">
                    <Filter
                      className="mb-4"
                      value={tags}
                      title="Tags"
                      onChange={handleReportsFilterChange}
                      options={intelTagOptions}
                    />
                    <PlayersAndTeamsIntelListings
                      tags={tags}
                      setTags={setTags}
                    />
                  </TabsContent>
                </Tabs>
              </Card>
            </Flex>
          </Container>
        );
      }}
    />
  );
}

function TeamViewLoading() {
  return (
    <Container size="xl">
      <Flex direction="down" gap="md">
        <Card>
          {/* Desktop only */}
          <Flex gap="md" align="center" className="max-md:hidden">
            <Skeleton className="h-36 w-36 rounded-full" />
            <Flex direction="down" gap="md">
              <Skeleton className="h-10 w-48" />
              <Flex gap="xl">
                <Flex direction="down" gap="sm">
                  <Skeleton className="h-6 w-20" />
                  <Skeleton className="h-8 w-32" />
                </Flex>
                <Flex direction="down" gap="sm">
                  <Skeleton className="h-6 w-20" />
                  <Skeleton className="h-8 w-32" />
                </Flex>
                <Flex direction="down" gap="sm">
                  <Skeleton className="h-6 w-20" />
                  <Skeleton className="h-8 w-32" />
                </Flex>
                <Flex direction="down" gap="sm">
                  <Skeleton className="h-6 w-20" />
                  <Skeleton className="h-8 w-32" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {/* Mobile only */}
          <Flex gap="md" direction="down" className="md:hidden">
            <Flex gap="sm" align="center">
              <Skeleton className="h-8 w-8 rounded-full" />
              <Skeleton className="h-6 w-48" />
            </Flex>
            <Flex gap="lg">
              <Flex direction="down" gap="sm">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-6 w-16" />
              </Flex>
              <Flex direction="down" gap="sm">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-6 w-16" />
              </Flex>
              <Flex direction="down" gap="sm">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-6 w-36" />
              </Flex>
              <Flex direction="down" gap="sm">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-6 w-16" />
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card>
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="md">
              <SkeletonHeading size="3xl" className="w-36" />
              <SkeletonHeading size="3xl" className="w-20" />
            </Flex>
            <SkeletonText rows={3} />
            <SkeletonText rows={3} />
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
}
