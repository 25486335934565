import { useState } from "react";
import { PlayerHeaderSkeleton } from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import { apolloApi } from "@/shared/api";
import IntelForm, {
  IntelFormSchema,
} from "../intel-create-player-team/components/IntelForm";
import { Button, Card, Flex, Skeleton, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import { IntelRow, IntelUpsertParams } from "@suns/api/generated-client/apollo";
import { useAsync, useNavigateLogin } from "@/shared/hooks";
import {
  intelFormDataToUpsertParams,
  intelResponseToFormData,
} from "../intel-create-player-team/intel-utils";
import { SunsApiError } from "@suns/api";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";
import { intelByIdLoader } from "../loaders/intel-loaders";

export function IntelEdit() {
  const navigate = useNavigate();
  const navigateLogin = useNavigateLogin();
  const { intelId } = useParams();
  const [saving, setSaving] = useState(false);
  const [upsertedIntel, setUpsertedIntel] = useState<IntelFormSchema | null>(
    null
  );

  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelByIdLoader, {
    intelId: intelId ? Number(intelId) : undefined,
  });

  const handleSave = async (
    intel: IntelFormSchema,
    type: "save" | "publish"
  ) => {
    try {
      setSaving(true);
      const savedIntel = await apolloApi.saveIntel({
        id: intelId ? Number(intelId) : undefined,
        ...intelFormDataToUpsertParams({
          ...intel,
        }),
      });

      setUpsertedIntel(savedIntel.intel);

      if (
        type === "publish" &&
        intel.status === IntelUpsertParams.status.PUBLISHED
      ) {
        toastSuccess("Intel Published");
        navigate(-1);
      } else if (
        type === "publish" &&
        intel.status === IntelUpsertParams.status.UNPUBLISHED
      ) {
        toastInfo("Intel Unpublished");
      } else {
        toastInfo("Intel Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toastError("Please log back in to save.");
        navigateLogin();
      }
      toastError("Unable to save intel. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (intelError) {
    throw new SunsApiError("There was an error requesting intel.", {
      cause: intelError,
    });
  }

  return (
    <>
      <Flex className="mb-2" align="center">
        <Button
          size="xs"
          variant="ghost"
          className="p-0 text-sm text-gray-500"
          onClick={() => navigate("/reports/intel/create")}
        >
          Intel
        </Button>
        <ChevronRight className="text-gray-500 " size={20} />
        <Button size="xs" variant="ghost" className="p-0 text-sm text-gray-500">
          Edit Intel
        </Button>
      </Flex>
      <Text heading={true} size="4xl" className="mb-4">
        Edit Intel
      </Text>
      <Card>
        {intelLoading ? (
          <Flex direction="down" gap="lg" className="mt-1">
            <PlayerHeaderSkeleton />
            <Flex direction="down" gap="md">
              <Skeleton className="h-72 w-full" />
              <Skeleton className="h-10 w-52" />
              <Skeleton className="h-10 w-96" />
              <Flex gap="md">
                <Skeleton className="h-10 w-32" />
                <Skeleton className="h-10 w-32" />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <>
            <IntelForm
              intel={
                upsertedIntel
                  ? intelResponseToFormData(upsertedIntel as IntelRow)
                  : intelResponseToFormData(intel as IntelRow)
              }
              onSave={handleSave}
              onPublish={handleSave}
              saving={saving}
            />
          </>
        )}
      </Card>
    </>
  );
}
