/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssessmentApolloGradeUpsertParams } from './AssessmentApolloGradeUpsertParams';
export type AssessmentUpsertParams = {
    id?: number;
    phase?: AssessmentUpsertParams.phase;
    playerId?: number;
    position?: AssessmentUpsertParams.position;
    role?: AssessmentUpsertParams.role;
    authorUsername?: string;
    authorName?: string;
    apolloGrades?: Array<AssessmentApolloGradeUpsertParams>;
};
export namespace AssessmentUpsertParams {
    export enum phase {
        PHASE_24_25_0 = 'PHASE_24_25_0',
        PHASE_24_25_1 = 'PHASE_24_25_1',
        PHASE_24_25_2 = 'PHASE_24_25_2',
        PHASE_24_25_3 = 'PHASE_24_25_3',
        PHASE_24_25_4 = 'PHASE_24_25_4',
        PHASE_24_25_5 = 'PHASE_24_25_5',
        PHASE_24_25_6 = 'PHASE_24_25_6',
    }
    export enum position {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum role {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERIOR_DEFENDER = 'INTERIOR_DEFENDER',
    }
}

