import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function intelLoader({
  offset = 0,
  limit = 20,
  playerIds,
  teamIds,
  authorUsername,
  status,
  level,
  tags,
}: {
  offset?: number;
  limit?: number;
  level?: IntelQueryParams.level;
  playerIds?: number[];
  teamIds?: number[];
  authorUsername?: string[];
  status?:
    | IntelQueryParams.status.PUBLISHED
    | IntelQueryParams.status.UNPUBLISHED;
  tags?: string[];
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    status: status,
    teamIds,
    playerIds,
    level,
    authorUsername: authorUsername,
    tags: tags,
  });

  return response;
}

export async function intelByIdLoader({ intelId }: { intelId?: number }) {
  if (!intelId) {
    return null;
  }

  const response = await apolloApi.getIntelById(intelId);

  return response?.intel || null;
}

export async function allIntelAuthorUsernamesLoader() {
  const response = await apolloApi.getIntelAuthors();
  return response;
}
