/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PlayerMetadataScoreRow = {
    id: number;
    playerMetadataId: number;
    grouping: PlayerMetadataScoreRow.grouping;
    key: PlayerMetadataScoreRow.key;
    value: number | null;
    max: number;
};
export namespace PlayerMetadataScoreRow {
    export enum grouping {
        HYBRID = 'HYBRID',
        SCOUT = 'SCOUT',
        ANALYTIC = 'ANALYTIC',
    }
    export enum key {
        SCORING = 'SCORING',
        PR_PLAYMAKING = 'PR_PLAYMAKING',
        PR_SCREENING = 'PR_SCREENING',
        PASSING = 'PASSING',
        FINISHING = 'FINISHING',
        OFFENSIVE_REBOUNDING = 'OFFENSIVE_REBOUNDING',
        CS_SHOOTING = 'CS_SHOOTING',
        OFF_DRIBBLE_SHOOTING = 'OFF_DRIBBLE_SHOOTING',
        MOVEMENT_SHOOTING = 'MOVEMENT_SHOOTING',
        PR_DEFENSE = 'PR_DEFENSE',
        ON_BALL_DEFENSE = 'ON_BALL_DEFENSE',
        DEFENSIVE_VERSATILITY = 'DEFENSIVE_VERSATILITY',
        OFF_BALL_DEFENSE = 'OFF_BALL_DEFENSE',
        DEFENSIVE_REBOUNDING = 'DEFENSIVE_REBOUNDING',
        RIM_PROTECTION = 'RIM_PROTECTION',
        BASKETBALL_IQ = 'BASKETBALL_IQ',
        AGILITY = 'AGILITY',
        VERTICAL_ATHLETICISM = 'VERTICAL_ATHLETICISM',
        DEFEND_POINT_GUARD = 'DEFEND_POINT_GUARD',
        DEFEND_GUARD = 'DEFEND_GUARD',
        DEFEND_WING = 'DEFEND_WING',
        DEFEND_FORWARD = 'DEFEND_FORWARD',
        DEFEND_BIG = 'DEFEND_BIG',
    }
}

