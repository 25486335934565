import { LeagueId, NbaLeagueId } from "@suns/api";

const leagueIdMap: Partial<Record<LeagueId, NbaLeagueId>> = {
  [LeagueId.NBA]: NbaLeagueId.NBA,
  [LeagueId.WNBA]: NbaLeagueId.WNBA,
  [LeagueId.GLEAGUE]: NbaLeagueId.GLEAGUE,
};

export function getLeagueIdFromNbaGameId(nbaGameId: string) {
  const nbaLeagueId = nbaGameId.substring(0, 2) as NbaLeagueId;
  return getLeagueIdFromNbaLeagueId(nbaLeagueId);
}

export function getNbaLeagueIdFromNbaGameId(nbaGameId: string) {
  const leagueId = nbaGameId.substring(0, 2) as NbaLeagueId;
  if (!Object.values(NbaLeagueId).includes(leagueId)) {
    throw Error(`League ID ${leagueId} isn't valid (pulled from ${nbaGameId})`);
  }

  return leagueId;
}

export function getNbaLeagueIdFromLeagueId(apolloLeagueId: LeagueId) {
  if (apolloLeagueId in leagueIdMap) {
    return leagueIdMap[apolloLeagueId] as NbaLeagueId;
  }

  throw new Error(
    `NBA League ID can't be mapped from Apollo league id: ${apolloLeagueId}`
  );
}

export function getLeagueIdFromNbaLeagueId(nbaLeagueId: NbaLeagueId) {
  const index = Object.values(leagueIdMap).indexOf(nbaLeagueId);

  if (index > -1) {
    return Object.keys(leagueIdMap)[index] as unknown as LeagueId;
  }

  throw new Error(
    `Apollo League ID can't be mapped from NBA league id: ${nbaLeagueId}`
  );
}
