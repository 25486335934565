import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { notify } from "../bugsnag";
import { PageError } from "../PageError/PageError";
import { SunsApiError } from "@suns/api";
import { useNavigateLogin } from "@/shared/hooks";

const ErrorFallback = () => {
  const error = useRouteError();
  const authError = (error as SunsApiError)?.authError;
  const navigateLogin = useNavigateLogin();

  useEffect(() => {
    if (error) {
      if (authError) {
        navigateLogin();
      } else {
        notify(error as Error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authError) {
    return null;
  }

  return (
    <PageError
      title="Something went wrong."
      message="We've been alerted that there was an issue. We'll do our best to address the problem in a timely manner. We apologize for any inconvenience."
      error={error as Error}
    />
  );
};

export default ErrorFallback;
