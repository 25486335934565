import { useCallback } from "react";
import { logout } from "@/shared/auth";
import { useLocation, useNavigate } from "react-router-dom";

export function useNavigateLogin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useCallback(
    ({ backToHome }: { backToHome?: boolean } = { backToHome: false }) => {
      logout();

      navigate("/login", {
        state: { from: backToHome ? "/" : pathname },
        replace: true,
      });
    },
    [navigate, pathname]
  );
}
