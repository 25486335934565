import { useEffect, useState } from "react";
import "./box-scores.scss";
import { BoxScoreService, PlayerStats } from "../../swagger";
import Table from "../../components/table/table";
import { TableSkeleton } from "../../components/table-skeleton/table-skeleton";
import { playerStatsColumns } from "./columns";
import BoxScoresFilters from "./component/box-scores-filters";
import {
  FiltersSelectedType,
  Loader,
  BoxScoreAllLoader,
  DisableFiltersFields,
} from "./box-scores-types";
import { useSearchParams } from "react-router-dom";
import { CalculationTypes, SeasonTypes } from "../../types/enums/teamDna.enums";
import { suns, teams } from "../../shared/utils/teams";
import WinLoss from "@/components/boxscore-win-loss/boxscore-win-loss";

const BoxScores = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filtersSelectedData, setFiltersSelectedData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());
  const [selectedFilters, setSelectedFilterData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
  const [playerStatsData, setPlayersStatsData] = useState<Array<PlayerStats>>(
    []
  );
  const [isLoading, setIsLoading] = useState<BoxScoreAllLoader>({
    [Loader.PLAYER_STATS]: true,
  });
  const [initialSort] = useState<{ orderBy: string | null; orderDir: string }>({
    orderBy: "MP",
    orderDir: "desc",
  });
  const disableFiltersFields: DisableFiltersFields = {
    ...new DisableFiltersFields(),
    all: false,
  };

  useEffect(() => {
    const incomingSearchParams = {
      nbaTeamId: searchParams.get("nbaTeamId"),
      seasons: searchParams.get("season"),
      seasonTypes: searchParams.getAll("seasonTypes") as SeasonTypes[],
      noOfGames: searchParams.get("noOfGames"),
      playersInLineup: searchParams.getAll("playersInLineup"),
      playersNotInLineup: searchParams.getAll("playersNotInLineup"),
      calType: searchParams.get("calType"),
      minMinutesplayed: searchParams.get("minMinutesplayed"),
    };
    const calculationTypeLabels = {
      [CalculationTypes.PER_75]: "Per 75",
      [CalculationTypes.PER_GAME]: "Per Game",
      [CalculationTypes.PER_100]: "Per 100",
      [CalculationTypes.TOTAL]: "Total",
    };

    const getTeamById = (nbaTeamId: string | undefined) => {
      if (!nbaTeamId) return null;
      const team =
        teams.find((team) => team.TEAM_ID === parseInt(nbaTeamId)) || suns;
      return {
        value: team.TEAM_ABBR,
        label: team.TEAM_FULL_NAME,
        id: team.TEAM_ID,
      };
    };
    const filterParams = {
      selectedTeam: getTeamById(
        incomingSearchParams.nbaTeamId || `${suns.TEAM_ID}`
      ),
      selectedSeasons: {
        value: incomingSearchParams.seasons || "2024-25",
        label: incomingSearchParams.seasons || "2024-25",
      },
      selectedSeasonTypes: incomingSearchParams.seasonTypes.length
        ? incomingSearchParams.seasonTypes
        : [SeasonTypes.REGULAR_SEASON],
      selectedNoOfGames: {
        value:
          incomingSearchParams.noOfGames === "undefined" ||
          incomingSearchParams.noOfGames === null
            ? undefined
            : parseInt(incomingSearchParams.noOfGames || "0"),
        label:
          incomingSearchParams.noOfGames === "undefined"
            ? "All Games"
            : incomingSearchParams.noOfGames || "All Games",
      },
      playersInLineup: [],
      playersNotInLineup: [],
      selectedCalculationType: {
        value:
          (incomingSearchParams.calType as CalculationTypes) ||
          CalculationTypes.PER_75,
        label:
          calculationTypeLabels[
            incomingSearchParams?.calType as CalculationTypes
          ] || calculationTypeLabels[CalculationTypes.PER_75],
      },

      minimumMinutesPlayed: incomingSearchParams.minMinutesplayed || "1",
    };

    if (searchParams.size > 0) {
      setFiltersSelectedData(filterParams);
    } else {
      setFiltersSelectedData(new FiltersSelectedType());
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      filtersSelectedData &&
      filtersSelectedData.selectedSeasonTypes?.length
    ) {
      getBoxScoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelectedData]);

  const getBoxScoreData = () => {
    setLoaders(Loader.PLAYER_STATS, true);
    BoxScoreService.getPlayerStats(
      filtersSelectedData?.selectedSeasonTypes,
      filtersSelectedData?.selectedTeam?.value || "",
      filtersSelectedData?.selectedCalculationType?.value || "Per_75",
      filtersSelectedData?.selectedSeasons?.value,
      filtersSelectedData?.selectedNoOfGames?.value
    )
      .then(async (response) => {
        setLoaders(Loader.PLAYER_STATS, false);
        setPlayersStatsData(response.data);
      })
      .catch(() => {
        setLoaders(Loader.PLAYER_STATS, false);
      });
  };

  const setLoaders = (type: Loader, value: boolean) => {
    setIsLoading((loader) => {
      loader[type] = value;
      return { ...loader };
    });
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter-head">
          <button
            className="btn sm-btn btn-primary text-normal"
            onClick={() => {
              setIsFiltersVisible(!isFiltersVisible);
            }}
          >
            {isFiltersVisible ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>
      <BoxScoresFilters
        setSearchParams={setSearchParams}
        selectedFilters={selectedFilters}
        setSelectedFilterData={setSelectedFilterData}
        playersInLineupList={[]}
        playersNotInLineupList={[]}
        setFiltersSelectedData={setFiltersSelectedData}
        filtersSelectedData={filtersSelectedData}
        isFiltersVisible={isFiltersVisible}
        tableLoader={isLoading[Loader.PLAYER_STATS]}
        disableFiltersFields={disableFiltersFields}
      ></BoxScoresFilters>
      <div className="common-card">
        <WinLoss filtersSelectedData={filtersSelectedData}></WinLoss>
      </div>
      <div className="common-card">
        <TableSkeleton
          columnsCount={21}
          rowsCount={10}
          outerRowsCount={1}
          fromTeamDNA={false}
          isLoading={isLoading[Loader.PLAYER_STATS]}
        >
          <Table
            columns={playerStatsColumns}
            data={playerStatsData}
            freezeFirstColumn={true}
            isSortable={true}
            initialSort={initialSort}
          />
        </TableSkeleton>
      </div>
    </>
  );
};

export default BoxScores;
