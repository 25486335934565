/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractBonusRow = {
    id: number;
    contractId: number;
    year: number;
    bonusType: ContractBonusRow.bonusType;
    earned: ContractBonusRow.earned;
    amount: number;
    criteria: string;
};
export namespace ContractBonusRow {
    export enum bonusType {
        LKLY = 'LKLY',
        OFFSE = 'OFFSE',
        PERF = 'PERF',
        ULKLY = 'ULKLY',
    }
    export enum earned {
        ERN = 'ERN',
        NTERN = 'NTERN',
        PEND = 'PEND',
    }
}

