import {
  ArrowDown,
  ArrowDown01Icon,
  ArrowDown10Icon,
  ArrowDownAZIcon,
  ArrowDownZAIcon,
  ArrowUp,
  ChevronsUpDownIcon,
} from "lucide-react";
import { flexRender, Header } from "@tanstack/react-table";
import { Button, cn, Flex, Text } from "@suns/design-system";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  header: Header<TData, TValue>;
}

export function DataTableColumnHeader<TData, TValue>({
  header,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!header.column.getCanSort()) {
    return (
      <div className={cn(className)}>
        {flexRender(header.column.columnDef.header, header.getContext())}
      </div>
    );
  }

  return (
    <Flex
      className={cn(
        "flex items-center justify-center space-x-2 pl-3",
        header.index === 0 ? "justify-start pl-2" : "",
        className
      )}
    >
      <Button
        variant="ghost"
        size="sm"
        onClick={() => {
          let nextSort = header.column.getNextSortingOrder();
          if (nextSort == false) {
            nextSort = header.column.getFirstSortDir();
          }
          header.column.toggleSorting(nextSort == "desc");
        }}
        className={cn(
          "-ml-3 h-8 data-[state=open]:bg-accent data-[state=open]:text-foreground",
          header.column.getIsSorted() && "bg-gray-700",
          header.index === 0 ? "pl-2" : ""
        )}
      >
        <Text size="sm">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </Text>
        <SortIcon
          type={header.column.columnDef.sortingFn}
          sort={header.column.getIsSorted()}
        />
      </Button>
    </Flex>
  );
}

function SortIcon({
  type,
  sort,
}: {
  type: string | unknown;
  sort: string | boolean;
}) {
  if (!sort) {
    return <ChevronsUpDownIcon className="ml-2 h-4 w-4" />;
  }

  switch (type) {
    case "text":
      return sort === "desc" ? (
        <ArrowDownZAIcon className="ml-2 h-4 w-4" />
      ) : (
        <ArrowDownAZIcon className="ml-2 h-4 w-4" />
      );
    case "alphanumeric":
      return sort === "desc" ? (
        <ArrowDown10Icon className="ml-2 h-4 w-4" />
      ) : (
        <ArrowDown01Icon className="ml-2 h-4 w-4" />
      );
    default:
      return sort === "desc" ? (
        <ArrowDown className="ml-2 h-4 w-4" />
      ) : (
        <ArrowUp className="ml-2 h-4 w-4" />
      );
  }
}
