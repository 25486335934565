import { useCallback, useEffect } from "react";
import { toast, ToastType } from "@/shared/utils/toaster";

const appVersion = __APP_VERSION__;
const versionCheckInterval = 30 * 1000;

export const APP_VERSION_PATH = "/app-version.json";

export function VersionChecker() {
  const fetchRemoteVersion = useCallback(
    () =>
      fetch(APP_VERSION_PATH, {
        cache: "no-store",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data.version;
        })
        .catch(() => {}),
    []
  );

  useEffect(() => {
    const timer = setInterval(() => {
      fetchRemoteVersion().then((remoteVersion) => {
        if (remoteVersion && remoteVersion !== appVersion) {
          toast(
            ToastType.ERROR,
            "There's a new version of the application. Apollo may not work as expected until you refresh the page.",
            false,
            "bottom-left"
          );
          clearInterval(timer);
        }
      });
    }, versionCheckInterval);

    return () => clearInterval(timer);
  }, [fetchRemoteVersion]);

  return null;
}
