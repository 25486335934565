import {
  DropdownMenu,
  DropdownMenuTrigger,
  Button,
  Flex,
  Separator,
  Badge,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSubTrigger,
  DropdownMenuSub,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  Text,
  DrawerTitle,
  ScrollArea,
} from "@suns/design-system";
import { CheckIcon, PlusCircleIcon } from "@suns/design-system/icons";
import { PlayerColumn } from "../players-listings";
import { VisibilityState } from "@tanstack/react-table";
import { useEffect, useState } from "react";

const sections = [
  {
    label: "General",
    columns: ["currentTeam", "target"],
  },
  {
    label: "Evaluation",
    columns: [
      "grade",
      "gradeValue",
      "analyticGrade",
      "analyticGradeValue",
      "scoutGrade",
      "scoutGradeValue",

      "remainingCapacity",
      "remainingCapacityValue",
      "analyticRemainingCapacity",
      "analyticRemainingCapacityValue",
      "scoutRemainingCapacity",
      "scoutRemainingCapacityValue",

      "position",
      "positionValue",
      "analyticPosition",
      "analyticPositionValue",
      "scoutPosition",
      "scoutPositionValue",

      "role",
      "roleValue",
      "analyticRole",
      "analyticRoleValue",
      "scoutRole",
      "scoutRoleValue",

      "aim",
      "oAim",
      "dAim",

      "ppv",
      "oPpv",
      "dPpv",

      "prv",
    ],
  },
  {
    label: "Player Info",
    columns: [
      "birthDate",
      "height",
      "weight",
      "wing",
      "reach",
      "yearsOfService",
    ],
  },
  // {
  //   label: "Scores",
  //   columns: Object.values(PlayerMetadataScoreRow.key).map((key) => key),
  // },
  {
    label: "Salary",
    columns: [
      "capHit",
      "capHit1Year",
      "capHit2Year",
      "capHit3Year",
      "capHit4Year",
      "aav",
      "unrestrictedFreeAgent",
      "restrictedFreeAgent",
      "playerOption",
      "teamOption",
    ],
  },
];

export default function ColumnFilters({
  columns,
  columnVisibility,
  onChange,
}: {
  columns: PlayerColumn[];
  columnVisibility: VisibilityState;
  onChange: (column: string, checked: boolean) => void;
}) {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const hidden = Object.keys(columnVisibility).filter(
      (column) => columnVisibility[column] === false
    );
    setHiddenColumns(hidden);
  }, [columnVisibility]);

  return (
    <Flex>
      <Flex className="hidden md:flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="dashed" className="bg-white">
              <Flex direction="right" gap="sm" align="center">
                <PlusCircleIcon size={18} />
                Columns
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge className="rounded-sm">
                  {
                    Object.values(columns).filter(
                      (column) =>
                        column.enableHiding !== false &&
                        !hiddenColumns.includes(column.accessorKey)
                    ).length
                  }{" "}
                  Selected
                </Badge>
              </Flex>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-60">
            {sections.map((section) => (
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>{section.label}</DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    {section.columns.map((column) => {
                      const tableColumn = columns.find(
                        (c) => c.accessorKey === column
                      );

                      if (!tableColumn) {
                        return null;
                      }

                      return (
                        <DropdownMenuCheckboxItem
                          key={column}
                          checked={!hiddenColumns.includes(column)}
                          onSelect={(e) => {
                            const checked = hiddenColumns.includes(column);
                            onChange(column, !checked);
                            e.preventDefault();
                          }}
                        >
                          {tableColumn.header}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>

      <Flex className="md:hidden">
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <Button variant="dashed" className="bg-white">
              <Flex direction="right" gap="sm" align="center">
                <PlusCircleIcon size={18} />
                Columns
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge className="rounded-sm">
                  {
                    Object.values(columns).filter(
                      (column) =>
                        column.enableHiding !== false &&
                        !hiddenColumns.includes(column.accessorKey)
                    ).length
                  }{" "}
                  Selected
                </Badge>
              </Flex>
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[60%]">
            <DrawerHeader>
              <DrawerTitle>Columns</DrawerTitle>
            </DrawerHeader>
            <ScrollArea>
              <Flex direction="down" gap="md" className="px-8">
                {sections.map((section) => (
                  <Flex direction="down" gap="md" key={section.label}>
                    <Text size="md" heading>
                      {section.label}
                    </Text>
                    <Separator />
                    {section.columns.map((column) => {
                      const tableColumn = columns.find(
                        (c) => c.accessorKey === column
                      );

                      if (!tableColumn) {
                        return null;
                      }

                      return (
                        <>
                          <Flex
                            key={column}
                            className="pl-2"
                            gap="sm"
                            direction="right"
                            align="center"
                            onClick={() => {
                              const checked = hiddenColumns.includes(column);
                              onChange(column, !checked);
                            }}
                          >
                            {!hiddenColumns.includes(column) ? (
                              <CheckIcon size={22} />
                            ) : (
                              <Flex className="min-w-5" />
                            )}
                            <Text size="lg">{tableColumn.header}</Text>
                          </Flex>
                          <Separator />
                        </>
                      );
                    })}
                  </Flex>
                ))}
              </Flex>
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
}
