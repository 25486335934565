import { AccountInfo } from "@azure/msal-browser";
import { msalInstance } from "./instance";

const Roles = {
  DEFAULT: "Default",
  DEVELOPER: "Developer",
  COACH: "Coach",
  SCOUT: "Scout",
  SCOUT_ADMIN: "ScoutAdmin",
  ADMIN: "Admin",
} as const;

const Flags = {
  BETA_SCOUTING: "BetaScouting",
  BETA_SHINY: "BetaShiny",
} as const;

export type AccountWithRoles = {
  isAuthenticated: boolean;
  primaryRole: (typeof Roles)[keyof typeof Roles];
  isDefault: boolean;
  isDeveloper: boolean;
  isCoach: boolean;
  isScout: boolean;
  isScoutAdmin: boolean;
  isAdmin: boolean;
  hasDefaultAccess: boolean;
  hasDeveloperAccess: boolean;
  hasScoutAccess: boolean;
  hasScoutAdminAccess: boolean;
  hasAdminAccess: boolean;
  hasBetaScoutingAccess: boolean;
  hasBetaShinyAccess: boolean;
  name: string | null;
  username: string | null;
  userId: string | null;
  info: AccountInfo | null;
};

export function getAccount() {
  const [account] = msalInstance.getAllAccounts();
  return formatAccount(account);
}

export function formatAccount(account?: AccountInfo | null): AccountWithRoles {
  if (account === undefined || account === null) {
    return {
      isAuthenticated: false,
      primaryRole: Roles.DEFAULT,
      isDefault: false,
      isDeveloper: false,
      isCoach: false,
      isScout: false,
      isScoutAdmin: false,
      isAdmin: false,
      hasDefaultAccess: false,
      hasDeveloperAccess: false,
      hasScoutAccess: false,
      hasScoutAdminAccess: false,
      hasAdminAccess: false,
      hasBetaScoutingAccess: false,
      hasBetaShinyAccess: false,
      username: null,
      name: null,
      userId: null,
      info: null,
    };
  }

  const roles = account.idTokenClaims?.roles || [];

  const isDeveloper = roles.includes(Roles.DEVELOPER);
  const isDefault = roles.includes(Roles.DEFAULT);
  const isCoach = roles.includes(Roles.COACH);
  const isScout = roles.includes(Roles.SCOUT);
  const isScoutAdmin = roles.includes(Roles.SCOUT_ADMIN);
  const isAdmin = roles.includes(Roles.ADMIN);

  const primaryRole = isDeveloper
    ? Roles.DEVELOPER
    : isAdmin
      ? Roles.ADMIN
      : isScoutAdmin
        ? Roles.SCOUT_ADMIN
        : isCoach
          ? Roles.COACH
          : isScout
            ? Roles.SCOUT
            : Roles.DEFAULT;

  return {
    isAuthenticated: true,
    primaryRole,
    isDeveloper,
    isDefault,
    isCoach,
    isScout,
    isScoutAdmin,
    isAdmin,
    hasDefaultAccess: Object.values(Roles).some((role) => roles.includes(role)),
    hasDeveloperAccess: isDeveloper,
    hasScoutAccess: isDeveloper || isAdmin || isScoutAdmin || isScout,
    hasScoutAdminAccess: isDeveloper || isAdmin || isScoutAdmin,
    hasAdminAccess: isDeveloper || isAdmin,
    hasBetaScoutingAccess: isDeveloper || roles.includes(Flags.BETA_SCOUTING),
    hasBetaShinyAccess: isDeveloper || roles.includes(Flags.BETA_SHINY),
    username: account.username,
    name: formatAccountName(account.name),
    userId: userId(account.username),
    info: account,
  };
}

function formatAccountName(name?: string) {
  if (!name) {
    return "";
  }

  if (!name.includes(",")) {
    return name;
  }

  return name.split(",").splice(1).join(" ").trim();
}

function userId(username?: string) {
  if (!username || username.length <= 2) {
    return null;
  }

  let hash = 0;
  for (let i = 0; i < username.length; i++) {
    const chr = username.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return `${username.substring(0, 2)}${Math.abs(hash)}`;
}
