import { ApolloGrade } from "@suns/api";
import { theme } from "../theme";
import { apolloGradeFromNumericalValue } from "./apollo-grades";

export const colors = {
  purple: "#6038C4",
  orange: "#fa4b0a",
  yellow: "#ff9e1a",
  darkOrange: "#d1441d",
  xihuitl: "#00bfdd",
  bugambilia: "#ae1857",
  tixinda: "#410099",
};

export const apolloGradeColors = {
  [ApolloGrade.FRANCHISE]: {
    class: "bg-amber-400 border-amber-400",
    hex: theme.colors.amber["400"],
  },
  [ApolloGrade.ALL_STAR_PLUS]: {
    class: "bg-amber-500 border-amber-500",
    hex: theme.colors.amber["500"],
  },
  [ApolloGrade.ALL_STAR]: {
    class: "bg-orange-400 border-orange-400",
    hex: theme.colors.orange["400"],
  },
  [ApolloGrade.STARTER]: {
    class: "bg-orange-500 border-orange-500",
    hex: theme.colors.orange["500"],
  },
  [ApolloGrade.TOP_ROTATION]: {
    class: "bg-orange-600 border-orange-600",
    hex: theme.colors.orange["600"],
  },
  [ApolloGrade.ROTATION]: {
    class: "bg-fuchsia-700 border-fuchsia-700",
    hex: theme.colors.fuchsia["700"],
  },
  [ApolloGrade.ROSTER]: {
    class: "bg-purple-700 border-purple-700",
    hex: theme.colors.purple["700"],
  },
  [ApolloGrade.MINOR_LEAGUE]: {
    class: "bg-purple-900 border-purple-900",
    hex: theme.colors.purple["900"],
  },
} satisfies Record<ApolloGrade, { class: string; hex: string }>;

export function apolloGradeColor(
  gradeNumber: number,
  type: "class" | "hex" = "class"
) {
  const grade = apolloGradeFromNumericalValue(gradeNumber);
  return apolloGradeColors[grade][type];
}
