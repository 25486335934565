/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportApolloGradeUpsertParams } from './ReportApolloGradeUpsertParams';
import type { ReportPlayerAttributeUpsertParams } from './ReportPlayerAttributeUpsertParams';
import type { ReportScoreUpsertParams } from './ReportScoreUpsertParams';
export type ReportUpsertParams = {
    id?: number;
    playerId?: number;
    teamId?: number;
    status?: ReportUpsertParams.status;
    level?: ReportUpsertParams.level;
    type?: ReportUpsertParams.type;
    scoutLocation?: ReportUpsertParams.scoutLocation;
    position?: ReportUpsertParams.position;
    role?: ReportUpsertParams.role;
    teamFit?: ReportUpsertParams.teamFit;
    teamFitNotes?: string;
    offensiveNotes?: string;
    defensiveNotes?: string;
    otherNotes?: string;
    authorUsername?: string;
    authorName?: string;
    phaseReportId?: number;
    didNotPlay?: boolean;
    apolloGrades?: Array<ReportApolloGradeUpsertParams>;
    playerAttributes?: Array<ReportPlayerAttributeUpsertParams>;
    scores?: Array<ReportScoreUpsertParams>;
    reportIds?: Array<number>;
    gameIds?: Array<number>;
    phase?: ReportUpsertParams.phase;
};
export namespace ReportUpsertParams {
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum type {
        PHASE = 'PHASE',
        GENERAL = 'GENERAL',
    }
    export enum scoutLocation {
        ON_SITE = 'ON_SITE',
        VIDEO = 'VIDEO',
    }
    export enum position {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum role {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERIOR_DEFENDER = 'INTERIOR_DEFENDER',
    }
    export enum teamFit {
        YES = 'YES',
        NO = 'NO',
        NA = 'NA',
    }
    export enum phase {
        PHASE_24_25_0 = 'PHASE_24_25_0',
        PHASE_24_25_1 = 'PHASE_24_25_1',
        PHASE_24_25_2 = 'PHASE_24_25_2',
        PHASE_24_25_3 = 'PHASE_24_25_3',
        PHASE_24_25_4 = 'PHASE_24_25_4',
        PHASE_24_25_5 = 'PHASE_24_25_5',
        PHASE_24_25_6 = 'PHASE_24_25_6',
    }
}

