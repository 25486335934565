/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentRow } from './AgentRow';
import type { ContractBonusRow } from './ContractBonusRow';
import type { ContractFlagRow } from './ContractFlagRow';
import type { ContractProtectionRow } from './ContractProtectionRow';
import type { ContractSalaryRow } from './ContractSalaryRow';
import type { PlayerRow } from './PlayerRow';
import type { TeamRow } from './TeamRow';
export type ContractRow = {
    id: number;
    playerId: number;
    player: PlayerRow;
    agentId: number;
    agent: AgentRow;
    pcmsId: number;
    status: ContractRow.status;
    contractFlags: ContractFlagRow;
    contractSalaries: Array<ContractSalaryRow>;
    contractProtections: Array<ContractProtectionRow>;
    contractBonuses: Array<ContractBonusRow>;
    currentTeam: TeamRow;
    signingTeamId: number;
    signingTeam: TeamRow;
    signingDate: string;
    signAndTradeTeamId: number;
    signAndTradeTeam: TeamRow;
    signedMethod: ContractRow.signedMethod;
    startYear: number;
    lengthOfYears: number;
    endDate: string;
    type: ContractRow.type;
    maxContract: ContractRow.maxContract;
    minContract: ContractRow.minContract;
    freeAgentDesignation: ContractRow.freeAgentDesignation;
    freeAgentStatus: ContractRow.freeAgentStatus;
    playerConsent: ContractRow.playerConsent;
    internationalPlayerPayment: number;
    poisonPillAmount: number;
    tradeBonusAmount: number;
    tradeBonusPercent: number;
    tradeRestriction: ContractRow.tradeRestriction;
    paymentSchedule: ContractRow.paymentSchedule;
    twoWayServiceLimit: number;
    comments: string;
    bonusComments: string;
    protectionComments: string;
    salaryComments: string;
    vendorUpdatedAt: string;
};
export namespace ContractRow {
    export enum status {
        ACT = 'ACT',
        APPR = 'APPR',
        CANC = 'CANC',
        CEF = 'CEF',
        CER = 'CER',
        CES = 'CES',
        CETE = 'CETE',
        COMP = 'COMP',
        DEL = 'DEL',
        EXP = 'EXP',
        FLPHY = 'FLPHY',
        FUTR = 'FUTR',
        IN = 'IN',
        MATCH = 'MATCH',
        NMTCH = 'NMTCH',
        PRE = 'PRE',
        REJ = 'REJ',
        REL = 'REL',
        REPL = 'REPL',
        RESC = 'RESC',
        SALCH = 'SALCH',
        SENT = 'SENT',
        SUB = 'SUB',
        TERM = 'TERM',
        UN = 'UN',
        VOID = 'VOID',
        WDRAW = 'WDRAW',
    }
    export enum signedMethod {
        BRD = 'BRD',
        MTP = 'MTP',
        MRM = 'MRM',
        BAE = 'BAE',
        DPE = 'DPE',
        ROM = 'ROM',
        RFP = 'RFP',
        RMN = 'RMN',
        DPKSE = 'DPKSE',
        KSE = 'KSE',
        VEE = 'VEE',
        EBE = 'EBE',
        NBE = 'NBE',
        MSEF = 'MSEF',
        MSERO = 'MSERO',
        MSE10 = 'MSE10',
        KSX = 'KSX',
        MEP = 'MEP',
        MEN = 'MEN',
        MINEX = 'MINEX',
        SM150 = 'SM150',
        SM125 = 'SM125',
        SM5M = 'SM5M',
        TRDEX = 'TRDEX',
        SIM = 'SIM',
        MINSL = 'MINSL',
        CNTMD = 'CNTMD',
        NA = 'NA',
        SM175 = 'SM175',
        _2RDPE = '2RDPE',
        NONE = 'NONE',
    }
    export enum type {
        _10D1 = '10D1',
        _10D2 = '10D2',
        QO = 'QO',
        REGCT = 'REGCT',
        REGOS = 'REGOS',
        REQSM = 'REQSM',
        REQTN = 'REQTN',
        RKSC = 'RKSC',
        ROS = 'ROS',
        RSEX = 'RSEX',
        SUMM = 'SUMM',
        REGSM = 'REGSM',
        VETEX = 'VETEX',
        MOS = 'MOS',
        OFFSH = 'OFFSH',
        W7D1 = 'W7D1',
        W7D2 = 'W7D2',
        W7D3 = 'W7D3',
        WCEX = 'WCEX',
        WCORE = 'WCORE',
        WHARD = 'WHARD',
        WMOS = 'WMOS',
        WOFFS = 'WOFFS',
        WRGCT = 'WRGCT',
        WRGOS = 'WRGOS',
        WRGTC = 'WRGTC',
        WRKSC = 'WRKSC',
        WRKTC = 'WRKTC',
        WROS = 'WROS',
        WRQTN = 'WRQTN',
        WTRCC = 'WTRCC',
        TMREG = 'TMREG',
        TM10D = 'TM10D',
        TMSUM = 'TMSUM',
        TMRSE = 'TMRSE',
        TMVTE = 'TMVTE',
        TMRSM = 'TMRSM',
        _2WCT = '2WCT',
        REG2W = 'REG2W',
        REGCV = 'REGCV',
        TM2W = 'TM2W',
        TM2RG = 'TM2RG',
        TM2RC = 'TM2RC',
        SUB = 'SUB',
        WRGRS = 'WRGRS',
    }
    export enum maxContract {
        FULL = 'FULL',
        _1ST = '1ST',
        NO = 'NO',
    }
    export enum minContract {
        _3PLS = '3PLS',
        _1OR2 = '1OR2',
        NO = 'NO',
    }
    export enum freeAgentDesignation {
        QUAL = 'QUAL',
        ERQL = 'ERQL',
        NONQL = 'NONQL',
        NONE = 'NONE',
    }
    export enum freeAgentStatus {
        UFA = 'UFA',
        RFA = 'RFA',
        NONE = 'NONE',
    }
    export enum playerConsent {
        NONE = 'NONE',
        NOTEN = 'NOTEN',
        ROFRE = 'ROFRE',
        YEARK = 'YEARK',
    }
    export enum tradeRestriction {
        DEC15 = 'DEC15',
        JAN15 = 'JAN15',
        _30DAY = '30DAY',
        NOTEN = 'NOTEN',
        EX6MO = 'EX6MO',
        EXNON = 'EXNON',
        NA = 'NA',
        VETDP = 'VETDP',
    }
    export enum paymentSchedule {
        _12SM = '12SM',
        _24SM = '24SM',
        _36SM = '36SM',
        NOTEN = 'NOTEN',
        OTH = 'OTH',
    }
}

