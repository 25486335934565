import dayjs from "dayjs";

const nbaFinalsDates = {
  "2023-24": "2024-06-17",
  "2022-23": "2023-06-12",
  "2021-22": "2022-06-16",
  "2020-21": "2021-07-20",
  "2019-20": "2020-10-11",
  "2018-19": "2019-06-13",
  "2017-18": "2018-06-08",
  "2016-17": "2017-06-12",
  "2015-16": "2016-06-19",
  "2014-15": "2015-06-16",
  "2013-14": "2014-06-15",
  "2012-13": "2013-06-20",
  "2011-12": "2012-06-21",
  "2010-11": "2011-06-12",
  "2009-10": "2010-06-17",
  "2008-09": "2009-06-14",
  "2007-08": "2008-06-17",
  "2006-07": "2007-06-14",
  "2005-06": "2006-06-20",
};
export function nbaSeasonFromDate(date: string) {
  const previousFinalsDate = Object.values(nbaFinalsDates).find(
    (finalsDate) => {
      return dayjs(date).isAfter(dayjs(finalsDate));
    }
  );

  const yearToParse = previousFinalsDate
    ? dayjs(previousFinalsDate).format("YY")
    : dayjs(date).format("YY");
  const year = Number(yearToParse);
  return `20${year}-${year + 1}`;
}
