import { useAccount, useAsync } from "@/shared/hooks";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { Page, PaginationControl } from "@/components";
import { IntelListItem } from "./components/IntelListItem";
import { SunsApiError } from "@suns/api";

import { useSearchParams } from "react-router-dom";
import { defaultFilterValues } from "@/shared/const";
import ReportFilters from "@/pages/reports/reports-listings/components/ReportFilters";
import { FilterValues } from "@/pages/reports/reports-listings/all-reports-listings";
import { PlayerLevel } from "../intel-create-player-team/tags";
import { intelLoader } from "../loaders/intel-loaders";

const PAGE_SIZE = 20;

export function IntelListings() {
  const account = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamIds: searchParams.getAll("teamIds")?.map(Number) || undefined,
    playerId: Number(searchParams.get("playerId")) || undefined,
    publishedPage: Number(searchParams.get("publishedPage")) || undefined,
    unPublishedPage: Number(searchParams.get("unPublishedPage")) || undefined,
    tab: searchParams.get("tab") || undefined,
  });

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const [publishedPage, setPublishedPage] = useState(
    filterValues.publishedPage ? filterValues.publishedPage - 1 : 0
  );
  const [unpublishedPage, setUnpublishedPage] = useState(
    filterValues.unPublishedPage ? filterValues.unPublishedPage - 1 : 0
  );

  const {
    loading: publishedLoading,
    response: publishedResponse,
    error: publishedError,
    refresh: refreshPublished,
  } = useAsync(intelLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: IntelQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerIds: filterValues.playerId ? [filterValues.playerId] : undefined,
  });

  const {
    loading: unpublishedLoading,
    response: unpublishedResponse,
    error: unpublishedError,
    refresh: refreshUnpublished,
  } = useAsync(intelLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: IntelQueryParams.status.UNPUBLISHED,
    offset: unpublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerIds: filterValues.playerId ? [filterValues.playerId] : undefined,
  });

  if (publishedError) {
    throw new SunsApiError("Unable to load intel.", { cause: publishedError });
  }

  if (unpublishedError) {
    throw new SunsApiError("Unable to load intel.", {
      cause: unpublishedError,
    });
  }

  const currentPublishedPage = Math.floor(
    (publishedResponse?.offset || 0) / (publishedResponse?.limit || 1)
  );

  const currentUnpublishedPage = Math.floor(
    (unpublishedResponse?.offset || 0) / (unpublishedResponse?.limit || 1)
  );

  useEffect(() => {
    if (publishedPage !== currentPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshPublished();
    }

    if (unpublishedPage !== currentUnpublishedPage) {
      window.scrollTo({ top: 0 });
      refreshUnpublished();
    }
  }, [
    publishedPage,
    currentPublishedPage,
    refreshPublished,
    unpublishedPage,
    currentUnpublishedPage,
    refreshUnpublished,
  ]);

  const handleChangeFilterValues = (value: FilterValues) => {
    setPublishedPage(0);
    setUnpublishedPage(0);
    const updatedFilters = { ...filterValues, ...value };
    setFilterValues(updatedFilters);

    const params = new URLSearchParams();

    const { level, ...rest } = updatedFilters;

    Object.entries(rest).forEach(([key, values]) => {
      if (values == null) return;

      if (Array.isArray(values)) {
        values.forEach((val) => val && params.append(key, val.toString()));
      } else {
        params.append(key, values.toString());
      }
    });

    setSearchParams(params);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);

    if (urlParams.publishedPage) {
      setPublishedPage(urlParams.publishedPage - 1);
    } else {
      setPublishedPage(0);
    }

    if (urlParams.unPublishedPage) {
      setUnpublishedPage(urlParams.unPublishedPage - 1);
    } else {
      setUnpublishedPage(0);
    }

    if (urlParams.tab) {
      setFilterValues((prev) => ({ ...prev, tab: urlParams.tab }));
    } else {
      setFilterValues((prev) => ({ ...prev, tab: "published" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeTab = (tab: string) => {
    setFilterValues((prev) => ({ ...prev, tab }));

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      teamIds: filterValues.teamIds?.map((id) => id.toString()) || [],
      tab: tab,
    });
  };

  const handleSetPage = (page: number) => {
    if (filterValues.tab === "published") {
      setPublishedPage(page - 1);
    } else {
      setUnpublishedPage(page - 1);
    }
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [filterValues.tab === "published" ? "publishedPage" : "unPublishedPage"]:
        page.toString(),
    });
    window.scrollTo({ top: 0 });
  };

  return (
    <Page title="My Intel" breadcrumbs={false}>
      <Card
        header={
          <Text size="xl" heading>
            My Intel
          </Text>
        }
      >
        <Tabs onValueChange={handleChangeTab} value={filterValues.tab}>
          <TabsList>
            <TabsTrigger value="published">
              <Text>Published</Text>
            </TabsTrigger>
            <TabsTrigger value="unpublished">
              <Text>Unpublished</Text>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="published">
            <ReportFilters
              type="myReportsIntel"
              onChange={handleChangeFilterValues}
              onClear={handleClear}
              filterValues={filterValues}
            />
            {publishedLoading ? (
              <IntelListingsLoading />
            ) : (
              <>
                {publishedResponse && publishedResponse?.intel?.length > 0 ? (
                  <Flex direction="down" className="divide-y-2">
                    {publishedResponse?.intel.map((intel) => (
                      <IntelListItem
                        key={intel.id}
                        className="py-4"
                        intel={intel}
                      />
                    ))}
                  </Flex>
                ) : (
                  <Text className="py-10 text-center" size="lg" muted>
                    No intel found.
                  </Text>
                )}
                <PaginationControl
                  {...(publishedResponse || {
                    offset: 0,
                    limit: PAGE_SIZE,
                    count: 0,
                  })}
                  onPageClicked={(page) => {
                    handleSetPage(page);
                  }}
                />
              </>
            )}
          </TabsContent>
          <TabsContent value="unpublished">
            <ReportFilters
              type="myReportsIntel"
              onChange={handleChangeFilterValues}
              onClear={handleClear}
              filterValues={filterValues}
            />
            {unpublishedLoading ? (
              <IntelListingsLoading />
            ) : (
              <>
                {unpublishedResponse &&
                unpublishedResponse?.intel?.length > 0 ? (
                  <Flex direction="down" className="divide-y-2">
                    {unpublishedResponse?.intel.map((intel) => (
                      <IntelListItem
                        key={intel.id}
                        className="py-4"
                        intel={intel}
                      />
                    ))}
                  </Flex>
                ) : (
                  <Text className="py-10 text-center" size="lg" muted>
                    No intel found.
                  </Text>
                )}
                <PaginationControl
                  {...(unpublishedResponse || {
                    offset: 0,
                    limit: PAGE_SIZE,
                    count: 0,
                  })}
                  onPageClicked={(page) => {
                    handleSetPage(page);
                  }}
                />
              </>
            )}
          </TabsContent>
        </Tabs>
      </Card>
    </Page>
  );
}

function IntelListingsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
