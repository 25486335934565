import { RouteObject } from "react-router-dom";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import TeamDNA from "@/pages/team-dna/team-dna/team-dna";
import { getFilterData } from "@/shared/utils/helper-functions";
import { protectedLoader } from "@/shared/utils";

export const TeamDnaRoutes = {
  path: "suns/team",
  children: [
    {
      index: true,
      element: <TeamDNA />,
      loader: protectedLoader(getFilterData),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;
