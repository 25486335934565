import {
  ApolloGradeValueLabels,
  ApolloGradeValueShortLabels,
} from "@/pages/reports/reports-const";
import { colors } from "@/shared/utils/colors";
import { ApolloGrade } from "@suns/api";
import {
  PlayerMetadataApolloGradeRow,
  PlayerMetadataRow,
} from "@suns/api/generated-client/apollo";
import { Card, Flex, Grid, Text, ApolloBadge } from "@suns/design-system";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BarChart2Icon,
  UserIcon,
} from "@suns/design-system/icons";
import { Fragment } from "react";

const CARD_WIDTH = 180;

export function computeChartPositions(
  currentGrade: PlayerMetadataApolloGradeRow,
  remainingCapacity: PlayerMetadataApolloGradeRow
) {
  let min = Math.min(
    currentGrade.numericalValue,
    remainingCapacity.numericalValue
  );
  let max = Math.max(
    currentGrade.numericalValue,
    remainingCapacity.numericalValue
  );

  if (currentGrade.value === remainingCapacity.value) {
    max = Math.ceil(max);
    min = Math.floor(min);
  }

  const left = min <= 1 ? 0 : ((min - 1) / 8) * 100;
  const right = max > 8 ? 100 : ((max - 1) / 8) * 100;

  return { left, right };
}

export default function ApolloGradeChart({
  metadata,
}: {
  metadata: PlayerMetadataRow;
}) {
  if (!metadata.hybridGradeCurrent || !metadata.hybridGradeRemainingCapacity) {
    return null;
  }

  const { left, right } = computeChartPositions(
    metadata.hybridGradeCurrent,
    metadata.hybridGradeRemainingCapacity
  );

  const apolloGradeCard = (
    <LegendCard
      title="Apollo Grade"
      hybridGradeValue={metadata.hybridGradeCurrent?.value}
      analyticNumericalValue={metadata.analyticGradeCurrent?.numericalValue}
      scoutNumericalValue={metadata.scoutGradeCurrent?.numericalValue}
    />
  );

  const remainingCapacityCard = (
    <LegendCard
      title="Remaining Capacity"
      hybridGradeValue={metadata.hybridGradeRemainingCapacity?.value}
      scoutNumericalValue={metadata.scoutGradeRemainingCapacity?.numericalValue}
    />
  );

  const leftCard =
    metadata.hybridGradeCurrent?.numericalValue <
    metadata.hybridGradeRemainingCapacity?.numericalValue
      ? apolloGradeCard
      : remainingCapacityCard;

  const rightCard =
    metadata.hybridGradeCurrent?.numericalValue <
    metadata.hybridGradeRemainingCapacity?.numericalValue
      ? remainingCapacityCard
      : apolloGradeCard;

  const barWidthPercent = right - left;
  const requiredLegendWidth = `calc(${barWidthPercent}% + ${CARD_WIDTH}px)`;
  const minLegendWidth = CARD_WIDTH * 2 - 20;
  const legendWidth = `max(${requiredLegendWidth}, ${minLegendWidth}px)`;

  return (
    <Flex direction="down" gap="md">
      <Flex direction="down" gap="sm">
        <Flex direction="right" className="w-full">
          <Flex
            direction="right"
            justify="between"
            gap="sm"
            style={{
              width: legendWidth,
              minWidth: minLegendWidth,
              marginLeft: `max(0px, min(calc(${left}% + ${barWidthPercent}% / 2 - (${legendWidth} / 2)), calc(100% - ${minLegendWidth}px)))`,
            }}
          >
            {leftCard}
            {rightCard}
          </Flex>
        </Flex>

        <Flex direction="down" gap="none">
          <div className="relative h-8 w-full rounded-md bg-gray-300">
            <Flex
              direction="right"
              justify="evenly"
              className="absolute left-[1px] right-0 h-full"
            >
              {[...Array(7)].map((_, i) => (
                <div key={i} className="h-full w-[1px] bg-gray-400" />
              ))}
            </Flex>
            <div
              className="absolute left-0 right-0 h-full rounded-md bg-gradient-to-r from-secondary-600
                via-orange-500 to-yellow-500"
              style={{
                clipPath: `inset(0 ${100 - right}% 0 ${left}% round 6px)`,
              }}
            ></div>
            <div
              className="absolute flex h-full items-center justify-center opacity-75"
              style={{
                left: `${left}%`,
                width: `${(right - left).toFixed(2)}%`,
              }}
            >
              {metadata.hybridGradeCurrent.numericalValue <
              metadata.hybridGradeRemainingCapacity.numericalValue ? (
                <>
                  <div className="-mr-2 ml-2 h-[2px] max-w-[30%] flex-grow bg-white"></div>
                  <ArrowRightIcon size={26} color="white" />
                </>
              ) : (
                <>
                  <ArrowLeftIcon size={26} color="white" />
                  <div className="-ml-2 mr-2 h-[2px] max-w-[40%] flex-grow bg-white"></div>
                </>
              )}
            </div>
          </div>
          <Grid className="grid-cols-8 gap-0 divide-x text-center">
            {Object.keys(ApolloGrade)
              .reverse()
              .map((grade, i) => {
                const active =
                  grade === metadata.hybridGradeCurrent?.value ||
                  grade === metadata.hybridGradeRemainingCapacity?.value;
                return (
                  <Fragment key={`grade-${grade}-${i}`}>
                    <Text
                      size="sm"
                      heading={active}
                      muted={!active}
                      className="col-span-1 hidden pt-1 md:block"
                    >
                      {
                        ApolloGradeValueLabels[
                          grade as keyof typeof ApolloGradeValueLabels
                        ]
                      }
                    </Text>
                    <Text
                      size="sm"
                      heading={active}
                      muted={!active}
                      className="col-span-1 pt-1 md:hidden"
                    >
                      {
                        ApolloGradeValueShortLabels[
                          grade as keyof typeof ApolloGradeValueShortLabels
                        ]
                      }
                    </Text>
                  </Fragment>
                );
              })}
          </Grid>
        </Flex>
      </Flex>
    </Flex>
  );
}

function LegendCard({
  title,
  hybridGradeValue,
  analyticNumericalValue,
  scoutNumericalValue,
}: {
  title: string;
  hybridGradeValue: ApolloGrade;
  analyticNumericalValue?: number;
  scoutNumericalValue?: number;
}) {
  return (
    <Card className="h-16 w-[200px] p-3">
      <Flex direction="right" gap="xs" justify="between" className="h-full">
        <Flex direction="down" gap="xs" justify="between">
          <Text size="xs" muted>
            {title}
          </Text>
          <Text size="xs" heading>
            {ApolloGradeValueLabels[hybridGradeValue]}
          </Text>
        </Flex>

        <Flex direction="right" gap="sm">
          {analyticNumericalValue && (
            <Flex direction="down" gap="none" justify="between">
              <ApolloBadge
                size={22}
                color={colors.bugambilia}
                icon={BarChart2Icon}
              />
              <Text size="xs" heading>
                {analyticNumericalValue.toFixed(1)}
              </Text>
            </Flex>
          )}
          {scoutNumericalValue && (
            <Flex direction="down" gap="none" justify="between">
              <ApolloBadge size={22} color={colors.xihuitl} icon={UserIcon} />
              <Text size="xs" heading>
                {scoutNumericalValue.toFixed(1)}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
