import { Flex, Grid, Text } from "@suns/design-system";
import React from "react";

type PlayerDetailItem = {
  label: string;
  value: string | React.ReactNode;
  onClick?: () => void;
};

export function PlayerDetails({
  title,
  items,
}: {
  title?: string;
  items: PlayerDetailItem[];
}) {
  return (
    <Flex direction="down" gap="sm" className="flex-grow">
      {title && (
        <Text size="md" heading variant="muted">
          {title}
        </Text>
      )}
      <Grid className="grid-cols-[30%_1fr]" gap="sm">
        {items.map((item) => (
          <React.Fragment key={item.label}>
            <dt>
              <Text size="sm" muted>
                {item.label}
              </Text>
            </dt>
            <dd>
              <Text size="sm" heading>
                {item.value}
              </Text>
            </dd>
          </React.Fragment>
        ))}
      </Grid>
    </Flex>
  );
}
