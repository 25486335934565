import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { playersReportsLoader } from "../loaders/player-loader";
import { useAsync } from "@/shared/hooks/useAsync";
import { useParams } from "react-router-dom";
import { SunsApiError } from "@suns/api";
import { ReportsList } from "@/components";

export function PlayersReportListings({
  reportType,
  authorUsername,
}: {
  reportType: string;
  authorUsername: string[];
}) {
  const PAGE_SIZE = 10;
  const { playerId } = useParams();
  const [publishedPage, setPublishedPage] = useState(0);

  const {
    loading: reportsLoading,
    response: reportsResponse,
    refresh: refreshReports,
    error: reportsError,
  } = useAsync(playersReportsLoader, {
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    status: ReportQueryParams.status.PUBLISHED,
    playerId: playerId ? Number(playerId) : undefined,
    type:
      reportType === "All" ? undefined : (reportType as ReportQueryParams.type),
    authorUsername,
  });

  const handleSetPage = (page: number) => {
    setPublishedPage(page - 1);
  };

  const currentPublishedPage = Math.floor(
    (reportsResponse?.offset || 0) / (reportsResponse?.limit || 1)
  );

  useEffect(() => {
    if (publishedPage !== currentPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshReports();
    }
  }, [publishedPage, currentPublishedPage, refreshReports]);

  if (reportsError) {
    throw new SunsApiError("Error loading the published reports.", {
      cause: reportsError,
    });
  }

  return (
    <ReportsList
      loading={reportsLoading}
      response={
        reportsResponse || {
          reports: [],
          offset: 0,
          limit: PAGE_SIZE,
          count: 0,
          sortColumn: "createdAt",
          sortDir: "DESC",
        }
      }
      setPage={handleSetPage}
      hideListItemHeader={true}
    />
  );
}
