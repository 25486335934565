import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, cn, Flex, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import {
  PlayerMetadataRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import dayjs from "dayjs";
import { Sheet, Page, TeamLogo } from "@/components";
import { LoaderCircle, SlidersVerticalIcon } from "lucide-react";
import { PlayerFilters } from "./components";
import ColumnFilters from "./components/ColumnFilters";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
  ReportScoreDescriptions,
} from "../reports/reports-const";
import { apolloGradeColor } from "@/shared/utils/colors";
import {
  formatMoney,
  numericalApolloGrade,
  plusMinus,
} from "@/shared/utils/helper-functions";
import { PlayerMetadataScoreRow } from "@/swagger";
import { playersLoader } from "./loaders/player-loader";
import { CURRENT_SEASON } from "@/shared/const";
import CsvDownloader from "react-csv-downloader";
import { apolloApi } from "@/shared/api";
import { toast } from "@/shared/utils/toaster";
import { ToastType } from "@/shared/utils/toaster";
import { SunsApiError } from "@suns/api/src/error";

export type PlayerColumn = ColumnDef<PlayerRow> & {
  accessorKey: string;
  header: string;
  sortingFn?: "alphanumeric" | "text";
  csvFn?: (row: PlayerRow) => string | number | null;
};

const columns: PlayerColumn[] = [
  {
    header: "Player",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.lastName + ", " + row.firstName,
    enableHiding: false,
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.firstName + " " + row.lastName,
  },
  {
    header: "Team",
    accessorKey: "currentTeam",
    cell: (cell) => {
      const teams = cell.row.original.currentTeams;
      return (
        <Flex direction="right" align="center" justify="center" gap="sm">
          {teams?.map((team, i) => (
            <Flex direction="right" align="center" gap="xs" key={team.id}>
              <TeamLogo
                src={team.image}
                nbaTeamId={team.nbaId || team.id}
                leagueId={team.domesticLeagueId}
                size="xxxs"
              />
              <Text size="sm" key={team.id} className="text-nowrap">
                {team.code}
                {i < teams.length - 1 ? "," : ""}
              </Text>
            </Flex>
          ))}
        </Flex>
      );
    },
    enableSorting: true,
    sortDescFirst: false,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.currentTeams.map((team) => team.code).join(", "),
  },
  {
    header: "High School",
    accessorKey: "highschool",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.highschool ?? "--",
  },
  {
    header: "Home Town",
    accessorKey: "homeTown",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeTown ?? "--",
  },
  {
    header: "Home State",
    accessorKey: "homeState",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeState ?? "--",
  },
  {
    header: "Home Country",
    accessorKey: "homeCountryCode",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeCountryCode ?? "--",
  },
  {
    header: "Nationality",
    accessorKey: "nationalityCountryCode",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.nationalityCountryCode ?? "--",
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
  },
  {
    header: "Years of Service",
    accessorKey: "yearsOfService",
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.yearsOfService?.toString() ?? "--",
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (!row.height) return "--";
      const inches = Number(row.height);
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.height
        ? `${Math.floor(Number(row.height) / 12)}' ${Number(row.height) % 12}"`
        : "--",
  },
  {
    header: "Weight",
    accessorKey: "weight",
    accessorFn: (row: PlayerRow) => (row.weight ? `${row.weight} lbs` : "--"),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.weight?.toString() ?? "--",
  },
  {
    header: "Wing",
    accessorKey: "wing",
    accessorFn: (row: PlayerRow) => {
      if (!row.wing) return "--";

      const feet = Math.floor(row.wing / 12);
      const remainingInches = row.wing % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.wing ? `${Math.floor(row.wing / 12)}' ${row.wing % 12}` : "--",
  },
  {
    header: "Reach",
    accessorKey: "reach",
    accessorFn: (row: PlayerRow) => {
      if (!row.reach) return "--";

      const feet = Math.floor(row.reach / 12);
      const remainingInches = row.reach % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.reach ? `${Math.floor(row.reach / 12)}' ${row.reach % 12}` : "--",
  },
  {
    header: "Target",
    accessorKey: "target",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
  },
  {
    header: "Apollo Grade",
    accessorKey: "grade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.hybridGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].hybridGradeCurrent.value]
        : "--",
  },
  {
    header: "Apollo Grade Value",
    accessorKey: "gradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(row.metadata[0]?.hybridGradeCurrent?.numericalValue),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeCurrent?.numericalValue
        ? row.metadata[0].hybridGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Analytic Grade",
    accessorKey: "analyticGrade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.analyticGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.analyticGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].analyticGradeCurrent.value]
        : "--",
  },
  {
    header: "Analytic Grade Value",
    accessorKey: "analyticGradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(
        row.metadata[0]?.analyticGradeCurrent?.numericalValue
      ),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.analyticGradeCurrent?.numericalValue
        ? row.metadata[0].analyticGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Scout Grade",
    accessorKey: "scoutGrade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.scoutGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].scoutGradeCurrent.value]
        : "--",
  },
  {
    header: "Scout Grade Value",
    accessorKey: "scoutGradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(row.metadata[0]?.scoutGradeCurrent?.numericalValue),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeCurrent?.numericalValue
        ? row.metadata[0].scoutGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Remaining Capacity",
    accessorKey: "remainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].hybridGradeRemainingCapacity.value
          ]
        : "--",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].hybridGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Scout Remaining Capacity",
    accessorKey: "scoutRemainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].scoutGradeRemainingCapacity.value
          ]
        : "--",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].scoutGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Position",
    accessorKey: "position",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
  },
  {
    header: "Scout Position",
    accessorKey: "scoutPosition",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutPosition
        ? ReportPositionLabels[row.metadata[0].scoutPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutPosition
        ? ReportPositionLabels[row.metadata[0].scoutPosition]
        : "--",
  },
  {
    header: "Role",
    accessorKey: "role",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
  },
  {
    header: "Scout Role",
    accessorKey: "scoutRole",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutRole
        ? ReportRoleLabels[row.metadata[0].scoutRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutRole
        ? ReportRoleLabels[row.metadata[0].scoutRole]
        : "--",
  },
  {
    header: "AIM",
    accessorKey: "aim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.aim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.aim, 2),
  },
  {
    header: "oAIM",
    accessorKey: "oAim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.oAim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.oAim, 2),
  },
  {
    header: "dAIM",
    accessorKey: "dAim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.dAim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.dAim, 2),
  },
  ...[
    CURRENT_SEASON,
    CURRENT_SEASON + 1,
    CURRENT_SEASON + 2,
    CURRENT_SEASON + 3,
    CURRENT_SEASON + 4,
  ].map((season, i) => ({
    header: `${season.toString().slice(-2)}-${(season + 1).toString().slice(-2)} Cap Hit`,
    accessorKey: `capHit${i > 0 ? `${i}Year` : ""}`,
    accessorFn: (row: PlayerRow) => {
      const key = `capHit${i > 0 ? `${i}Year` : ""}`;
      const salary = row.metadata[0]?.[key as keyof PlayerMetadataRow];
      return salary ? `$${salary.toLocaleString()}` : "--";
    },
    enableSorting: true,
    sortingFn: "alphanumeric" as const,
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => {
      const key = `capHit${i > 0 ? `${i}Year` : ""}`;
      const salary = row.metadata[0]?.[key as keyof PlayerMetadataRow];
      return salary ? `"${formatMoney(salary as number)}"` : "--";
    },
  })),
  ...Object.values(PlayerMetadataScoreRow.key).map((scoreKey) => ({
    header:
      ReportScoreDescriptions[scoreKey].fullLabel ||
      ReportScoreDescriptions[scoreKey].label,
    accessorKey: scoreKey,
    enableSorting: true,
    accessorFn: (row: PlayerRow) => {
      const score = row.metadata[0]?.hybridScores?.find(
        (score) => score.key === scoreKey
      );
      return score == null || score.value == null
        ? "--"
        : `${score.value}/${score.max}`;
    },
    sortingFn: "alphanumeric" as const,
    csvFn: (row: PlayerRow) => {
      const score = row.metadata[0]?.hybridScores?.find(
        (score) => score.key === scoreKey
      );
      return score == null || score.value == null
        ? "--"
        : `${score.value}/${score.max}`;
    },
  })),
  {
    header: "AAV",
    accessorKey: "aav",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.aav ? `$${row.metadata[0].aav.toLocaleString()}` : "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.aav
        ? `"$${row.metadata[0].aav.toLocaleString()}"`
        : "--",
  },
  {
    header: "UFA",
    accessorKey: "unrestrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.unrestrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.unrestrictedFreeAgent ?? "--",
  },
  {
    header: "RFA",
    accessorKey: "restrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.restrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.restrictedFreeAgent ?? "--",
  },
  {
    header: "Player Option",
    accessorKey: "playerOption",
    enableSorting: true,
    accessorFn: (row: PlayerRow) => row.metadata[0]?.playerOption ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.playerOption ?? "--",
  },
  {
    header: "Team Option",
    accessorKey: "teamOption",
    enableSorting: true,
    accessorFn: (row: PlayerRow) => row.metadata[0]?.teamOption ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.teamOption ?? "--",
  },
  {
    header: "PPV",
    accessorKey: "ppv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.ppv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.ppv)}"`,
  },
  {
    header: "oPPV",
    accessorKey: "oPpv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.oPpv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.oPpv)}"`,
  },
  {
    header: "dPPV",
    accessorKey: "dPpv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.dPpv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.dPpv)}"`,
  },
  {
    header: "PRV",
    accessorKey: "prv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.prv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.prv)}"`,
  },
];

const defaultVisibleColumns = [
  "name",
  "currentTeam",
  "position",
  "grade",
  "aim",
  "capHit",
  "birthDate",
];

export function PlayersListings() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  if ([...searchParams.entries()].length === 0) {
    setSearchParams({
      page: "1",
      pageSize: "20",
      sort: "name",
      dir: "asc",
      visible: defaultVisibleColumns.join(","),
      "league.in": "1",
    });
  }

  const [loadingCsv, setLoadingCsv] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const sorting = [
    {
      id: searchParams.get("sort") || "name",
      desc: searchParams.get("dir") === "desc",
    },
  ];

  const pagination = {
    pageIndex: parseInt(searchParams.get("page") || "1") - 1,
    pageSize: parseInt(searchParams.get("pageSize") || "20"),
  };

  const visibleColumns =
    searchParams.get("visible")?.split(",") || defaultVisibleColumns;
  const columnVisibility = columns.reduce((acc, column) => {
    acc[column.accessorKey] = visibleColumns.includes(column.accessorKey);
    return acc;
  }, {} as VisibilityState);

  const params = Object.fromEntries(searchParams);
  delete params.page;
  delete params.pageSize;
  delete params.sort;
  delete params.dir;
  delete params.visible;

  const { response, loading, error } = useAsync(playersLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0].id,
    sortDir: sorting[0].desc ? "DESC" : "ASC",
    ...params,
  });

  const handleSelectPlayer = (id: number | null) => {
    if (!id) return;
    navigate(`${id}`);
  };

  const updateSearchParams = (params = {}) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });
  };

  const numFilters = [...searchParams.keys()].filter(
    (key) => !["page", "pageSize", "sort", "dir", "visible"].includes(key)
  ).length;

  const getFilteredCsvPlayers = async () => {
    try {
      setLoadingCsv(true);
      const playersResponse = await apolloApi.getPlayers({
        offset: 0,
        limit: response?.count ?? 0,
        ...params,
      });

      toast(ToastType.SUCCESS, "CSV Downloaded");
      return playersResponse.players;
    } catch (error) {
      toast(ToastType.ERROR, "Unable to download CSV");
      return [];
    } finally {
      setLoadingCsv(false);
    }
  };

  const filteredCsvData = async () => {
    const data = await getFilteredCsvPlayers();

    return (
      data.map((player) => {
        const csvRow: Record<string, string | number | null | undefined> = {};

        columns.forEach((c) => {
          const key = c.accessorKey;

          if (c.csvFn) {
            csvRow[key] = c.csvFn(player);
          } else {
            const value = player[key as keyof PlayerRow];
            csvRow[key] =
              typeof value === "object" ? JSON.stringify(value) : value;
          }
        });

        return visibleColumns.reduce(
          (
            filteredObj: Record<string, string | number | null | undefined>,
            key
          ) => {
            filteredObj[key] = csvRow[key];
            return filteredObj;
          },
          {} as Record<string, string | number | null | undefined>
        );
      }) ?? []
    );
  };

  const csvHeaders: { id: string; displayName: string }[] = [];
  columns.forEach((column) => {
    visibleColumns.forEach((visibleColumn) => {
      if (visibleColumn === column.accessorKey) {
        csvHeaders.push({
          id: column.accessorKey,
          displayName: column.header,
        });
      }
    });
  });

  if (error) {
    throw new SunsApiError("Unable to load players.", {
      cause: error,
    });
  }

  return (
    <Page title="Players" breadcrumbs={false}>
      <Flex direction="down" gap="md" className="mt-4 lg:mt-2">
        <Sheet open={filterDrawerOpen} onOpenChange={setFilterDrawerOpen}>
          <PlayerFilters
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            onSubmit={() => {
              setFilterDrawerOpen(false);
            }}
          />
        </Sheet>
        <Flex direction="right" gap="md" justify="between">
          <Flex gap="md">
            <Button variant="outline" onClick={() => setFilterDrawerOpen(true)}>
              <SlidersVerticalIcon size={24} className="pr-2" /> Filters
              {numFilters > 0 && <Badge className="ml-2">{numFilters}</Badge>}
            </Button>
            <ColumnFilters
              columns={columns}
              columnVisibility={columnVisibility}
              onChange={(column, checked) => {
                const newColumnVisibility = {
                  ...columnVisibility,
                  [column]: !checked,
                };

                updateSearchParams({
                  visible: Object.keys(newColumnVisibility)
                    .filter((key) => Boolean(newColumnVisibility[key]))
                    .join(","),
                });
              }}
            />
          </Flex>
          <CsvDownloader
            className="max-sm:hidden"
            datas={filteredCsvData}
            columns={csvHeaders}
            filename="players.csv"
            separator=";"
            meta
          >
            <Button variant="outline" disabled={loadingCsv || loading}>
              {loadingCsv ? (
                <Flex direction="right" gap="sm" align="center">
                  <Text size="sm">Downloading...</Text>
                  <LoaderCircle size={18} className="animate-spin" />
                </Flex>
              ) : (
                <Text size="sm">Download as CSV</Text>
              )}
            </Button>
          </CsvDownloader>
        </Flex>
        <DataTable
          loading={loading}
          columns={columns}
          data={response?.players || []}
          count={response?.count || 0}
          pagination={pagination}
          setPagination={(setPagination) => {
            const newPagination =
              typeof setPagination === "function"
                ? setPagination(pagination)
                : setPagination;

            updateSearchParams({
              page: `${newPagination.pageIndex + 1}`,
              pageSize: `${newPagination.pageSize}`,
            });
          }}
          sorting={sorting}
          setSorting={(setSorting) => {
            const newSort =
              typeof setSorting === "function"
                ? setSorting(sorting)
                : setSorting;

            const sort = newSort[0] ?? null;

            updateSearchParams(
              sort
                ? {
                    sort: sort.id,
                    dir: sort.desc ? "desc" : "asc",
                  }
                : {
                    sort: undefined,
                    dir: undefined,
                  }
            );
          }}
          columnVisibility={columnVisibility}
          setColumnVisibility={(setColumnVisibility) => {
            const newColumnVisibility =
              typeof setColumnVisibility === "function"
                ? setColumnVisibility(columnVisibility)
                : setColumnVisibility;

            updateSearchParams({
              visible: Object.keys(newColumnVisibility)
                .filter((key) => Boolean(newColumnVisibility[key]))
                .join(","),
            });
          }}
          onRowClicked={(row) => handleSelectPlayer(row.original.id)}
        />
      </Flex>
    </Page>
  );
}
