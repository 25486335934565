import {
  ReportApolloGrade,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { Badge, Button, cn, Flex, Text } from "@suns/design-system";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "../../reports-const";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Link } from "react-router-dom";
import { URL } from "@/shared/utils/route-constant";
import { useAccount } from "@/shared/hooks";
import { apolloGradeColor } from "@/shared/utils/colors";

dayjs.extend(advancedFormat);

interface ReportListItemParams {
  report: ReportResponseItem;
  className?: string;
  hideListItemHeader?: boolean;
}

function renderReportType(report: ReportResponseItem) {
  if (report.type === ReportResponseItem.type.PHASE) {
    return `Phase Report`;
  } else if (report.type === ReportResponseItem.type.GENERAL) {
    const opponents = report.games?.map((game) => {
      return game.homeTeam?.id === report.teamId
        ? `vs ${game.awayTeam?.fullName}`
        : `vs ${game.homeTeam?.fullName}`;
    });

    if (opponents?.length) {
      return opponents.join(", ");
    } else {
      return "Player Report";
    }
  }
}

function renderCurrentScore(report: ReportResponseItem) {
  const currentScore = report.apolloGrades?.find(
    (grade) => grade.scope === ReportApolloGrade.scope.CURRENT
  );
  if (currentScore) {
    return (
      <Badge
        className={cn(
          "h-7 text-nowrap text-white",
          apolloGradeColor(currentScore.numericalValue)
        )}
      >
        {ApolloGradeValueLabels[currentScore.value]}
      </Badge>
    );
  }

  return null;
}

function renderPosition(report: ReportResponseItem) {
  if (report.position) {
    return (
      <Badge className="h-7 text-nowrap">
        {ReportPositionLabels[report.position]}
      </Badge>
    );
  }

  return null;
}

function renderRole(report: ReportResponseItem) {
  if (report.role) {
    return (
      <Badge className="h-7 text-nowrap">{ReportRoleLabels[report.role]}</Badge>
    );
  }

  return null;
}

function renderNotes(report: ReportResponseItem) {
  if (report.teamFitNotes) {
    return report.teamFitNotes;
  }

  return null;
}

export default function ReportListItem({
  report,
  className,
  hideListItemHeader = false,
}: ReportListItemParams) {
  const account = useAccount();

  const firstGame = report.games?.length > 0 ? report.games[0] : null;
  let editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`;

  const isPlayerOnDifferentTeam = !report.player.currentTeams?.some(
    (team) => team.id === report.teamId
  );

  if (
    firstGame &&
    report.authorUsername === account.info?.username &&
    report.type === ReportResponseItem.type.GENERAL &&
    !isPlayerOnDifferentTeam
  ) {
    editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}/game/${firstGame.id}?playerId=${report.player.id}`;
  }

  return (
    <Flex className={className} direction="down" gap="sm">
      {!hideListItemHeader && (
        <Text size="md" heading muted>
          {renderReportType(report)}
        </Text>
      )}
      <Link
        className="w-96"
        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
      >
        {!hideListItemHeader ? (
          <Text size="2xl" heading>
            {report.player.firstName} {report.player.lastName}
          </Text>
        ) : (
          <Text className="hover:text-black" variant="muted" size="xl" heading>
            {report.games?.length > 0
              ? renderReportType(report)
              : report.type === ReportResponseItem.type.PHASE
                ? "Phase Report"
                : "Player Report"}
          </Text>
        )}
      </Link>
      <Flex direction="right" gap="sm" wrap>
        {renderCurrentScore(report)}
        {renderPosition(report)}
        {renderRole(report)}
      </Flex>
      <>{renderNotes(report)}</>
      <Flex
        direction="right"
        gap="sm"
        className={cn(hideListItemHeader && "hidden")}
      >
        <Link
          to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
        >
          <Button size="sm" variant="outline">
            View
          </Button>
        </Link>
        <Link to={editLink}>
          <Button size="sm" variant="outline">
            Edit
          </Button>
        </Link>
      </Flex>
      <Flex direction="right" gap="xs">
        <Text heading muted>
          {dayjs(report.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text muted>by</Text>
        <Text heading muted>
          {report.authorName}
        </Text>
      </Flex>
    </Flex>
  );
}
