import { apolloApi } from "@/shared/api";
import { AgentRow, AgentsResponse } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { notify } from "@/components/bugsnag";

type AgentMap = Map<string, AgentCacheItem>;

// Bump this manually to force clear client cache
const AGENT_LIST_CACHE_VERSION = 1;
const AGENT_LIST_CACHE_KEY = "agentListCache";
let AGENT_LIST_PROMISE: Promise<AgentsResponse>;
const CACHE_TTL = 7 * 24 * 60 * 60 * 1000; // 7 days

export type AgentCacheItem = {
  type: "agent";
  key: string;
  id: number;
  fullName: string;
  display: string;
};

function getAgentsFromCache() {
  const cacheString = localStorage.getItem(AGENT_LIST_CACHE_KEY);

  if (!cacheString) {
    return;
  }
  const cache = JSON.parse(cacheString);
  const now = new Date().getTime();

  if (
    cache.version !== AGENT_LIST_CACHE_VERSION ||
    now - cache.date > CACHE_TTL
  ) {
    localStorage.removeItem(AGENT_LIST_CACHE_KEY);
    return;
  }

  return new Map(cache.agents) as AgentMap;
}

const agentMapFromCache = getAgentsFromCache();

export function useAgentsMap() {
  const [loading, setLoading] = useState(false);
  const [agentMap, setAgentMap] = useState<AgentMap | undefined>(
    agentMapFromCache
  );

  useEffect(() => {
    if (agentMap) {
      return;
    }

    AGENT_LIST_PROMISE =
      AGENT_LIST_PROMISE ||
      apolloApi.getAgents({
        limit: 1400,
      });

    setLoading(true);
    AGENT_LIST_PROMISE.then((response) => {
      const agentsTuple = response.agents
        .sort(sortAgents)
        .map<[string, AgentCacheItem]>((agent) => {
          return [
            `agent-${agent.id}`,
            {
              type: "agent" as const,
              key: `agent-${agent.id}`,
              id: agent.id,
              fullName: `${agent.name}`,
              display: `${agent.name}`,
            },
          ];
        });

      localStorage.setItem(
        AGENT_LIST_CACHE_KEY,
        JSON.stringify({
          version: AGENT_LIST_CACHE_VERSION,
          date: new Date().getTime(),
          agents: agentsTuple,
        })
      );

      setAgentMap(new Map(agentsTuple));
    })
      .catch((error) => {
        notify(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, agentMap };
}

function sortAgents(agent1: AgentRow, agent2: AgentRow) {
  return agent1.name.localeCompare(agent2.name);
}
