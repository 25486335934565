import { StatName } from "@/shared/const/glossary";
import { theme } from "@/shared/theme";
import { PlayerMetadataRow } from "@suns/api/generated-client/apollo";
import {
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@suns/design-system/src/components/Chart/Chart";
import dayjs from "dayjs";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Dot,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { MetricButton } from "./MetricButton";
import { CURRENT_DATE } from "@/shared/const";
import {
  generateChartTicks,
  playerMetadataToYearlyChartData,
  roundToSignificantDigit,
} from "@/shared/utils/helper-functions";

const chartConfig = {
  prv: {
    label: StatName.PRV,
    color: theme.colors.purple.DEFAULT,
  },
} satisfies ChartConfig;

export function PlayerPrvChart({
  playerMetadata,
}: {
  playerMetadata: PlayerMetadataRow[];
}) {
  const chartDataMonthly = playerMetadata
    ?.filter((metadata) => {
      if (metadata.snapshotDate === null) return true;
      const snapshotDate = new Date(metadata.snapshotDate);
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 8);
      return snapshotDate >= startDate;
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM DD YYYY")
        : dayjs().format("MMM DD YYYY"),
      prv: metadata.prv ?? null,
      ppv: metadata.ppv ?? null,
      capHit: metadata.capHit ?? null,
    }))
    .sort(
      (a, b) =>
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
    )
    .filter((item, index, array) => {
      if (index === 0) return true;
      return item.snapshotDate !== array[index - 1].snapshotDate;
    });

  const chartDataAnnual = Object.values(
    playerMetadataToYearlyChartData(playerMetadata)
  )
    ?.filter((metadata) => metadata.prv !== null)
    .sort((a, b) => {
      if (a.snapshotDate === null) return 1;
      if (b.snapshotDate === null) return -1;
      return (
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
      );
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM YYYY")
        : dayjs().format("MMM YYYY"),
      prv: metadata.prv ?? null,
      ppv: metadata.ppv ?? null,
      capHit: metadata.capHit ?? null,
    }));

  type MetricKey = keyof typeof chartConfig;

  const getMetricValues = (
    data: typeof chartDataAnnual,
    metric: MetricKey
  ) => ({
    currentValue: data[data.length - 1]?.[metric]?.toString() ?? null,
    previousValue:
      data.find((d) => d[metric] !== null)?.[metric]?.toString() ?? null,
  });

  return (
    <Flex direction="down" gap="md" className="w-full">
      <Tabs defaultValue="annual">
        <Flex direction="right" justify="between">
          <Text heading muted size="lg">
            Player Relative Value ({StatName.PRV})
          </Text>
          <TabsList>
            <TabsTrigger value="annual">Annual</TabsTrigger>
            <TabsTrigger value="monthly">Monthly</TabsTrigger>
          </TabsList>
        </Flex>
        <TabsContent value="annual">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" wrap>
              <MetricButton
                key="prv"
                label={StatName.PRV}
                color={theme.colors.gray[500]}
                currentValue={
                  getMetricValues(chartDataAnnual, "prv").currentValue
                }
                previousValue={
                  getMetricValues(chartDataAnnual, "prv").previousValue
                }
                differenceType="percentage"
                disabled={true}
              />
            </Flex>
            <PrvChart
              timeframe="annual"
              chartData={chartDataAnnual.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate ?? "",
              }))}
              chartConfig={chartConfig}
            />
          </Flex>
        </TabsContent>
        <TabsContent value="monthly">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" wrap>
              <MetricButton
                key="prv"
                label={StatName.PRV}
                color={theme.colors.gray[500]}
                currentValue={
                  getMetricValues(chartDataAnnual, "prv").currentValue
                }
                previousValue={
                  getMetricValues(chartDataAnnual, "prv").previousValue
                }
                differenceType="percentage"
                disabled={true}
              />
            </Flex>

            <PrvChart
              timeframe="monthly"
              chartData={chartDataMonthly.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate ?? "",
              }))}
              chartConfig={chartConfig}
            />
          </Flex>
        </TabsContent>
      </Tabs>
    </Flex>
  );
}

function PrvChart({
  chartData,
  chartConfig,
  timeframe,
}: {
  chartData: {
    snapshotDate: string;
    prv: number | null;
    ppv: number | null;
    capHit: number | null;
  }[];
  chartConfig: ChartConfig;
  timeframe: "monthly" | "annual";
}) {
  const minValue = roundToSignificantDigit(
    Math.min(...chartData.map((d) => Number(d?.prv))) * 1.2
  );
  const maxValue = roundToSignificantDigit(
    Math.max(...chartData.map((d) => Number(d?.prv))) * 1.5
  );

  const ticks = generateChartTicks(
    minValue < 0 ? minValue : 0,
    maxValue > 0 ? maxValue : Math.abs(minValue)
  );

  function gradientOffset() {
    const dataMax = Math.max(...chartData.map((d) => Number(d?.prv)));
    const dataMin = Math.min(...chartData.map((d) => Number(d?.prv)));

    if (dataMax <= 0) return 0;
    if (dataMin >= 0) return 1;

    return dataMax / (dataMax - dataMin);
  }

  const off = gradientOffset();

  return (
    <ChartContainer config={chartConfig} className="h-[400px] w-full md:h-full">
      <AreaChart
        accessibilityLayer
        data={chartData}
        margin={{
          top: 12,
          left: 20,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="snapshotDate"
          tickLine={false}
          axisLine={false}
          tickMargin={12}
          tickFormatter={(value) => {
            const isAnnual = timeframe === "annual";
            const dateFormat = isAnnual ? "MMM YYYY" : "MMM DD YYYY";
            const isPresent = value === CURRENT_DATE.format("MMM DD YYYY");
            return isPresent
              ? "Today"
              : dayjs(value, dateFormat).format(isAnnual ? "'YY" : "MMM YY");
          }}
          strokeWidth={2}
        />
        <YAxis
          width={30}
          dataKey="prv"
          type="number"
          domain={[minValue, maxValue]}
          ticks={ticks}
          tickFormatter={(value) => {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              notation: "compact",
              compactDisplay: "short",
            });
          }}
          tickLine={false}
        />
        <ReferenceLine
          y={0}
          stroke={theme.colors.gray[800]}
          strokeWidth={1.5}
          strokeOpacity={0.65}
        />

        <ChartLegend content={<ChartLegendContent hideLabel={true} />} />

        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              formatter={(value, name, props) => {
                const USDollar = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                });
                if (name === "prv") {
                  const ppvValue = props.payload.ppv;
                  return (
                    <Flex direction="down" justify="between">
                      <Flex gap="sm">
                        <span className="text-muted-foreground">
                          {StatName.PRV}
                        </span>
                        <span className="font-mono font-medium tabular-nums text-foreground">
                          {USDollar.format(Number(value))}
                        </span>
                      </Flex>
                      <Flex gap="sm">
                        <span className="text-muted-foreground">
                          {StatName.PPV}
                        </span>
                        <span className="font-mono font-medium tabular-nums text-foreground">
                          {USDollar.format(Number(ppvValue))}
                        </span>
                      </Flex>
                      <Flex gap="sm">
                        <span className="text-muted-foreground">Cap Hit</span>
                        <span className="font-mono font-medium tabular-nums text-foreground">
                          {USDollar.format(Number(props.payload.capHit))}
                        </span>
                      </Flex>
                    </Flex>
                  );
                }
                return null;
              }}
            />
          }
        />

        <defs>
          <linearGradient id="gradientprv" x1="0" y1="0" x2="0" y2="1">
            <stop offset={off} stopColor="green" stopOpacity={1} />
            <stop offset={off} stopColor="red" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          dataKey="prv"
          type="monotone"
          baseValue={0}
          stroke={`url(#gradientprv)`}
          strokeWidth={2}
          fillOpacity={0.2}
          fill={`url(#gradientprv)`}
          animationDuration={900}
          dot={({ payload, ...props }) => {
            return (
              <Dot
                key={`prv-${props.cx}-${props.cy}`}
                r={3}
                cx={props.cx}
                cy={props.cy}
                fill={payload.prv > 0 ? "green" : "red"}
                stroke={payload.prv > 0 ? "green" : "red"}
              />
            );
          }}
          activeDot={({ payload, ...props }) => {
            return (
              <Dot
                key={`prv-${props.cx}-${props.cy}`}
                r={6}
                cx={props.cx}
                cy={props.cy}
                fill={payload.prv > 0 ? "green" : "red"}
                stroke={payload.prv > 0 ? "green" : "red"}
              />
            );
          }}
        />
      </AreaChart>
    </ChartContainer>
  );
}
