import ErrorFallback from "@/components/error-fallback/error-fallback";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import { TeamsAnalyticsView, TeamsListings, TeamsView } from "@/pages/teams";
import { Suspense } from "react";

export const TeamRoutes = {
  path: "teams",
  children: [
    {
      path: "",
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <TeamsListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: ":teamId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <TeamsView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: ":teamId/analytics",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <TeamsAnalyticsView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
};
