import { ApolloGrade, PlayerPosition, PlayerRole } from "@suns/api";

type LabelInput = ApolloGrade | PlayerRole | PlayerPosition;

const labels: Record<LabelInput, string> = {
  [ApolloGrade.FRANCHISE]: "Franchise",
  [ApolloGrade.ALL_STAR]: "All Star",
  [ApolloGrade.ALL_STAR_PLUS]: "All Star Plus",
  [ApolloGrade.STARTER]: "Starter",
  [ApolloGrade.TOP_ROTATION]: "Top Rotation",
  [ApolloGrade.ROTATION]: "Rotation",
  [ApolloGrade.ROSTER]: "Roster",
  [ApolloGrade.MINOR_LEAGUE]: "Minor League",
  [PlayerPosition.POINT_GUARD]: "Point Guard (PG)",
  [PlayerPosition.GUARD]: "Guard (G)",
  [PlayerPosition.WING]: "Wing (W)",
  [PlayerPosition.FORWARD]: "Forward (F)",
  [PlayerPosition.BIG]: "Big (B)",
  [PlayerRole.PLAYMAKER]: "Playmaker (PM)",
  [PlayerRole.SHOOTER]: "Shooter (SH)",
  [PlayerRole.PERIMETER_SCORER]: "Perimeter Scorer (PS)",
  [PlayerRole.SLASHER_DRIVER]: "Slasher/Driver (SD)",
  [PlayerRole.INTERIOR_FINISHER]: "Interior Finisher (IF)",
  [PlayerRole.PERIMETER_DEFENDER]: "Perimeter Defender (PD)",
  [PlayerRole.INTERIOR_DEFENDER]: "Interior Defender (ID)",
};

export function getLabel(input: LabelInput) {
  return labels[input] || input;
}
