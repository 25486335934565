import { showError } from "@/components";
import { getAuthToken, logout } from "../auth";
import { APP_VERSION_PATH } from "@/components/version-checker/version-checker";

export default () => {
  const originalFetch = window.fetch;

  window.fetch = async (
    input: RequestInfo | URL,
    init: RequestInit = {}
  ): Promise<Response> => {
    if (input.toString().includes(import.meta.env.VITE_API_URL)) {
      const token = await getAuthToken();

      init.headers = mergeRequestHeaders(init.headers, {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let res: Response;
      try {
        res = await originalFetch(input as RequestInfo, init);
      } catch (error) {
        if (urlsToNotShowToast.some((url) => input.toString().includes(url))) {
          return reject(error);
        }

        showError({
          message: `Error fetching ${input}`,
          error: error instanceof Error ? error : undefined,
        });
        return reject(error);
      }

      if ([400, 401, 422, 404, 500, 403].includes(res.status)) {
        const resp = await res.json();
        showError({
          message: resp.message,
          error: new Error(`Network error (${res.status}): ${resp.message}`, {
            cause: resp.error,
          }),
        });
        if (res.status === 401) {
          logout();
        }
        return reject(
          resp.error || {
            statusCode: res.status,
            message: `Network error: ${resp.message}`,
          }
        );
      }

      return resolve(res);
    });
  };
};

function mergeRequestHeaders(
  inputHeaders: HeadersInit | undefined,
  extraHeaders: Record<string, string>
) {
  const newHeaders: Headers =
    inputHeaders instanceof Headers ? inputHeaders : new Headers(inputHeaders);

  for (const [name, value] of Object.entries(extraHeaders)) {
    if (!newHeaders.has(name)) {
      newHeaders.set(name, value);
    }
  }

  return newHeaders;
}

const urlsToNotShowToast = [
  "plausible.io",
  "google-analytics.com",
  APP_VERSION_PATH,
];
