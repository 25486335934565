import { getGame, getTeams } from "@/shared/api";
import { Mercury, Suns, ValleySuns } from "@/shared/const";
import { getNbaLeagueIdFromNbaGameId } from "@/shared/utils";
import { BoxScoreTeamStatistics } from "@suns/api/generated-client/stats";
import {
  calcDefRating,
  calcDrbPct,
  calcEfgPct,
  calcFtRate,
  calcNetRating,
  calcOffRating,
  calcOrbPct,
  calcPoss,
  calcTovPct,
} from "../stats-utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: Record<string, any> = {};

export async function boxScoreLoader({ nbaGameId }: { nbaGameId?: string }) {
  if (!nbaGameId) {
    return null;
  }

  const game = await getGame({ gameId: nbaGameId });
  const pregame = game.gameStatus == 1;
  const live = game.gameStatus == 2;
  const postgame = game.gameStatus == 3;
  if (pregame) {
    return {
      game,
      pregame,
      live,
      postgame,
      team: null,
      oppTeam: null,
      homeTeam: null,
      awayTeam: null,
      percentiles: null,
    };
  }

  if (
    cache[nbaGameId] &&
    game.gameStatusText == cache[nbaGameId].game.gameStatusText
  ) {
    return cache[nbaGameId] as typeof toReturn;
  }
  const boxScore = await getGame({
    gameId: nbaGameId,
    measureType: "BoxScore",
  });
  const teams = await getTeams({
    leagueId: getNbaLeagueIdFromNbaGameId(nbaGameId),
    season: game.seasonYear!,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    seasonType: game.seasonType as any,
    perMode: "PerGame",
  });

  const teamKey = [Suns.id, Mercury.id, ValleySuns.id].includes(
    game.awayTeam?.teamId || NaN
  )
    ? "awayTeam"
    : "homeTeam";
  const oppTeamKey = teamKey == "homeTeam" ? "awayTeam" : "homeTeam";

  const homeTeamSeason = teams.find((team) => {
    return team.teamId == game?.homeTeam?.teamId;
  });
  const awayTeamSeason = teams.find((team) => {
    return team.teamId == game?.awayTeam?.teamId;
  });

  const teamData = {
    homeTeam: {
      id: game.homeTeam!.teamId!,
      city: game.homeTeam!.teamCity!,
      tricode: game.homeTeam!.teamTricode!,
      name: game.homeTeam!.teamName!,
      score: game.homeTeam!.score!,
      boxScore: boxScore.homeTeam!.statistics!,
      advancedBox: calculateAdvancedBox(
        boxScore.homeTeam!.statistics!,
        boxScore.awayTeam!.statistics!
      ),
      season: homeTeamSeason?.stats,
    },
    awayTeam: {
      id: game.awayTeam!.teamId!,
      city: game.awayTeam!.teamCity!,
      tricode: game.awayTeam!.teamTricode!,
      name: game.awayTeam!.teamName!,
      score: game.awayTeam!.score!,
      boxScore: boxScore.awayTeam!.statistics!,
      advancedBox: calculateAdvancedBox(
        boxScore.awayTeam!.statistics!,
        boxScore.homeTeam!.statistics!
      ),
      season: awayTeamSeason?.stats,
    },
  };

  const toReturn = {
    game,
    pregame,
    live,
    postgame,
    team: teamData[teamKey],
    oppTeam: teamData[oppTeamKey],
    homeTeam: teamData.homeTeam,
    awayTeam: teamData.awayTeam,
  };
  cache[nbaGameId] = toReturn;
  return toReturn;
}

function calculateAdvancedBox(
  stats: BoxScoreTeamStatistics,
  oppStats: BoxScoreTeamStatistics
) {
  const poss = calcPoss({
    fga: stats.fieldGoalsAttempted,
    fta: stats.freeThrowsAttempted,
    tov: stats.turnovers,
    orb: stats.reboundsOffensive,
  });
  const oppPoss = calcPoss({
    fga: oppStats.fieldGoalsAttempted,
    fta: oppStats.freeThrowsAttempted,
    tov: oppStats.turnovers,
    orb: oppStats.reboundsOffensive,
  });
  const offRating = calcOffRating({ poss, pts: stats.points });
  const defRating = calcDefRating({ oppPoss, oppPts: oppStats.points });
  const netRating = calcNetRating({ offRating, defRating });
  const efgPct = calcEfgPct({
    fgm: stats.fieldGoalsMade,
    fga: stats.fieldGoalsAttempted,
    threePts: stats.threePointersMade,
  });
  const tovPct = calcTovPct({
    tov: stats.turnoversTotal,
    poss,
  });
  const orbPct = calcOrbPct({
    orb: stats.reboundsOffensive,
    oppDrb: oppStats.reboundsDefensive,
  });
  const drbPct = calcDrbPct({
    drb: stats.reboundsDefensive,
    oppOrb: oppStats.reboundsOffensive,
  });
  const ftaRate = calcFtRate({
    fga: stats.fieldGoalsAttempted,
    fta: stats.freeThrowsAttempted,
  });

  return {
    poss,
    offRating,
    defRating,
    netRating,
    efgPct,
    tovPct,
    orbPct,
    drbPct,
    ftaRate,
  };
}
